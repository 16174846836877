import { CredentialResponse } from 'google-one-tap';
import * as jose from 'jose';
import loadScript from 'load-script';

import { GOOGLE_APPLICATION_ID } from 'common/configuration/constants';

export type ResponsePayload = {
  aud: string;
  azp: string;
  email: string;
  email_verified: boolean;
  exp: number;
  family_name: string;
  given_name: string;
  hd: string;
  iat: number;
  iss: string;
  jti: string;
  name: string;
  nbf: number;
  picture?: string;
  sub: string;
};

const GOOGLE_API_URL = 'https://accounts.google.com/gsi/client';

let loaded = false;

export const getGoogleCredentialResponse = (
  response: Pick<CredentialResponse, 'credential'>
) => {
  const responsePayload = jose.decodeJwt(
    response.credential
  ) as ResponsePayload;

  return {
    email: responsePayload.email,
    name: responsePayload.given_name,
    token: response.credential
  };
};

/**
 * This module / function allows the dynamic loading of google client
 * javascript library
 * @param  {Function} [callback] - the function to execute once google client api is loaded
 * @return {void}
 */
export default (
  callback?: () => void,
  handleCredentialResponse?: (credentialResponse: CredentialResponse) => void
) => {
  if (loaded) {
    if (typeof callback === 'function') {
      callback();
    }
    return false;
  }

  loadScript(GOOGLE_API_URL, (err: Error | null) => {
    if (err) {
      // eslint-disable-next-line no-console
      console.error(err.message);
    } else {
      window.google.accounts.id.initialize({
        client_id: GOOGLE_APPLICATION_ID,
        callback: handleCredentialResponse
      });
      loaded = true;
      callback?.();
    }
  });
};
