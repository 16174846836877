import { TrackingEventNames } from 'common/constants/trackingEventsNames';
import eventEmitter from 'common/services/events/eventEmitter';
import { hit } from 'common/tracking/googleanalytics';
import { standardizeTrackingType } from 'common/tracking/helpers';

import { Entity } from 'website/types';

export const trackAffinityTooltip = ({
  entity,
  eventType
}: {
  entity: Entity;
  eventType: 'hover' | 'touch';
}): void => {
  // the print tracking for tooltip display on mouse hover
  // have to be call once time only
  // not the case for touch event
  if (eventType === 'hover') {
    eventEmitter.off(
      TrackingEventNames.AFFINITY_TOOLTIP_EVENT,
      trackAffinityTooltip
    );
  }

  hit(
    {
      eventCategory: 'my_brand',
      eventLabel: 'affinity_score',
      eventAction: 'impression_tooltip',
      dimension84: standardizeTrackingType(entity.typename) // entity theme
    },
    true
  );
};
