import get from 'common/tools/objects/get';

import { addToDatalayer } from '../googletagmanager';

export const gtmTrackOpinion = (
  eventAction: string,
  { context }: { context: { opinion: { rating: number } } }
) => {
  const rating = get(context, ['opinion', 'rating']);
  const formattedRating = `${rating}/5`;
  let formattedEventAction;
  if (['add', 'edit'].includes(eventAction)) {
    formattedEventAction = 'graduation';
  } else if (eventAction === 'remove') {
    formattedEventAction = 'remove-graduation';
  }
  if (!formattedEventAction) return;
  addToDatalayer(
    {
      event: 'eventGA',
      eventCategory: 'social-action',
      clicked_grade: formattedRating,
      eventAction: formattedEventAction
    },
    {
      movie_distributor: 'movie_distributors',
      clicked_movie_id: 'movie_id'
    }
  );
};
