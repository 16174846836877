import React, {
  useState,
  useEffect,
  useRef,
  ReactNode,
  MouseEvent
} from 'react';
import { connect, ConnectedProps } from 'react-redux';

import EventEmitter, { EventsTypes } from 'common/services/events/eventEmitter';

import { askForUserToLogIn } from 'website/containers/user/connection';
import { State } from 'website/reducers';
import { updateUserProfile } from 'website/services/authentication';
import {
  openAccountNeedsConfirmationModal,
  openTOSValidationModal
} from 'website/services/modal';
import { EntityTypename } from 'website/types';

/**
 * This component represents a link that is accessible only
 * to an authenticated user.
 *
 * If the user clicks the link while non authenticated, a login
 * process will be started
 *
 * If the user clicks the link while authenticated, the onClick props
 * function will be called (or the click event will be let through the DOM)
 */

export type AuthenticatedLinkTrackingContext = {
  eventAction?:
    | 'impression_modal'
    | 'impression_affinity_score'
    | 'click_tooltip';
  entityTypename?: EntityTypename;
};

type Props = {
  children: ReactNode;
  className?: string;
  ignoreMailValidation?: boolean;
  onClick?: () => void;
  trackingContext?: AuthenticatedLinkTrackingContext;
};

type PropsFromRedux = ConnectedProps<typeof connector>;

const mapStateToProps = (state: State) => ({
  isEmailValidated: !!state.user.account?.isEmailValidated,
  isTOSValidated: !!state.user.account?.isCguValidated,
  loggedIn: state.user.loggedIn,
  passiveLoginDone: state.user.passiveLoginDone
});

const connector = connect(mapStateToProps);

const AuthenticatedLink = ({
  children,
  className,
  ignoreMailValidation = false,
  isEmailValidated,
  isTOSValidated,
  loggedIn,
  onClick,
  passiveLoginDone,
  trackingContext
}: Props & PropsFromRedux) => {
  const [clicked, setClicked] = useState(false);
  const targetRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    if (clicked && loggedIn && passiveLoginDone && targetRef.current) {
      // Now we know that the user is logged in. If he clicked
      // the link before, let him pass through by "reclicking" for him
      targetRef.current.click();
    }
  }, [clicked, loggedIn, passiveLoginDone]);

  useEffect(() => {
    EventEmitter.on(EventsTypes.UPDATE_USER_PROFILE, updateUserProfile);
    return () => {
      EventEmitter.off(EventsTypes.UPDATE_USER_PROFILE, updateUserProfile);
    };
  }, []);

  const handleClickCapture = (evt: MouseEvent) => {
    // The passive login is not done yet
    if (!passiveLoginDone) {
      // Let's memorize this click
      evt.stopPropagation();
      setClicked(true);
      if (evt.target instanceof HTMLElement) {
        targetRef.current = evt.target;
      }
      return;
    }
    // User is not logged in
    if (!loggedIn) {
      evt.stopPropagation();
      askForUserToLogIn(trackingContext ? { trackingContext } : {});
      return;
    }
    // User is logged in but no accepted the Therms Of Services
    if (!isTOSValidated) {
      evt.stopPropagation();
      openTOSValidationModal();
      return;
    }
    // User is logged in but his email is not validated
    if (!ignoreMailValidation && !isEmailValidated) {
      evt.stopPropagation();
      openAccountNeedsConfirmationModal(
        trackingContext ? { trackingContext } : {}
      );
      return;
    }
    if (typeof onClick === 'function') {
      evt.stopPropagation();
      onClick();
    }
    // Let the event pass
  };

  return (
    <div className={className} onClickCapture={handleClickCapture}>
      {children}
    </div>
  );
};

export { AuthenticatedLink as AuthenticatedLinkForTest };
export default connector(AuthenticatedLink);
