import Cookies from 'cookies-js';

import { ELASTIC_SEARCH_URL } from 'common/configuration/constants';
import { GEOLOCALISATION_LEVEL2 } from 'common/constants/CookieNames';
import request from 'common/tools/network/request';
import { path } from 'common/tools/network/routing';

export function search(term = '') {
  const geocode = Cookies.get(GEOLOCALISATION_LEVEL2);

  return request(`${ELASTIC_SEARCH_URL}?geo2=${geocode}&q=${term}`);
}

export function headerAutocompleteSearch(term = '') {
  return request(path('autocomplete', { search: term }));
}
