import { hit } from 'common/tracking/googleanalytics';

export const trackFollowUser = (
  eventAction: string,
  {
    context: { socialActionPlacement }
  }: { context: { socialActionPlacement: string } }
) => {
  // since data past to GQL
  // no data match entity status

  hit(
    {
      eventCategory: 'social',
      eventLabel: 'follow_member',
      eventAction,
      dimension85: socialActionPlacement || 'standalone' // social action placement
    },
    true
  );
};
