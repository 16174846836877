/* global dataLayer */
import * as events from 'common/tools/dom/events';
import readAttribute from 'common/tools/dom/readAttribute';
import pick from 'common/tools/objects/pick';
import remapProps from 'common/tools/objects/remapProps';

export function addToDatalayer(object, mapInfos = {}) {
  const layer = dataLayer[0];
  let keys = [];

  for (const key in mapInfos) {
    if (mapInfos[key]) {
      keys = [...keys, mapInfos[key]];
    }
  }

  const dataToPush = {
    ...object,
    ...remapProps(pick(layer, keys), mapInfos)
  };
  dataLayer.push(dataToPush);
}

export default function setupExtraTracking() {
  const gtmPushBtn = document.querySelectorAll('[data-gtm-push]');

  events.on(gtmPushBtn, 'click', function pushInGtmLayer() {
    const eventData = readAttribute(this, 'data-gtm-push');
    addToDatalayer(eventData);
  });
}
