import UnwantedOverlayManager from 'common/services/unwantedOverlay/UnwantedOverlayManager';

import PromoteSignupOverlayCandidate from 'website/components/user/PromoteSignupModal/OverlayCandidate';

export default function globalPushPromoteSignup() {
  const componentAnchor = document.getElementById('promote-signup-anchor');

  if (!componentAnchor) {
    return false;
  }

  UnwantedOverlayManager.registerCandidate(new PromoteSignupOverlayCandidate());
}
