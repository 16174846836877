import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';

import eventEmitter, { EventsTypes } from 'common/services/events/eventEmitter';
import noop from 'common/tools/noop';
import positionTooltip from 'common/tools/tooltip/positioner';

/**
 * This component will create a tooltip using a React Portal attached
 * to document's body.
 *
 * The tooltip DOM is the same as the one created by tooltip and uses
 * the same positioning logic and CSS styles
 */
class Tooltip extends PureComponent {
  constructor(props) {
    super(props);

    this.anchorRef = React.createRef();
    this.tooltipRef = React.createRef();
    this.tooltipArrowRef = React.createRef();
    this.tooltipContainerRef = React.createRef();
    this.constraint = document.getElementById(props.constraintRectangle);
    this.header = document.getElementById(props.constraintHeight);

    // Pre-bind to keep method reference with this in context at eventEmitter.on and eventEmitter.off
    this.positionTooltip = this.positionTooltip.bind(this);
  }

  componentDidMount() {
    this.positionTooltip();

    // fixes the positioning problem when the ad loads and shifts all the elements
    eventEmitter.on(EventsTypes.ADS_READY, this.positionTooltip);

    if (this.props.onShow) {
      this.props.onShow();
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.visible) {
      this.positionTooltip();

      if (this.props.onShow) {
        this.props.onShow();
      }
    }
  }

  componentWillUnmount() {
    // Make sure to remove the DOM listener when the component is unmounted.
    eventEmitter.removeListener(EventsTypes.ADS_READY, this.positionTooltip);
  }

  positionTooltip() {
    if (this.props.visible) {
      const header = this.header.getBoundingClientRect();

      positionTooltip(
        this.props.position,
        this.anchorRef.current,
        this.tooltipRef.current,
        this.tooltipArrowRef.current,
        this.tooltipContainerRef.current,
        this.constraint,
        header.height
      );
    }
  }

  render() {
    return (
      <span
        ref={this.anchorRef}
        onMouseEnter={this.props.onMouseEnter}
        onMouseLeave={this.props.onMouseLeave}
        onClick={this.props.onTargetClick}
        className={this.props.wrapperClassName}
      >
        {this.props.children}
        {this.props.visible &&
          ReactDOM.createPortal(
            <div
              ref={this.tooltipRef}
              onClick={this.props.onContentClick}
              className={classNames(
                `tooltip tooltip-${this.props.theme}`,
                {
                  'tooltip-close': this.props.onClose
                },
                this.props.className
              )}
            >
              <div className="tooltip-content" ref={this.tooltipContainerRef}>
                {this.props.tooltipContent}
              </div>
              <div className="tooltip-arrow" ref={this.tooltipArrowRef} />
              {this.props.onClose ? (
                <span
                  className="tooltip-close-button icon icon-cross light"
                  onClick={this.props.onClose}
                />
              ) : null}
            </div>,
            this.constraint
          )}
      </span>
    );
  }
}

Tooltip.propTypes = {
  position: PropTypes.oneOf(['top', 'right', 'bottom', 'left', 'auto']),
  visible: PropTypes.bool.isRequired,
  tooltipContent: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node,
    PropTypes.string
  ]).isRequired,
  constraintRectangle: PropTypes.string.isRequired,
  constraintHeight: PropTypes.string.isRequired,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onTargetClick: PropTypes.func,
  onContentClick: PropTypes.func,
  onClose: PropTypes.func,
  onShow: PropTypes.func,
  theme: PropTypes.oneOf(['default', 'dark', 'blue']),
  className: PropTypes.string,
  wrapperClassName: PropTypes.string,
  children: PropTypes.node.isRequired
};

Tooltip.defaultProps = {
  position: 'auto',
  theme: 'default',
  onClose: null,
  onShow: noop,
  className: null,
  wrapperClassName: null,
  onMouseEnter: noop,
  onMouseLeave: noop,
  onTargetClick: noop,
  onContentClick: noop
};

export default Tooltip;
