import strictUriEncode from 'strict-uri-encode';

export function stringify(obj: {
  [key: string]: string | number | string[] | undefined | null;
}) {
  return obj
    ? Object.keys(obj)
        .sort()
        .map(function convertKey(key) {
          const val = obj[key];

          if (val === undefined) {
            return '';
          }

          if (val === null) {
            return key;
          }

          if (Array.isArray(val)) {
            return val
              .sort()
              .map(function convertArray(val2) {
                return (
                  strictUriEncode(`${key}[]`) + '=' + strictUriEncode(val2)
                );
              })
              .join('&');
          }

          return strictUriEncode(key) + '=' + strictUriEncode(val.toString());
        })
        .filter(function removeEmptyParams(x) {
          return x.length > 0;
        })
        .join('&')
    : '';
}
