import * as events from 'common/tools/dom/events';

const RC_KEY = 'rcblock';
const RC_NINJA = 'rc-ko';
const RC_NO_NINJA = 'rc-ok';

export default function initKonamiCode() {
  events.on(window, 'keydown', e => {
    if (!global.RCBLOCK && e.keyCode === 65 && e.ctrlKey && e.shiftKey) {
      localStorage.setItem(RC_KEY, RC_NINJA);
    }

    if (global.RCBLOCK && e.keyCode === 65 && e.ctrlKey && e.shiftKey) {
      localStorage.setItem(RC_KEY, RC_NO_NINJA);
    }
  });
}
