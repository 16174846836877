import eventEmitter, { EventsTypes } from 'common/services/events/eventEmitter';
import { requestAnimationFrame } from 'common/tools/functions/request-animation-frame';

import { isProd } from '../env';

const OBFUSCATED_PREFIX = 'ACr';
export const DESOBFUSCATED_CLASSNAME = 'xXx';

let obfuscatedLinks: NodeListOf<HTMLSpanElement>;
let obfuscatedSvgLinks: NodeListOf<SVGTSpanElement>;
let obfuscatedSelectValue: NodeListOf<HTMLOptionElement>;

export const runAfterDesobfuscation = (callback: () => void) => {
  if (window.desobfuscationDone) {
    callback();
  } else {
    eventEmitter.once(EventsTypes.DESOBFUSCATION_DONE, callback);
  }
};

export const desobfuscateUrl = (url?: string) => {
  if (!url) return '';
  if (url.indexOf(OBFUSCATED_PREFIX) !== 0) {
    return url;
  }
  // split for case with anchor
  const splitedUrl = url.split('#');
  let href = '';
  try {
    href = window.atob(splitedUrl[0].split(OBFUSCATED_PREFIX).join(''));
  } catch (e) {
    console.error(e); // eslint-disable-line
  }

  if (typeof splitedUrl[1] !== 'undefined') {
    href += '#' + splitedUrl[1];
  }
  return href;
};

const _desobfuscate = (
  anchor: HTMLSpanElement | SVGTSpanElement | HTMLOptionElement,
  attribute = 'href'
) => {
  try {
    const attr = attribute === 'value' ? 'value' : 'class';
    const info = anchor.getAttribute(attr)?.split(' ') ?? [''];

    const obfuscatedUrl = info[0];
    const href = desobfuscateUrl(obfuscatedUrl);

    switch (attribute) {
      case 'value':
        anchor.setAttribute('value', href);
        break;
      case 'xlink':
        anchor.setAttribute('xlink:href', href);
        break;
      default:
        anchor.setAttribute('href', href);
        break;
    }

    info[0] = DESOBFUSCATED_CLASSNAME;
    anchor.setAttribute('class', info.join(' '));

    // if item not an option tag we have to transform the span generated into a link
    if (attribute !== 'value') {
      const objAttributes = Array.prototype.slice.call(anchor.attributes);
      const link = document.createElement('a');

      while (anchor.firstChild) {
        link.appendChild(anchor.firstChild);
      }

      objAttributes.forEach(item => {
        link.setAttribute(item.name, item.value);
      });
      anchor.parentNode?.insertBefore(link, anchor);
      anchor.parentNode?.removeChild(anchor);
    }
  } catch (e) {
    // fail silenty in prod, log error in dev
    if (!isProd()) {
      /* eslint-disable no-console */
      console.error('desobfuscation error', e);
      /* eslint-enable no-console */
    }
  }
};

const desobfuscateAll = () => {
  for (const link of obfuscatedLinks) {
    _desobfuscate(link, 'href');
  }
  for (const link of obfuscatedSvgLinks) {
    _desobfuscate(link, 'xlink');
  }
  for (const link of obfuscatedSelectValue) {
    _desobfuscate(link, 'value');
  }

  window.desobfuscationDone = true;
  eventEmitter.emit(EventsTypes.DESOBFUSCATION_DONE);

  // for some weird / unknown reason browsers don't like when we rewrite svg content
  // and the svg stops responding to user events, so we need to replace the node
  // by a new one, and with SVG a simple cloneNode does not work...
  for (const svgNode of document.querySelectorAll('.obfuscated-svg')) {
    const parent = svgNode.parentElement;
    const doc = new DOMParser().parseFromString(
      parent?.innerHTML ?? '',
      'image/svg+xml'
    );
    svgNode.remove();
    const child = parent?.ownerDocument?.importNode(doc.documentElement, true);
    if (child) parent?.appendChild(child);
  }
};

const _init = () => {
  window.desobfuscationDone = false;
  requestAnimationFrame(() => {
    obfuscatedLinks = document.querySelectorAll(
      `span[class^="${OBFUSCATED_PREFIX}"]`
    );
    obfuscatedSvgLinks = document.querySelectorAll(
      `tspan[class^="${OBFUSCATED_PREFIX}"]`
    );
    obfuscatedSelectValue = document.querySelectorAll(
      `option[value^="${OBFUSCATED_PREFIX}"]`
    );
    desobfuscateAll();
  });
};

export { _desobfuscate as desobfuscateForTests };
export default _init;
