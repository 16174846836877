import { Middleware } from 'redux';

import { isAcActionTypes } from 'website/store';

/**
 * This ius middleware for asynchronous redux actions
 * @see http://gaearon.github.io/redux/docs/recipes/ReducingBoilerplate.html#async-action-creators for the inspiration
 */
const callApiMiddleware: Middleware =
  ({ dispatch, getState }) =>
  next =>
  action => {
    if (!isAcActionTypes(action)) {
      // Normal action: pass it on
      return next(action);
    }

    const {
      types,
      callAPI,
      shouldCallAPI = () => true,
      payload = {},
      metas = []
    } = action;

    if (
      !Array.isArray(types) ||
      types.length !== 3 ||
      !types.every(type => typeof type === 'string')
    ) {
      throw new Error('Expected an array of three string types.');
    }

    if (typeof callAPI !== 'function') {
      throw new Error('Expected fetch to be a function.');
    }

    if (!shouldCallAPI(getState())) {
      return;
    }

    const [requestType, successType, failureType] = types;

    dispatch({
      ...payload,
      type: requestType,
      meta: metas[0]
    });

    return callAPI<Record<string, string> | undefined>().then(
      response =>
        dispatch({
          ...payload,
          payload: response,
          type: successType,
          meta: metas[1]
        }),
      error =>
        dispatch({
          ...payload,
          error: error,
          type: failureType,
          meta: metas[2]
        })
    );
  };

export default callApiMiddleware;
