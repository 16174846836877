import { WebediaAdvertisingSystem } from '@webedia-services/webedia-player';

import { PlayerCustomData } from 'website/module/player';

type ThenableError = { then: (resolve: () => void) => void };

export enum AdProviders {
  DFP_SYSTEM_CODE = 'dfp',
  FACEBOOK_SYSTEM_CODE = 'facebook',
  JAD_SYSTEM_CODE = 'jad',
  WEBEDIADS_SYSTEM_CODE = 'wads'
}

export interface AdProvidersModule {
  init?: (
    resolve: (_?: ThenableError | void) => void
  ) => Promise<void> | boolean | undefined | void;
  getPrerollConfiguration?: (
    data: PlayerCustomData
  ) => Promise<boolean | { tag: string | boolean }>;
  getPostrollConfiguration?: (
    data: PlayerCustomData
  ) => Promise<boolean | { tag: string | boolean }>;
  getPostrollAsVideoConfiguration?: (
    data: PlayerCustomData
  ) => Promise<unknown> | { type: string };
  getClientConfiguration?: () => {
    adscheduleid?: string;
    client?: string;
    iasActive?: boolean;
    system?: string;
    vpaidcontrols?: boolean;
    vpaidmode?: string;
  };
  createPosition?: (
    positionId: string,
    referenceElement: string | HTMLDivElement,
    forceCreateAnchor?: boolean
  ) => void;
  refreshAds?:
    | ((
        payload: { advertisementTargets: string[] },
        anchorArray?: string[]
      ) => boolean | undefined)
    | ((
        payload: { advertisementTargets: string[] },
        all?: boolean,
        withHeaderBidding?: boolean,
        callback?: () => void
      ) => boolean | undefined);
  removePosition?: (
    positionId?: string,
    referenceElement?: HTMLDivElement | null
  ) => void;
}

export type PostrollAsVideoConfig = {
  type?: WebediaAdvertisingSystem;
  postroll?: JADTrailers[];
};
