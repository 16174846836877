import loadScript from 'load-script';

import {
  LOCALE,
  FACEBOOK_APPID,
  FACEBOOK_VERSION,
  FACEBOOK_PERMISSIONS
} from 'common/configuration/constants';
import { Credentials } from 'common/services/authentication/tokens';

const FACEBOOK_API_URL = `https://connect.facebook.net/${LOCALE}/sdk.js`;

let loaded = false;
let loading = false;
const resolves: ((authResponse: fb.AuthResponse) => any)[] = [];

export const login = (scope: string, options: fb.LoginOptions) => {
  if (!(loaded && window.FB)) return Promise.reject(new Error('not loaded'));
  return new Promise(resolve => {
    window.FB.login(resolve, {
      scope,
      return_scopes: true,
      ...options
    });
  });
};

export const makeGraphApiCall = (path: string) => {
  if (!(loaded && window.FB)) return Promise.reject(new Error('not loaded'));
  return new Promise(resolve => {
    window.FB.api(path, resolve);
  });
};

export const getLoginStatus = () => {
  if (!(loaded && window.FB)) return Promise.reject(new Error('not loaded'));
  return new Promise(resolve => {
    window.FB.getLoginStatus(resolve);
  });
};

export const shareUrl = (
  href: string,
  display?: fb.ShareDialogParams['display']
) => {
  if (!(loaded && window.FB)) return Promise.reject(new Error('not loaded'));
  return new Promise(resolve => {
    window.FB.ui(
      {
        method: 'share',
        href,
        ...(display ? { display } : {})
      },
      resolve
    );
  });
};
const loadFacebookClient = async (
  options = {}
): Promise<fb.AuthResponse | null> => {
  // If the lib is already loaded
  if (loaded) {
    return Promise.resolve(window.FB.getAuthResponse());
  }

  // registering callback
  const p = new Promise<fb.AuthResponse | null>(resolve => {
    resolves.push(resolve);
  });

  // The lib is currently loading
  if (loading) {
    return p;
  } else {
    loading = true;
  }

  // facebook client api loads another script by itself
  // preventing the use the callback provided by loadScript
  // we create a global function and register it as a callback
  // it will be called by facebook automatically
  window.fbAsyncInit = () => {
    window.FB.init({
      appId: FACEBOOK_APPID,
      version: FACEBOOK_VERSION,
      status: true,
      cookie: true,
      xfbml: true
    });

    // The status flag in FB.init might not work as expected
    // (https://developers.facebook.com/support/bugs/655049928258121)
    // It's working asynchronously while FB.init exposes only a synchronous API
    // For that reason, if a call to FB.share is made too soon after FB.init
    // its behavior might be chaotic because the user status is not yet known
    window.FB.getLoginStatus(statusResponse => {
      loaded = true;
      loading = false;
      resolves?.forEach(resolve => {
        resolve(statusResponse.authResponse);
      });
    });
  };

  if (!loaded && window.FB) {
    // FB is alreday loaded, doesn't understand how.
    // Perhaps because of googletagmanager (almost sure, the bug doesn't appear in dev mode)
    // This bug prevent fbAsyncInit to be trigger (don't know why)
    // and prevent loggin on FB connection click (modal connection login)
    window.fbAsyncInit();
  } else {
    loadScript(FACEBOOK_API_URL, options);
  }

  return p;
};

export const performFacebookLogin = async () => {
  /*
      handcraft a Promise has a wrapper around the facebook auth request,
      has it could be executed asynchronously if the facebook client javascript
      is not yet available on the page
       */
  await loadFacebookClient();
  return new Promise<string>((resolve, reject) => {
    window.FB.login(
      response => {
        const authResponse = response.authResponse;
        const accessToken = authResponse && authResponse.accessToken;

        if (!accessToken) {
          return reject(new Error('missing FB accessToken'));
        }
        resolve(accessToken);
      },
      { scope: FACEBOOK_PERMISSIONS }
    );
  });
};

export const getFacebookCredentialResponse = () =>
  new Promise<Credentials>(resolve => {
    performFacebookLogin().then(token => {
      window.FB.api(
        '/me',
        { fields: 'name, email' },
        ({ email, name }: { email: string; name: string }) => {
          resolve({
            token,
            email,
            name
          });
        }
      );
    });
  });

export default loadFacebookClient;
