const polyfillWithResolvers = async () => {
  if (typeof Promise.withResolvers === 'function') {
    return;
  }
  await import(
    /* webpackChunkName: "@enhances/with-resolvers" */ '@enhances/with-resolvers'
  );
  return;
};

export default polyfillWithResolvers;
