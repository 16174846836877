import classNames from 'classnames';
import React, { MouseEvent, ReactNode } from 'react';

import noop from 'common/tools/noop';

/*
This component will render a share button for
either WhatsApp, Facebook or Twitter.

The button can have to aspect: a "square" one
(no text, just an icon) oa a "full" one (icon +
social network name)
*/

export enum Network {
  FACEBOOK,
  TWITTER,
  WHATSAPP
}

export enum Type {
  SQUARE,
  FULL
}

const networkToButtonClass = (network: Network) => {
  if (network === Network.FACEBOOK) return 'button-fb-full';
  if (network === Network.TWITTER) return 'button-tw-full';
  if (network === Network.WHATSAPP) return 'button-whatsapp-full';
};

const networkToSquareButtonClass = (network: Network) => {
  return `${networkToButtonClass(network)}-square`;
};

const networkToIconClass = (network: Network) => {
  if (network === Network.FACEBOOK) return 'icon-social-facebook';
  if (network === Network.TWITTER) return 'icon-social-twitter';
  if (network === Network.WHATSAPP) return 'icon-social-whatsapp';
};

const networkToSquareIconClass = (network: Network) => {
  return `${networkToIconClass(network)}-square`;
};

export type Props = {
  network: Network;
  type?: Type;
  url?: string;
  children?: ReactNode;
  disabled?: boolean;
  className?: string;
  onClick?: (e: MouseEvent) => void;
  size?: 'sm' | 'md';
};

const ShareButton = ({
  type = Type.SQUARE,
  url,
  onClick = noop,
  className,
  network,
  children,
  size = 'md',
  disabled = false
}: Props) => {
  const commonProps = {
    ...(url ? { target: '_blank', href: url, rel: 'noopener noreferrer' } : {}),
    onClick
  };

  const commonClasses = classNames(
    'share-button',
    'button',
    `button-${size}`,
    {
      'button-disabled': disabled
    },
    className
  );

  if (type === Type.SQUARE) {
    return (
      <a
        {...commonProps}
        className={classNames(
          commonClasses,
          networkToSquareButtonClass(network)
        )}
      >
        <i className={classNames('icon', networkToSquareIconClass(network))} />
        <span className="txt">{children}</span>
      </a>
    );
  }
  if (type === Type.FULL) {
    return (
      <a
        {...commonProps}
        className={classNames(
          commonClasses,
          networkToButtonClass(network),
          'icon',
          networkToIconClass(network)
        )}
      >
        {children}
      </a>
    );
  }
  return null;
};

export default ShareButton;
