import { addToDatalayer } from '../googletagmanager';

export const gtmTrackWantToSee = eventAction => {
  let formattedEventAction;
  if (eventAction === 'add') {
    formattedEventAction = 'want-to-watch';
  } else if (eventAction === 'remove') {
    formattedEventAction = 'remove-want-to-watch';
  }
  if (!formattedEventAction) return;
  addToDatalayer(
    {
      event: 'eventGA',
      eventCategory: 'social-action',
      eventAction: formattedEventAction
    },
    {
      movie_distributor: 'movie_distributors',
      clicked_movie_id: 'movie_id'
    }
  );
};
