import React, { PureComponent } from 'react';

import Tooltip from 'common/components/Tooltip';
import { on, off } from 'common/tools/dom/events';

/**
 * This HOC will mutate the "visible" property of the WrappedComponent
 * based on user clicks. If the user clicks outside of the tooltip,
 * the property will be set to false
 *
 * @param {Component} WrappedComponent A component to enhance
 * @returns {Component} a enhanced component
 */
function clickOutHoc(WrappedComponent) {
  return class ClickOutTooltip extends PureComponent {
    constructor(props) {
      super(props);
      this.tooltipRef = React.createRef();
      this.state = {
        visible: true
      };
    }

    componentDidMount() {
      on(document, 'click', this.handleDocumentClick.bind(this));
    }

    componentWillUnmount() {
      off(document, 'click', this.handleDocumentClick.bind(this));
    }

    handleDocumentClick(e) {
      let element = e.target;
      const tooltipNode = this.tooltipRef.current.tooltipRef.current;
      while (element && element !== document) {
        if (element === tooltipNode) {
          return; // user clicked inside tooltip
        }
        element = element.parentNode;
      }
      this.setState({ visible: false });
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          visible={this.state.visible}
          ref={this.tooltipRef}
        />
      );
    }
  };
}

export default clickOutHoc(Tooltip);
