import eventEmitter, { EventsTypes } from 'common/services/events/eventEmitter';
import { addClass, hasClass, removeClass } from 'common/tools/dom/classes';
import * as events from 'common/tools/dom/events';

function showMore(event) {
  event.preventDefault();

  if (hasClass(this, 'active')) {
    removeClass(this, 'active');
  } else {
    addClass(this, 'active');
    eventEmitter.emit(EventsTypes.LAZY_REVALIDATE);
  }
}

export default function initMoreTrigger() {
  const showMoreBtnCol = document.getElementsByClassName('js-more-trigger');

  if (showMoreBtnCol.length) {
    events.on(showMoreBtnCol, 'click', showMore);
  }
}
