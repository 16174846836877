import { Action, configureStore } from '@reduxjs/toolkit';

import { isDev } from 'common/tools/env';

import * as actionTypes from 'website/constants/ActionTypes';
import { State } from 'website/reducers';
import * as reducers from 'website/reducers';

import callAPIMiddleware from './middleware/callApiMiddleware';
import trackingMiddleware from './middleware/tracking';

type TrackingData = Record<string, string> | false;
interface ACAction extends Omit<Action, 'type'> {
  action?: {
    type: typeof actionTypes;
    payload: Record<string, string | boolean | number>;
  };
  callAPI?: <T>() => Promise<T>;
  failureType?: string;
  meta?: {
    tracking: (() => TrackingData) | TrackingData;
  };
  payload?: Record<string, string>;
  requestType?: string;
  shouldCallAPI?: (state: State) => boolean;
  successType?: undefined;
  tracking?: string;
}
export interface ACActionType extends ACAction, Pick<Action, 'type'> {}
export interface ACActionTypes extends ACAction {
  types: Array<Action['type']>;
  metas?: Array<ACAction['meta']>;
}

export const isAcActionTypes = (action: any): action is ACActionTypes =>
  !!action.types;

export const isACAction = (action: any): action is ACAction => !!action.meta;

const store = configureStore({
  reducer: reducers,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(trackingMiddleware, callAPIMiddleware),
  // Turn off devtools in prod
  devTools: isDev()
});

export default store;
