import { bannerEmergence } from 'common/constants/Ads';
import { MQ_SMALL } from 'common/constants/MediaQueries';
import eventEmitter, { EventsTypes } from 'common/services/events/eventEmitter';
import { addClass, removeClass } from 'common/tools/dom/classes';
import * as events from 'common/tools/dom/events';
import hasTouch from 'common/tools/dom/getTouchBrowser';
import isVisible from 'common/tools/dom/isVisible';
import { getStateIndex } from 'common/tools/dom/mq-state';
import { onYScroll } from 'common/tools/dom/on-scroll';
import readAttribute from 'common/tools/dom/readAttribute';
import debounce from 'common/tools/functions/debounce';
import once from 'common/tools/functions/once';
import { runAfterDesobfuscation } from 'common/tools/seo/desobfuscation';
import { hit, print } from 'common/tracking/googleanalytics';
import { print as print_jan, hit as hit_jan } from 'common/tracking/jan';

const SHOW_BANNER_CLASS = 'show-banner-emergence';
const CLOSE_BANNER_CLASS = 'close-banner-emergence';

const btnClose = document.getElementById('banner-emergence-close');
const body = document.body;

let trackingInfo: Record<string, any> | string | undefined;
let bannerClosed = false;
let bannerShowed = false;
let windowTop =
  window.scrollY || window.pageYOffset || document.documentElement.scrollTop;

const handleTrackingImpression = once(() => {
  if (typeof trackingInfo === 'object') {
    print(trackingInfo?.ga);
    print_jan(trackingInfo?.jan.eventName, trackingInfo?.jan);
  }
});

export const closeBanner = () => {
  if (!bannerEmergence) {
    return false;
  }

  removeClass(body, SHOW_BANNER_CLASS);
  addClass(body, CLOSE_BANNER_CLASS);
  bannerClosed = true;
};

const handleScroll = () => {
  if (bannerClosed || getStateIndex() > MQ_SMALL) {
    return false;
  }

  const currentTop =
    window.scrollY || window.pageYOffset || document.documentElement.scrollTop;

  if (currentTop < 500 && !bannerShowed) {
    return false;
  }

  if (windowTop > currentTop && !bannerShowed) {
    addClass(body, SHOW_BANNER_CLASS);
    handleTrackingImpression();
    eventEmitter.emit(EventsTypes.EMERGENCE_BANNER_ON);
    bannerShowed = true;
  }

  if (windowTop < currentTop && bannerShowed) {
    removeClass(body, SHOW_BANNER_CLASS);
    bannerShowed = false;
  }

  windowTop = currentTop;

  // HANDLE JS OUTBRAIN RESTRICTION FOR STICY BANNER
  const outbrainAnchor = document.getElementsByClassName('js-outbrain')[0];

  if (!outbrainAnchor) {
    return false;
  }

  isVisible(outbrainAnchor as HTMLDivElement).then(visible => {
    if (visible) {
      removeClass(body, SHOW_BANNER_CLASS);
      bannerShowed = false;
    }
  });
};

const handleBanner = () => {
  const bannerLink = document.getElementById('banner-emergence-link');
  trackingInfo = bannerEmergence
    ? readAttribute<Record<string, any>, undefined>(
        bannerEmergence,
        'data-tracking'
      )
    : undefined;

  if (bannerLink) {
    events.on(bannerLink, 'click', () => {
      if (typeof trackingInfo === 'object') {
        hit(trackingInfo.ga);
        hit_jan(`${trackingInfo.jan.eventName}_click`, trackingInfo.jan);
      }
    });
  }

  onYScroll(debounce(handleScroll, 100));

  if (btnClose) {
    events.on(btnClose, 'click', closeBanner);
  }
};

export default () => {
  if (!bannerEmergence || !hasTouch()) {
    return false;
  }

  runAfterDesobfuscation(handleBanner);
};
