import { MQ_LARGE } from 'common/constants/MediaQueries';
import { addClass } from 'common/tools/dom/classes';
import { getStateIndex } from 'common/tools/dom/mq-state';
import { requestAnimationFrame } from 'common/tools/functions/request-animation-frame';
import onUserAction from 'common/tools/ui/defer-on-user-action';
import { runAfterDeferAssets } from 'common/tools/ui/load-assets';
import { addToRicThread } from 'common/tools/ui/request-idle-cb';

import { CSS_SECONDARY_URL } from 'website/configuration/constants';

function _loadSecondaryCss() {
  const secondaryCss = document.getElementById('secondary-styles');

  if (secondaryCss) {
    return false;
  }

  const link = document.createElement('link');

  link.setAttribute('href', CSS_SECONDARY_URL);
  link.setAttribute('rel', 'stylesheet');
  link.setAttribute('type', 'text/css');
  link.setAttribute('id', 'secondary-styles');

  requestAnimationFrame(() => {
    document.head.appendChild(link);
    addClass(document.getElementsByTagName('html')[0], 'rc-visible');
  });
}

export default function loadSecondaryCss() {
  runAfterDeferAssets(() => {
    if (getStateIndex() < MQ_LARGE) {
      onUserAction(_loadSecondaryCss);
    } else {
      addToRicThread(_loadSecondaryCss);
    }
  });
}
