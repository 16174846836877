import {
  Action,
  CaseReducer,
  combineReducers,
  createReducer
} from '@reduxjs/toolkit';

import * as actionTypes from 'website/constants/ActionTypes';

type Followees = string[];

export type RelationshipsState = {
  followees: Followees;
};

interface RelationshipsAction<T extends string> extends Action<T> {
  userId: string;
  payload: { legacyId: string; userId: string }[];
}

const getUserFollowees: CaseReducer<
  Followees,
  RelationshipsAction<'GET_USER_FOLLOWEES_SUCCESS'>
> = (state, action) =>
  action.payload.map((followee: { legacyId: string }) => followee.legacyId);

const createFollowee: CaseReducer<
  Followees,
  RelationshipsAction<'CREATE_FOLLOWEE_REQUEST'>
> = (state, action) =>
  state.includes(action.userId) ? [...state] : [...state, action.userId];

const deleteFollowee: CaseReducer<
  Followees,
  RelationshipsAction<'DELETE_FOLLOWEE_REQUEST'>
> = (state, action) => {
  let followees = [...state];

  while (followees.includes(action.userId)) {
    const deletedItemIndex = followees.indexOf(action.userId);

    followees = [
      ...followees.slice(0, deletedItemIndex),
      ...followees.slice(deletedItemIndex + 1)
    ];
  }

  return followees;
};

const followeesReducer = createReducer<Followees>([], builder => {
  builder
    .addCase(actionTypes.CREATE_FOLLOWEE_REQUEST, createFollowee)
    .addCase(actionTypes.DELETE_FOLLOWEE_REQUEST, deleteFollowee)
    .addCase(actionTypes.GET_USER_FOLLOWEES_SUCCESS, getUserFollowees);
});

const appReducer = combineReducers({
  followees: followeesReducer
});

export default appReducer;
