import Cookies from 'cookies-js';

import { USERSPACE_COOKIE_DOMAIN } from 'common/configuration/constants';
import { USER_PROVENANCE } from 'common/constants/CookieNames';
import { path } from 'common/tools/network/routing';

export const USER_PROVENANCE_ACCOUNT_CONFIRMATION_ERROR =
  'USER_PROVENANCE_ACCOUNT_CONFIRMATION_ERROR';
export const USER_PROVENANCE_SIGN_UP = 'USER_PROVENANCE_SIGN_UP';
export const USER_PROVENANCE_ONBOARDING = 'USER_PROVENANCE_ONBOARDING';
export const USER_PROVENANCE_ACTIVATION_EMAIL_SENT_SUCCESS =
  'USER_PROVENANCE_ACTIVATION_EMAIL_SENT_SUCCESS';
export const USER_PROVENANCE_ACTIVATION_EMAIL_SENT_ERROR =
  'USER_PROVENANCE_ACTIVATION_EMAIL_SENT_ERROR';

const getUrl = (
  userSpaceRoute: string,
  securePath: string,
  returnUrl?: string
) => {
  let url = path(userSpaceRoute);
  if (returnUrl) {
    url += `?returnUrl=${encodeURIComponent(returnUrl)}`;
  }
  return url;
};

export const getSignInUrl = (returnUrl?: string) => {
  return getUrl('signin', '/account/logon', returnUrl);
};

export const getSignOutUrl = (returnUrl?: string) =>
  getUrl('signout', '/account/logoff', returnUrl);

export const getSignUpUrl = (returnUrl?: string) =>
  getUrl('signup', '/account/registrationmethod', returnUrl);

export const getRecoverPasswordUrl = () =>
  getUrl('password_recover', '/account/forgottenpassword');

export const getResendConfirmationEmailUrl = (returnUrl?: string) =>
  getUrl('resend_confirmation_email', '/account/activation', returnUrl);

let userProvenance: string;

/**
 * Will return the place the user comes from
 *
 * Only works in conjunction with backend which have to set the cookie
 * to the desired value. This cookie will be destroyed upon first read.
 *
 * @return {string} a string uniquely identifying the page the user came from
 */
export const getUserProvenance = () => {
  if (!userProvenance) {
    userProvenance = Cookies.get(USER_PROVENANCE);
    Cookies.expire(USER_PROVENANCE, { domain: USERSPACE_COOKIE_DOMAIN });
  }
  return userProvenance;
};
