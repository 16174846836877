import { LocalStorageKeys } from 'common/constants/LocalStorageKeys';
import {
  Candidate,
  CandidateNames
} from 'common/services/unwantedOverlay/UnwantedOverlayManager';
import readAttribute from 'common/tools/dom/readAttribute';
import { isNotOnFirstScreen } from 'common/tools/network/index';
import { getCapping } from 'common/tools/ui/capping-helper';

import { waitForLoginStatus } from 'website/containers/user/connection';
import { openPromoteSignupModal } from 'website/services/modal';

class PromoteSignupOverlayCandidate implements Candidate {
  name = CandidateNames.PromoteSignup;
  componentAnchor: HTMLElement | null;
  figure?: string;
  poster?: string;
  text?: string;

  constructor() {
    this.componentAnchor = document.getElementById('promote-signup-anchor');
    if (this.componentAnchor) {
      this.figure = readAttribute(this.componentAnchor, 'data-figure');
      this.poster = readAttribute(this.componentAnchor, 'data-poster');
      this.text = readAttribute(this.componentAnchor, 'data-text');
    }
  }

  async isEligible() {
    const { loggedIn } = await waitForLoginStatus();

    if (loggedIn) {
      return false;
    }

    const capping = getCapping(LocalStorageKeys.PROMOTE_SIGNUP_MODAL_CAPPING);

    return (
      isNotOnFirstScreen() &&
      capping.now.getTime() < 1596844800000 &&
      (capping.now.getMonth() > capping.month ||
        (capping.counter < 3 && capping.now.getDate() > capping.day + 7))
    );
  }

  render() {
    openPromoteSignupModal({
      figure: this.figure,
      poster: this.poster,
      text: this.text
    });
  }
}

export default PromoteSignupOverlayCandidate;
