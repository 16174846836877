import slugify from 'common/tools/string/slugify';
import { hit } from 'common/tracking/googleanalytics';
import { standardizeTrackingType } from 'common/tracking/helpers';
import socialActionTracking from 'common/tracking/socialActionTracking';

export type TrackOpinionContextType = {
  eventLabel: string | string[];
  entity: { legacyId: number; title: string; typename: string };
  socialActionPlacement: string;
};

const sendHit = (
  eventLabel: string,
  eventAction: string,
  {
    entity: { legacyId, title, typename },
    socialActionPlacement
  }: TrackOpinionContextType
) => {
  hit(
    {
      eventCategory: 'social',
      eventLabel,
      eventAction,
      dimension82: `${legacyId}-${slugify(title)}`, // entity
      dimension83: null, // entity status
      dimension84: standardizeTrackingType(typename), // entity theme
      dimension85: socialActionPlacement, // social action placement
      dimension87: legacyId // entity ID
    },
    true
  );

  socialActionTracking(
    eventLabel === 'reviews' ? 'review' : 'rate',
    eventAction,
    {
      internalId: legacyId,
      title,
      typename
    }
  );
};

export const trackOpinion = (
  eventAction: string,
  { context }: { context: TrackOpinionContextType }
) => {
  // one event could be sent for 2 trackings:
  // review modal sends review and rating tracking
  if (Array.isArray(context.eventLabel)) {
    return context.eventLabel.forEach(label =>
      sendHit(label, eventAction, context)
    );
  }

  return sendHit(context.eventLabel, eventAction, context);
};
