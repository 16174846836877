/* add classes dependencies */
import classNames from 'classnames';

import { addClass, removeClass } from 'common/tools/dom/classes';
import { on, off, delegate } from 'common/tools/dom/events';

import styles from './styles.module.scss';

const OVERLAY_JS_CLASSNAME = 'js-trigger-overlay-close';

const isKeyboardEvent = (e: Event): e is KeyboardEvent => 'key' in e;

class Overlay {
  private html: HTMLElement;
  public container: HTMLDivElement | null = null;
  public content: HTMLDivElement | null = null;
  private closeBtn: HTMLDivElement | null = null;
  private isInjected = false;
  private isCreated = false;
  public isOpen = false;
  private type: string | null = null;
  private closeOlbyEsc: EventListenerOrEventListenerObject | undefined;

  constructor() {
    // DOM Element reference
    this.html = document.documentElement;
  }

  private createCloseHandler(closeHandler?: () => void) {
    const closeHdl = closeHandler || this.close.bind(this);
    this.closeOlbyEsc = (e: Event) => {
      if (isKeyboardEvent(e) && e.key === 'Escape') {
        closeHdl();
      }
    };
    return closeHdl;
  }

  // global Methods
  create(closeHandler?: () => void) {
    if (this.isCreated) {
      return false;
    }

    this.container = document.createElement('div');
    this.content = document.createElement('div');
    this.closeBtn = document.createElement('div');

    this.container.id = this.container.className = styles.overlay;
    this.content.id = this.content.className = styles.overlayContent;
    this.closeBtn.id = this.closeBtn.className = classNames(
      styles.overlayClose,
      OVERLAY_JS_CLASSNAME,
      'icon',
      'icon-cross'
    );

    this.container.append(this.content, this.closeBtn);

    const closeHdl = this.createCloseHandler(closeHandler);

    delegate(this.container, OVERLAY_JS_CLASSNAME, 'click', closeHdl);

    // control creation
    this.isCreated = true;
  }

  setContent(content: string | Element) {
    if (this.content) {
      if (typeof content === 'string') {
        this.content.innerHTML = content;
      } else {
        this.content.appendChild(content);
      }
    }
  }

  inject() {
    if (this.isInjected || !this.container) return false;
    document.body.appendChild(this.container);
    this.isInjected = true;
  }

  empty() {
    if (this.content) this.content.innerHTML = '';
  }

  show(type: string) {
    if (this.isOpen) return false;

    addClass(
      this.html,
      type === 'modal' ? styles.openModal : styles.openOverlay
    );
    this.type = type === 'modal' ? 'modal' : null;

    if (this.closeOlbyEsc) on(window, 'keydown', this.closeOlbyEsc);

    this.isOpen = true;
  }

  hide() {
    if (!this.isOpen) return false;

    removeClass(
      this.html,
      this.type === 'modal' ? styles.openModal : styles.openOverlay
    );

    if (this.closeOlbyEsc) off(window, 'keydown', this.closeOlbyEsc);

    this.type = null;
    this.isOpen = false;
  }

  close = () => {
    this.hide();
    this.empty();
  };

  // global init with controls
  init(content: string | Element, type: string) {
    this.create();
    this.setContent(content);
    this.inject();
    this.show(type);
  }
}

const overlay = new Overlay();

export default overlay;
