import { getUserSocialActionsForEntities } from 'website/actions/SocialActionActions';
import { waitForLoginStatus } from 'website/containers/user/connection';
import store from 'website/store';

const entityIds = new Set();

export const registerEntityForSocialInteractions = entityId => {
  entityIds.add(entityId);
};

export const socialActionsBootstrap = async () => {
  const user = await waitForLoginStatus();
  if (user.loggedIn) {
    store.dispatch(getUserSocialActionsForEntities(Array.from(entityIds)));
  }
};
