// Encapsulate the idea of passing a new object as the first parameter
// to Object.assign to ensure we correctly copy data instead of mutating
import isArray from 'lodash/isArray';
import mergeWith from 'lodash/mergeWith';

import { DeepPartial } from 'common/types';

// ! Please note: new values must replace old ones, not combine
// them. For example, an array must be able to be emptied.
export const updateObject = <T>(
  oldValues: T,
  updatedValues: DeepPartial<T>
) => {
  const data: T = mergeWith(
    {},
    oldValues,
    updatedValues,
    (a: any, b: any[] | undefined) => (isArray(b) ? b : undefined)
  );

  return data;
};
