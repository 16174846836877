import { hasClass } from 'common/tools/dom/classes';
import { isHTMLElement } from 'common/types';

type EventElementTypes =
  | Document
  | Element
  | HTMLCollectionOf<Element>
  | HTMLElement
  | NodeList
  | Window;

/**
 * src : http://stackoverflow.com/questions/7238177/detect-htmlcollection-nodelist-in-javascript
 */

const isNodeList = (
  nodes: any
): nodes is NodeList | HTMLCollectionOf<Element> => {
  const stringRepr = Object.prototype.toString.call(nodes);

  return (
    typeof nodes === 'object' &&
    /^\[object (HTMLCollection|NodeList|Object)]$/.test(stringRepr) &&
    nodes.length !== undefined &&
    (nodes.length === 0 ||
      (typeof nodes[0] === 'object' && nodes[0].nodeType > 0))
  );
};

/**
 * Add a listener to a dom element
 * @param  {EventTarget}  element   The DOM element that will receive the event
 * @param  {String}       name      The event name
 * @param  {Function}     callback  Callback function
 * @param  {boolean}      capture   Initiate event capture (default false)
 * @return {void}
 */
export const on = (
  element: EventElementTypes,
  name: string,
  callback: EventListenerOrEventListenerObject,
  capture = false
) => {
  if (isNodeList(element)) {
    for (let i = 0; i < element.length; ++i) {
      element[i].addEventListener(name, callback, capture); // Calling myNodeList.item(i) isn't necessary in JavaScript
    }
  } else {
    element.addEventListener(name, callback, capture);
  }
};

/**
 * Remove a listener
 * @param  {EventTarget}  element   The DOM element that will receive the event
 * @param  {String}       name      The event name
 * @param  {Function}     callback  Callback function
 * @param  {boolean}      capture   Initiate event capture (default false)
 * @return {void}
 */
export const off = (
  element: EventElementTypes | null,
  name: string,
  callback: EventListenerOrEventListenerObject,
  capture = false
) => {
  if (isNodeList(element)) {
    for (let i = 0; i < element.length; ++i) {
      element[i].removeEventListener(name, callback, capture); // Calling myNodeList.item(i) isn't necessary in JavaScript
    }
  } else if (element) {
    element.removeEventListener(name, callback, capture);
  }
};

/**
 * Add an event that will fire only once
 * @param  {EventTarget}  element   The DOM element that will receive the event
 * @param  {String}       name      The event name
 * @param  {Function}     callback  Callback function
 * @param  {boolean}      capture   Initiate event capture (default false)
 * @return {void}
 */
export const once = (
  element: EventElementTypes,
  name: string,
  callback: (e: Event) => void,
  capture = false
) => {
  const tmp = (ev: Event) => {
    off(element, name, tmp, capture);
    callback.call(element, ev);
  };
  on(element, name, tmp, capture);
};

/**
 * Wait for page load
 *
 * @return {Promise} a promise that resolves when page is loaded
 */
export const deferToLoad = () => {
  return new Promise(resolve => {
    if (document.readyState === 'complete') {
      resolve(undefined);
    } else {
      once(window, 'load', () => {
        resolve(undefined);
      });
    }
  });
};

/**
 * Event delegation on parent
 * @param  {DomElement}  element   The element (parent) who receive the delegation event (bublle event)
 * @param  {string}      className The class to check for collection element who are events target
 * @param  {string}      event the event to check on delegation
 * @param  {function}    cb the callback to invoke once the event delegation is trigger
 * @return {void}
 */
export const delegate = (
  element: HTMLElement,
  className: string,
  event: string,
  cb: (e: Event) => void
) => {
  const thisEvent = event;
  element.addEventListener(thisEvent, (e: Event) => {
    let eventTarget = e.target;

    while (isHTMLElement(eventTarget) && eventTarget !== element) {
      if (hasClass(eventTarget, className)) {
        cb.call(eventTarget, e);
        break;
      }
      eventTarget = eventTarget?.parentNode;
    }
  });
};

export const live = (
  elementQuerySelector: string,
  eventType: string,
  callback: (e: Event, el?: Node | null) => void
) => {
  document.addEventListener(eventType, event => {
    const qs = document.querySelectorAll(elementQuerySelector);
    if (qs) {
      let el = event.target as Node | null | undefined;
      let index = -1;
      // eslint-disable-next-line
      while (el && (index = Array.prototype.indexOf.call(qs, el)) === -1) {
        el = el.parentElement;
      }

      if (index > -1) {
        callback(event, el);
      }
    }
  });
};
