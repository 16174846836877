import forceNumber from 'common/tools/number/forceNumber';
import { isString } from 'common/types';

export type Attribute<T, D> = T | D | string;

// Returning an any type because JSON.parse returns an any type
const readAttribute = <T, D = undefined>(
  domElement: Element | EventTarget,
  attributeName: string,
  defaultValue?: D
) => {
  let value =
    domElement instanceof Element
      ? domElement.getAttribute(attributeName)
      : null;
  if (value === null) {
    // return D
    return defaultValue as D;
  }
  try {
    // return T
    value = JSON.parse(value);
  } catch (e) {
    // return string
    // do nothing
  }
  return value as T | string;
};

export const readNumberAttribute = (
  domElement: Element,
  attributeName: string,
  defaultValue?: number
) => forceNumber(readAttribute(domElement, attributeName), defaultValue);

export const readBooleanAttribute = (
  domElement: Element,
  attributeName: string,
  defaultValue?: boolean
) => {
  const value = readAttribute<boolean, boolean | undefined>(
    domElement,
    attributeName,
    defaultValue
  );

  return value === undefined || isString(value) ? defaultValue ?? false : value;
};

export default readAttribute;
