import { path } from 'common/tools/network/routing';
import { getImageUrl } from 'common/tools/network/url';
import get from 'common/tools/objects/get';
import unescape from 'common/tools/string/unescape';

export function standardizeEntityType(item) {
  return (item.entity_type || '').toLowerCase();
}

export function getItemUrl(item) {
  let url = '';
  let videoUrl = null;
  let videoId = get(item, ['data', 'default_video']);

  switch (item.entity_type) {
    case 'movie':
      // route moviepage
      url = path('moviepage', { movie: item.data.id });
      videoUrl = videoId
        ? path('moviepage_videos_trailer', {
            movie: item.data.id,
            idMedia: videoId
          })
        : null;
      break;
    case 'series':
      if (item.data.is_program) {
        // route program
        url = path('program', { program: item.data.id });
      } else {
        // route seriespage
        url = path('seriespage', { series: item.data.id });
        videoUrl = videoId
          ? path('seriespage_videos_trailer', {
              series: item.data.id,
              idMedia: videoId
            })
          : null;
      }
      break;
    case 'person':
      // route person
      url = path('person', { person: item.data.id });
      break;
    case 'theater':
      // route theaterpage
      url = path('theater_showtimes', { idTheater: item.data.id });
      break;
    case 'program':
      // route program
      url = path('program', { program: item.data.id });
      break;
    default:
      url = '';
  }
  return { url, videoUrl };
}

export function fixThumbnail(item) {
  return getImageUrl({
    src: item.data.poster_path || '/commons/v9/common/empty/empty_portrait.png',
    format: { cw: 75, ch: 100 }
  });
}

export function prepare(results) {
  const preparedResults = [];

  results.forEach(item => {
    const preparedData = {};

    preparedData.id = item.data.id;
    preparedData.entitytype = standardizeEntityType(item);
    preparedData.itemUrl = getItemUrl(item).url;
    preparedData.thumbnail = fixThumbnail(item);
    preparedData.title = unescape(item.label);
    preparedData.title_original = unescape(item.original_label);
    preparedData.sponsored = item.sponsored;
    preparedData.campaignName = item.campaign_name ? item.campaign_name : null;
    preparedData.campaignId = item.campaign_id ? item.campaign_id : null;

    if (preparedData.entitytype === 'theater') {
      preparedData.meta = {
        city: get(item, ['data', 'city']),
        zip: get(item, ['data', 'zip'])
      };
    }

    if (
      preparedData.entitytype === 'movie' ||
      preparedData.entitytype === 'series'
    ) {
      preparedData.itemVideoUrl = getItemUrl(item).videoUrl;
      preparedData.meta = {
        year: get(item, ['data', 'year'])
      };
    }

    if (preparedData.entitytype === 'movie') {
      preparedData.meta = {
        ...preparedData.meta,
        director: get(item, ['data', 'director_name'])
      };
    }

    if (preparedData.entitytype === 'series') {
      preparedData.meta = {
        ...preparedData.meta,
        creator: get(item, ['data', 'creator_name'])
      };
    }

    if (preparedData.entitytype === 'person') {
      const gender = item.gender ? item.gender.toLowerCase() : 'male';
      preparedData.meta = {
        activities: get(item, ['data', 'activities']),
        nationality: get(item, ['data', 'nationality', 'adjective', gender])
      };
    }

    preparedResults.push(preparedData);
  });

  return preparedResults;
}
