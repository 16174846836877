import Blazy from 'blazy';

import eventEmitter, { EventsTypes } from 'common/services/events/eventEmitter';
import onUserAction from 'common/tools/ui/defer-on-user-action';
import { runAfterDeferAssets } from 'common/tools/ui/load-assets';

let bLazy: BlazyInstance;

// for the documentation : https://dinbror.dk/blog/blazy/
function initBlazy() {
  bLazy = new Blazy({
    selector: 'img[data-src], iframe[data-src]',
    offset: 550
  });
}

function revalidate() {
  if (!bLazy) {
    return;
  }

  bLazy.revalidate();
}

export default function initLazyLoad() {
  eventEmitter.on(EventsTypes.LAZY_REVALIDATE, revalidate);
  eventEmitter.on(EventsTypes.MQ_STATE, revalidate);

  runAfterDeferAssets(() => {
    onUserAction(initBlazy);
  });
}

// Experimental javascript API
// https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
//
// The IntersectionObserver interface of the Intersection Observer API provides a way to
// asynchronously observe changes in the intersection of a target element with an ancestor
// element or with a top-level document's viewport. The ancestor element or viewport is referred
// to as the root.
export const lazyLoadCall = (
  anchors: Element[],
  callback: (anchor: Element) => void,
  options?: IntersectionObserverInit
) => {
  if (window.IntersectionObserver) {
    const intersectionObserver = new window.IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.intersectionRatio > 0) {
          // prevent recall
          intersectionObserver.unobserve(entry.target);
          callback(entry.target);
        }
      });
    }, options);

    for (const anchor of anchors) intersectionObserver.observe(anchor);
  } else {
    // when IntersectionObserver is unavailable
    // abort lazy loading callback
    for (const anchor of anchors) callback(anchor);
  }
};
