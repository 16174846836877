import { RewindModalParams } from 'common/components/RewindModal';
import { ShowtimeModalParams } from 'common/components/ShowtimeModal';
import { ModalNames } from 'common/module/ui/lazy-modal/constant-types';
import { hit } from 'common/tracking/googleanalytics';
import { standardizeTrackingType } from 'common/tracking/helpers';
import {
  trackCloseRewindModal,
  trackOpenRewindModal
} from 'common/tracking/rewindModal';

import { AccountNeedsConfirmationModalParams } from 'website/services/modal/types';
import { trackReservationImpression } from 'website/tracking/listener/reservationTracking';

type Params<T extends ModalNames> =
  T extends ModalNames.ACCOUNT_NEEDS_CONFIRMATION
    ? AccountNeedsConfirmationModalParams
    : T extends ModalNames.SHOWTIME_MODAL
      ? ShowtimeModalParams
      : T extends ModalNames.REWIND_MODAL
        ? RewindModalParams
        : undefined;

export const trackOpenModal = <T extends ModalNames>(
  modalName: T,
  params: Params<T>
) => {
  switch (modalName) {
    case ModalNames.ACCOUNT_NEEDS_CONFIRMATION:
      hit(
        {
          eventAction:
            (params as AccountNeedsConfirmationModalParams).trackingContext
              ?.eventAction || 'impression_modal',
          eventCategory: 'social',
          eventLabel: 'account_activation',
          dimension84: standardizeTrackingType(
            (params as AccountNeedsConfirmationModalParams).trackingContext
              ?.entityTypename
          )
        },
        true
      );
      break;
    case ModalNames.PROMOTE_AFFINITY:
      hit(
        {
          eventAction: 'pop_up',
          eventCategory: 'recommandation_program',
          eventLabel: 'impression'
        },
        true
      );
      break;
    case ModalNames.PROMOTE_SIGNUP:
      hit(
        {
          eventAction: 'pop_up',
          eventCategory: 'recommandation_signup',
          eventLabel: 'impression'
        },
        true
      );
      break;
    case ModalNames.SHOWTIME_MODAL:
      trackReservationImpression(
        (params as ShowtimeModalParams).trackingContext
      );
      break;
    case ModalNames.REWIND_MODAL:
      trackOpenRewindModal();
      break;
  }
};

export const trackCloseModal = <T extends ModalNames>(
  modalName: T,
  params: Params<T>
) => {
  switch (modalName) {
    case ModalNames.PROMOTE_AFFINITY:
      hit(
        {
          eventAction: 'pop_up',
          eventCategory: 'recommandation_program',
          eventLabel: 'close'
        },
        true
      );
      break;
    case ModalNames.PROMOTE_SIGNUP:
      hit(
        {
          eventAction: 'pop_up',
          eventCategory: 'recommandation_signup',
          eventLabel: 'close'
        },
        true
      );
      break;
    case ModalNames.REWIND_MODAL:
      trackCloseRewindModal(params as RewindModalParams);
      break;
  }
};
