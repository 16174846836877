export enum CandidateNames {
  Newsletter = 'newsletter',
  PromoteAffinity = 'promoteAffinity',
  PromoteSignup = 'promoteSignup'
}

export interface Candidate {
  name: CandidateNames;
  isEligible: () => Promise<boolean>;
  render: () => void;
}

// order of priorities candidates, first occurrence is the most important
const priorities: CandidateNames[] = [
  CandidateNames.PromoteSignup,
  CandidateNames.Newsletter,
  CandidateNames.PromoteAffinity
];

class UnwantedOverlayManager {
  private candidates: Map<CandidateNames, Candidate> = new Map();

  registerCandidate(candidate: Candidate) {
    this.candidates.set(candidate.name, candidate);
  }

  async electCandidate() {
    const eligibleCandidates = new Map<CandidateNames, Candidate>();

    const promises: Promise<boolean>[] = [];
    const candidates = Array.from(this.candidates.values());

    candidates.forEach(candidate => {
      promises.push(candidate.isEligible());
    });

    const results = await Promise.all(promises);

    results.forEach((isEligible, index) => {
      const candidate = candidates[index];
      if (isEligible) {
        eligibleCandidates.set(candidate.name, candidate);
      }
    });

    if (eligibleCandidates.size === 0) {
      return;
    }

    // select the first match in priorities order
    for (let idx = 0; idx < priorities.length; idx++) {
      const candidate = eligibleCandidates.get(priorities[idx]);
      if (candidate) {
        return candidate.render();
      }
    }
  }
}

export { UnwantedOverlayManager as UnwantedOverlayManagerForTests };
export default new UnwantedOverlayManager();
