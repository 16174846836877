/**
 * description: Multiples methods for class manipulation
 * source: http://toddmotto.com/apollo-js-standalone-class-manipulation-api-for-html5-and-legacy-dom/
 * */

/**
 * Check if an element has a class
 * @param  {HTMLElement}  element   The element to check
 * @param  {string}  className the class to check
 * @return {Boolean}           true if the element has the class
 */
export const hasClass = (
  element: Element | HTMLElement | undefined,
  className: string
) => element?.classList.contains(className) ?? false;

/**
 * Add a class to an element
 * @param {HTMLElement} element   The element to update
 * @param {String} className The class to add
 *
 * @return {void}
 */
export const addClass = (
  element: Element | HTMLElement | undefined,
  className: string
) => {
  if (!element || hasClass(element, className)) {
    return false;
  }
  element.classList.add(className);
  return true;
};

/**
 * Remove a class from an element
 * @param {HTMLElement} element   The element to update
 * @param {String} className The remove to remove
 *
 * @return {void}
 */
export const removeClass = (
  element: Element | HTMLElement | undefined,
  className: string
) => {
  if (!element || !hasClass(element, className)) {
    return false;
  }
  element.classList.remove(className);
  return true;
};

/**
 * Toggle a class from an element
 * @param {HTMLElement} element   The element to update
 * @param {String} className The class to toggle
 *
 * @return {void}
 */
export const toggleClass = (
  element: Element | HTMLElement | undefined,
  className: string
) => element?.classList.toggle(className) ?? false;

/**
 * replace a class by another from an element
 * @param {HTMLElement} element   The element to update
 * @param {String} oldClassName class to remove
 * @param {String} newClassName class to add
 *
 * @return {void}
 */
export const replaceClass = (
  element: HTMLElement | undefined,
  oldClassName: string,
  newClassName: string
) => element?.classList.replace(oldClassName, newClassName) ?? false;
