import classnames from 'classnames';
import React, {
  DetailedHTMLProps,
  HTMLAttributes,
  MouseEvent as ReactMouseEvent,
  useCallback,
  useMemo
} from 'react';

import { openRewindModal } from 'common/services/modal';
import { path } from 'common/tools/network/routing';
import trans from 'common/tools/translations/trans';

type RewindMenuProps = DetailedHTMLProps<
  HTMLAttributes<HTMLAnchorElement>,
  HTMLAnchorElement
> & { logged?: boolean };
const RewindMenu = ({ className, logged, ...props }: RewindMenuProps) => {
  const handleClick = useCallback(
    (e: ReactMouseEvent<HTMLAnchorElement, MouseEvent>) => {
      e.preventDefault();
      openRewindModal();
    },
    []
  );

  const menuLabel = useMemo(() => trans('rewind.menu.button'), []);

  return (
    <a
      className={classnames(className, 'rewind-menu-link')}
      {...props}
      {...(logged
        ? { href: '#', onClick: handleClick }
        : { href: path('signin') })}
    >
      {menuLabel}
    </a>
  );
};

export default RewindMenu;
