import { hit } from 'common/tracking/googleanalytics';

export enum Origin {
  MyMoviesHeader = 'completed_button',
  MyMoviesEmptyState = 'empty_state_button',
  BoosterThumbnail = 'booster_thumbnail',
  MyBrandMenu = 'my_brand_menu'
}

export const trackPermanentSpeednoterButtonClick = (origin: Origin) => {
  hit(
    {
      eventCategory: 'my_brand',
      eventAction: origin,
      eventLabel: 'entities_list'
    },
    true
  );
};
