import { getConsentForAnalitycs } from 'common/consent/Didomi';
import * as events from 'common/tools/dom/events';
import readAttribute from 'common/tools/dom/readAttribute';
import {
  hit,
  hover,
  print,
  pageview,
  sendPendingGaCalls,
  createUA,
  setCommonDimensions,
  anonimizeIp
} from 'common/tracking/googleanalytics';

function sendPageViewEvent() {
  if (
    typeof global.trackingViewPage === 'undefined' ||
    global.trackingViewPage
  ) {
    pageview();
  }
}

function setDomTracking() {
  const trackOnPrint = document.querySelectorAll(
    "[data-ga-trigger='impression'], [data-ga-trigger='both'], [data-ga-trigger='bothonce']"
  );
  for (const element of trackOnPrint) {
    const eventData = readAttribute(element, 'data-ga');
    if (Array.isArray(eventData)) {
      eventData.map(data => print(data));
    } else {
      print(eventData);
    }
  }

  const trackOnClick = document.querySelectorAll(
    "[data-ga-trigger='clic'], [data-ga-trigger='both'], [data-ga-trigger='cliconce'], [data-ga-trigger='bothonce']"
  );
  events.on(trackOnClick, 'click', function sendGa() {
    const eventData = readAttribute(this, 'data-ga');
    const eventActionName = readAttribute(this, 'data-ga-trigger');
    const isNonInteraction = !!readAttribute(this, 'data-ga-non-interaction');
    if (Array.isArray(eventData)) {
      eventData.map(data => hit(data, isNonInteraction));
    } else {
      hit(eventData, isNonInteraction);
    }

    // NOTE: fix temp : add bothonce selector
    // maybe use once function instead of on to bind these click events?
    // need to resolve once nodelist problem first, reference branch : fix/events/once
    if (eventActionName.indexOf('once') !== -1) {
      events.off(this, 'click', sendGa);
    }
  });

  const trackOnHover = document.querySelectorAll(
    "[data-ga-trigger='hover'], [data-ga-trigger='hoveronce']"
  );

  events.on(trackOnHover, 'mouseenter', function sendGa() {
    const eventData = readAttribute(this, 'data-ga');
    const eventActionName = readAttribute(this, 'data-ga-trigger');
    if (Array.isArray(eventData)) {
      eventData.map(data => hover(data));
    } else {
      hover(eventData);
    }

    if (eventActionName === 'hoveronce') {
      events.off(this, 'mouseenter', sendGa);
    }
  });
}

export async function initForWebsite() {
  const hasGaConsent = await getConsentForAnalitycs();
  createUA(hasGaConsent);
  setCommonDimensions(hasGaConsent);
  anonimizeIp(hasGaConsent);
  sendPageViewEvent();
  setDomTracking();
  sendPendingGaCalls();
}

export async function initForSinglePageApp(settings = {}) {
  const hasGaConsent = await getConsentForAnalitycs();
  createUA(hasGaConsent);
  setCommonDimensions(hasGaConsent);
  anonimizeIp(hasGaConsent);
  setDomTracking();
  sendPendingGaCalls();
  if (settings && settings.sendPageView) {
    sendPageViewEvent();
  }
}
