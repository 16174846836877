import { requestAnimationFrame } from 'common/tools/functions/request-animation-frame';
import isNumeric from 'common/tools/string/isNumeric';
import { isHTMLElement } from 'common/types';

const HEADER_HEIGHT_COMPENSATION = 80;

/**
 * Get the top position of an element in the document
 * @param  {DomElement} element Dom element to check
 * @return {Number}             it's top position
 */
const getTop = (element: HTMLElement | null) => {
  // return value of html.getBoundingClientRect().top ... IE : 0, other browsers : -pageYOffset
  if (element?.nodeName === 'HTML') {
    return -window.scrollY;
  }
  return element ? element.getBoundingClientRect().top + window.scrollY : 0;
};

/**
 * Ease In and  Out Cubic function
 * @see http://blog.greweb.fr/2012/02/bezier-curve-based-easing-functions-from-concept-to-implementation/
 *
 * @param  {Number} t The entry value
 * @return {Number}   The computed output
 */
const easeInOutCubic = (t: number) =>
  t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1; // eslint-disable-line

/**
 * Compute the scroll position
 * @param  {Number} start    start of the scroll
 * @param  {Number} end      end of the scroll
 * @param  {Number} elapsed  Time elapsed in the animation
 * @param  {Number} duration duration of the animation
 * @return {Number}          the scroll position for these parameters
 */
const position = (
  start: number,
  end: number,
  elapsed: number,
  duration: number
) => {
  if (elapsed > duration) {
    return end;
  }
  return start + (end - start) * easeInOutCubic(elapsed / duration);
};

/**
 * Smooth scrolling to an element / position
 * @param  {DomElement|Number}  el           The target of the scroll, an element or numeric value
 * @param  {Number}             duration    Scroll duration, default 500
 * @param  {Boolean}            compensate  Set to true to compensate for the header height of AC Websites
 * @param  {Function|false}     callback    A callback called at the end of the animation
 * @param  {DOM}                context     context of the call, default = window
 * @param  {String}             direction   direction of the scroll, default = top
 * @param  {Number}             startScroll a scroll offset
 * @return {void}
 */
export default (
  el: HTMLElement | number | null,
  duration = 500,
  compensate = false,
  callback?: ((el: HTMLElement | number | null) => void) | false,
  context: Element | Window = window,
  direction = 'top',
  startScroll: number | null | undefined = null
) => {
  const start = startScroll !== null ? startScroll : window.scrollY;
  let end: number;

  if (isNumeric(el)) {
    end = parseInt(`${el}`, 10);
  } else {
    end = getTop(el);
  }

  if (compensate) {
    end -= HEADER_HEIGHT_COMPENSATION;
  }

  const clock = Date.now();

  const step = function step() {
    const elapsed = Date.now() - clock;
    if (context !== window && isHTMLElement(context)) {
      if (direction !== 'top') {
        context.scrollLeft = position(start, end, elapsed, duration);
      } else {
        context.scrollTop = position(start, end, elapsed, duration);
      }
    } else {
      window.scroll(0, position(start, end, elapsed, duration));
    }

    if (elapsed > duration) {
      if (typeof callback === 'function') {
        callback(el);
      }
    } else {
      requestAnimationFrame(step);
    }
  };
  step();
};
