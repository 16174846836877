import classeNames from 'classnames';
import React from 'react';

import Button from 'common/components/Button';
import useCopyToClipboard from 'common/react/hooks/useCopyToClipboard';
import trans from 'common/tools/translations/trans';

type Props = {
  link: string;
  size?: 'sm' | 'md';
};

const CopyButton = ({ link, size }: Props): JSX.Element => {
  const [isCopied, copyToClipoard] = useCopyToClipboard();

  return (
    <Button
      type="full"
      theme="grey"
      variant="square"
      onClick={() => {
        copyToClipoard(link);
      }}
      title={trans('common.copy')}
      size={size}
    >
      <i
        className={classeNames('icon', {
          'icon-link-square': !isCopied,
          'icon-tick-square': isCopied
        })}
      />
      <span className="txt">{trans('common.copy')}</span>
    </Button>
  );
};

export default CopyButton;
