import { TrackingEventNames } from 'common/constants/trackingEventsNames';
import { updateUserMenu } from 'common/module/ui/header-main';
import eventEmitter, { EventsTypes } from 'common/services/events/eventEmitter';
import * as events from 'common/tools/dom/events';
import readAttribute from 'common/tools/dom/readAttribute';
import { getSignUpUrl } from 'common/tools/userspace';

import { AuthenticatedLinkTrackingContext } from 'website/components/user/AuthenticatedLink';
import { UserState } from 'website/reducers/user';
import store from 'website/store/';

let loggedIn = false;
let passiveLoginDone = false;

/**
 * Handles clicks on login triggers directly from the web page
 *
 * Fires a EventsTypes.LOGIN_REQUEST to trigger the login
 *
 * @param  {Event} event the event
 * @return {boolean}]
 */
const loginTriggerClick = (event: Event) => {
  event.preventDefault();
  const title = event.currentTarget
    ? readAttribute<string, undefined>(
        event.currentTarget,
        'data-login-message'
      )
    : undefined;
  eventEmitter.emit(EventsTypes.LOGIN_REQUEST, title ? { title } : null);
  return false;
};

const redirectToConnect = () => {
  document.location.href = getSignUpUrl(window.location.href);
};

/**
 * Helper method to run code after the login status has been checked
 *
 * @return {Promise} a promise resolved with user info
 */
export const waitForLoginStatus = () => {
  let state;
  return new Promise<UserState>(resolve => {
    if (passiveLoginDone) {
      state = store.getState();
      resolve(state.user);
    } else {
      eventEmitter.once(EventsTypes.PASSIVE_LOGIN_DONE, () => {
        state = store.getState();
        resolve(state.user);
      });
    }
  });
};

/**
 * Helper method to run code after login. If the user
 * is not connected he will be prompted to do so
 *
 * @param {object} params
 * @return {Promise} a promise resolved with null or user info
 */
export const askForUserToLogIn = ({
  trackingContext = {}
}: {
  trackingContext?: AuthenticatedLinkTrackingContext;
}) => {
  let state;
  return new Promise<UserState>(resolve => {
    if (loggedIn) {
      state = store.getState();
      resolve(state.user);
    } else {
      eventEmitter.once(EventsTypes.LOGIN_SUCCESS, () => {
        state = store.getState();
        resolve(state.user);
      });
      eventEmitter.emit(EventsTypes.LOGIN_REQUEST);

      if (trackingContext) {
        eventEmitter.emit(
          TrackingEventNames.TRACKING_LOGIN_REQUEST,
          trackingContext
        );
      }
    }
  });
};

export default () => {
  // watch state changes
  const unsubscribe1 = store.subscribe(() => {
    const state = store.getState();
    if (state.user.loggedIn !== loggedIn && state.user.loggedIn) {
      unsubscribe1();
      loggedIn = true;
      eventEmitter.emit(EventsTypes.LOGIN_SUCCESS, state.user);
      // login status changed, update the menu
      updateUserMenu(state.user);
    }
  });

  const unsubscribe2 = store.subscribe(() => {
    const state = store.getState();
    if (
      state.user.passiveLoginDone !== passiveLoginDone &&
      state.user.passiveLoginDone
    ) {
      unsubscribe2();
      passiveLoginDone = true;
      eventEmitter.emit(EventsTypes.PASSIVE_LOGIN_DONE);
    }
  });

  // watch login requests coming from the app
  eventEmitter.on(EventsTypes.LOGIN_REQUEST, redirectToConnect);

  // watch clicks on "login-trigger" links
  const anchors = document.getElementsByClassName('login-trigger');
  events.on(anchors, 'click', loginTriggerClick);
};
