import * as classes from 'common/tools/dom/classes';
import * as events from 'common/tools/dom/events';
import { onYScroll } from 'common/tools/dom/on-scroll';
import scrollTo from 'common/tools/dom/scrollTo';
import debounce from 'common/tools/functions/debounce';

export default function initBackToTop() {
  const elem = document.getElementById('back-top');
  const windowHeight = 500;

  if (!elem) {
    return false;
  }

  onYScroll(
    debounce(function scrolling() {
      const scrollCoord =
        window.scrollY ||
        window.pageYOffset ||
        document.documentElement.scrollTop;

      if (classes.hasClass(elem, 'is-visible')) {
        if (scrollCoord < windowHeight) {
          classes.removeClass(elem, 'is-visible');
        }
      } else if (scrollCoord > windowHeight) {
        classes.addClass(elem, 'is-visible');
      }
    })
  );

  events.on(elem, 'click', event => {
    event.preventDefault();
    scrollTo(0, 800);
  });
}
