import {
  InternalImage,
  Maybe,
  Movie,
  MovieData,
  MovieFlags,
  PartialDate
} from 'types/graphql-api.generated';

import {
  Entity,
  Opinion,
  WantToSee,
  SeenIt,
  Helpful,
  Unhelpful,
  UserAffinity,
  EntityTypename,
  SocialActionTypename
} from 'website/types';

/* JsEntity SF module types */
export type MovieJsEntity = {
  flags: { isComingSoon: boolean };
  id: string;
  productionYear: number | null;
  releaseDate: string;
  social: { user_note_i_want_to_see_count: number } | null;
  title: string;
};

type SeasonsConnectionNode = {
  cast?: {
    edges?: {
      node?: {
        actor?: {
          __typename?: 'Person';
          internalId?: number | null;
          lastName?: string | null;
          firstName?: string | null;
          seo?: {
            browsable?: boolean;
          };
        };
        voiceActor?: string | null;
        originalVoiceActor?: string | null;
        rank?: number;
      };
    }[];
  };
  internalId?: number;
  number?: number;
  status?: SeasonGraphStatus;
};

export type SeriesJsEntity = {
  id: string;
  title: string;
  releaseDate: string;
  flags: { isComingSoon: boolean };
  broadcasted_seasons?: string[];
  seasons?: {
    __typename?: 'seasonsConnection';
    edges?: {
      node?: SeasonsConnectionNode;
    }[];
    nodes?: SeasonsConnectionNode[];
  };
};

export type SeasonJsEntity = {
  id: string;
  number: number;
  season_status: SeasonStatus;
};

export type EpisodeJsEntity = {
  id: string;
  title: string;
};

export type ProgramJsEntity = {
  id: string;
  title: string;
};

export type UserReviewJsEntity = {
  id: string;
};

export type JsEntity =
  | SeriesJsEntity
  | MovieJsEntity
  | SeasonJsEntity
  | EpisodeJsEntity
  | ProgramJsEntity
  | UserReviewJsEntity;

/* Seasons status */
export type SeasonStatus = 122002 | 122003 | 122004 | 122005 | 122006;
export type SeasonGraphStatus =
  | 'IN_PROGRESS'
  | 'ENDED'
  | 'CANCELLED'
  | 'PILOT'
  | 'TO_COME';

/* Graph entities */
export type GraphNode<T extends string = string> = {
  id: string;
  __typename: T;
};

export type MovieGraphEntity = GraphNode<EntityTypename.Movie> &
  Pick<Movie, 'title'> & {
    poster?: Maybe<Pick<InternalImage, 'path'>>;
    flags?: Maybe<Pick<MovieFlags, 'isComingSoon'>>;
    releases?: Maybe<
      Array<
        Maybe<{
          releaseDate?: Maybe<Pick<PartialDate, 'date'>>;
        }>
      >
    >;
    userAffinity?: Maybe<UserAffinity>;
    data?: Maybe<Pick<MovieData, 'productionYear'>>;
  };

export type SeriesGraphEntity = GraphNode<EntityTypename.Series> & {
  title: string;
  poster: {
    path: string | null;
    file_name: string | null;
  } | null;
  flags: { isComingSoon: boolean } | null;
  originalBroadcast: {
    firstAiredDate: {
      date: string;
      precision: 'day' | 'month' | 'year';
    };
  } | null;
  userAffinity?: UserAffinity;
};

export type SeasonGraphEntity = GraphNode<EntityTypename.Season> & {
  number: number;
  status: SeasonGraphStatus;
};

export type EpisodeGraphEntity = GraphNode<EntityTypename.Episode> & {
  title: string;
};

export type ProgramGraphEntity = GraphNode<EntityTypename.Program> & {
  title: string;
};

export type UserReviewGraphEntity = GraphNode<EntityTypename.UserReview> &
  Record<string, unknown>;

export type GraphEntity =
  | MovieGraphEntity
  | SeriesGraphEntity
  | SeasonGraphEntity
  | EpisodeGraphEntity
  | ProgramGraphEntity
  | UserReviewGraphEntity;

type BaseGraphSocialAction<T extends SocialActionTypename> = GraphNode<T> & {
  updatedAt: string;
  relatedEntity: {
    id: string;
  };
};

export type GraphOpinion =
  BaseGraphSocialAction<SocialActionTypename.Opinion> & {
    content: {
      rating: number;
      review: string;
      status:
        | 'ACCEPTED'
        | 'BEING_CHECKED'
        | 'BLOCKED'
        | 'DENIED'
        | 'MUST_BE_CHECKED'
        | 'PENDING'
        | 'PUBLISHED'
        | 'REPORTED';
    };
  };

type GraphWantToSee = BaseGraphSocialAction<SocialActionTypename.WantToSee>;

type GraphSeenIt = BaseGraphSocialAction<SocialActionTypename.SeenIt>;
type GraphHelpful = BaseGraphSocialAction<SocialActionTypename.Helpful>;
type GraphUnhelpful = BaseGraphSocialAction<SocialActionTypename.Unhelpful>;

export type GraphSocialAction =
  | GraphOpinion
  | GraphWantToSee
  | GraphSeenIt
  | GraphHelpful
  | GraphUnhelpful;

export type GraphUserEntityLeaf = GraphNode<string> & {
  entity:
    | MovieGraphEntity
    | SeriesGraphEntity
    | SeasonGraphEntity
    | EpisodeGraphEntity
    | ProgramGraphEntity
    | UserReviewGraphEntity;
  opinion: GraphOpinion | null;
  wantToSee: GraphWantToSee | null;
  seenIt: GraphSeenIt | null;
  helpful: GraphHelpful | null;
  unhelpful: GraphUnhelpful | null;
};

export type UserEntityLeaf = {
  entity: Entity;
  opinion: Opinion | null;
  wantToSee: WantToSee | null;
  seenIt: SeenIt | null;
  helpful: Helpful | null;
  unhelpful: Unhelpful | null;
};

export function isGraphOpinion(
  candidate: GraphSocialAction
): candidate is GraphOpinion {
  return candidate.__typename == 'Opinion';
}
