import request from 'common/tools/network/request';
import { path } from 'common/tools/network/routing';

export function geolocalizeIp() {
  return request(path('geoiplocalize'));
}

export function searchProgramVideos(idProgram: string, term: string) {
  return request(
    path('internal_api_program_videos_autocomplete', {
      program: idProgram,
      search: term
    }),
    {
      credentials: 'same-origin'
    }
  );
}
