import classnames from 'classnames';
import React, { MouseEvent, ReactNode } from 'react';

type Props = {
  children: ReactNode;
  className?: string;
  disabled?: boolean;
  href?: string;
  onClick?: (e: MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => void;
  size?: Size;
  submit?: boolean;
  targetHref?: boolean;
  theme?: Theme;
  title?: string;
  type?: Type;
  variant?: 'square';
};

export type Theme =
  | 'social-ac'
  | 'inverse'
  | 'default'
  | 'dark'
  | 'white'
  | 'grey'
  | 'danger'
  | 'whatsapp'
  | 'tw'
  | 'fb'
  | 'countdown';

export type Type = 'full' | 'ghost';

export type Size = 'lg' | 'md' | 'sm' | 'xs';

const sizeArray: Size[] = ['lg', 'md', 'sm', 'xs'];
export const isSize = (candidate: any): candidate is Size =>
  sizeArray.includes(candidate);

const typeArray: Type[] = ['full', 'ghost'];
export const isType = (candidate: any): candidate is Type =>
  typeArray.includes(candidate);

const themeArray: Theme[] = [
  'countdown',
  'danger',
  'dark',
  'default',
  'fb',
  'grey',
  'inverse',
  'social-ac',
  'tw',
  'whatsapp',
  'white'
];
export const isTheme = (candidate: any): candidate is Theme =>
  themeArray.includes(candidate);

const Button = ({
  children,
  className,
  disabled = false,
  href,
  onClick,
  size = 'md',
  submit = false,
  targetHref = false,
  theme = 'default',
  title,
  type = 'full',
  variant
}: Props) => {
  const buttonClasses = classnames(
    'button',
    {
      [`button-${theme}-${type}-${variant}`]: theme && type && variant,
      [`button-${theme}-${type}`]: theme && type && !variant,
      [`button-${size}`]: size,
      'button-disabled': disabled
    },
    className
  );

  const commonProps = {
    title: title,
    className: buttonClasses,
    onClick: onClick
  };

  if (href) {
    const hrefProps = {
      ...(targetHref ? { target: '_blank', rel: 'noopener noreferrer' } : {}),
      ...commonProps
    };

    return (
      <a {...hrefProps} href={href}>
        {children}
      </a>
    );
  }

  return (
    <button
      type={submit ? 'submit' : 'button'}
      disabled={disabled}
      {...commonProps}
    >
      {children}
    </button>
  );
};

export default Button;
