import { IMAGE_EMPTY_AVATAR } from 'common/configuration/constants';
import { getImageUrl } from 'common/tools/network/url';

export function getCroppedAvatar(src, dimension = 50) {
  if (src.indexOf('google') !== -1) {
    // image is from google
    return `${src}?sz=${dimension}`;
  }
  if (src.indexOf('acsta') !== -1) {
    return src.replace('i_w_h', `c_${dimension}_${dimension}`);
  }
  return src;
}

export function getDefaultAvatar() {
  return getImageUrl({
    src: IMAGE_EMPTY_AVATAR
  });
}
