import {
  getUserProvenance,
  USER_PROVENANCE_ACCOUNT_CONFIRMATION_ERROR,
  USER_PROVENANCE_ACTIVATION_EMAIL_SENT_ERROR,
  USER_PROVENANCE_ACTIVATION_EMAIL_SENT_SUCCESS,
  USER_PROVENANCE_SIGN_UP,
  USER_PROVENANCE_ONBOARDING
} from 'common/tools/userspace';

import { waitForLoginStatus } from 'website/containers/user/connection';
import {
  openAccountActivationEmailSentModal,
  openAccountConfirmedErrorModal,
  openAccountConfirmedSuccessModal,
  openAccountCreatedSuccessModal,
  openAccountAlreadyConfirmedModal
} from 'website/services/modal';

export default function init() {
  // If you modify this please check also
  // website/containers/user/accountConfirmationReminder.js
  // See also http://gph.is/1J263dv
  const userProvenance = getUserProvenance();

  if (!userProvenance) return;

  if (userProvenance === USER_PROVENANCE_ONBOARDING) {
    openAccountConfirmedSuccessModal();
  } else if (userProvenance === USER_PROVENANCE_ACCOUNT_CONFIRMATION_ERROR) {
    waitForLoginStatus().then(user => {
      if (user.loggedIn && user.account) {
        if (user.account.isEmailValidated) {
          openAccountAlreadyConfirmedModal();
        } else {
          openAccountConfirmedErrorModal();
        }
      }
    });
  } else if (userProvenance === USER_PROVENANCE_SIGN_UP) {
    waitForLoginStatus().then(user => {
      if (user.loggedIn && user.account) {
        openAccountCreatedSuccessModal(user.account.label);
      }
    });
  } else if (userProvenance === USER_PROVENANCE_ACTIVATION_EMAIL_SENT_SUCCESS) {
    waitForLoginStatus().then(user => {
      if (user.loggedIn && user.account && user.account.email) {
        openAccountActivationEmailSentModal(false, user.account.email);
      }
    });
  } else if (userProvenance === USER_PROVENANCE_ACTIVATION_EMAIL_SENT_ERROR) {
    openAccountActivationEmailSentModal(true);
  }
}
