import { CredentialResponse } from 'google-one-tap';
import loadScript from 'load-script';

import { connectWithGoogle } from 'common/api/SsoApi';
import {
  BRAND_HAS_TOS_VALIDATION_WITH_GOOGLE,
  BRAND_HAS_GOOGLE_ONE_TAP
} from 'common/configuration/constants';
import { getConsentForGoogle } from 'common/consent/Didomi';
import emailExist from 'common/services/emailExist';
import loadGoogleClient, {
  getGoogleCredentialResponse
} from 'common/third_party/google';
import { path } from 'common/tools/network/routing';
import { getUrlParams } from 'common/tools/network/url';
import { runAfterDeferAssets } from 'common/tools/ui/load-assets';

import { waitForLoginStatus } from 'website/containers/user/connection';
import postData from 'website/tools/react/postData';

const GOOGLE_ONE_TAP_URL = 'https://accounts.google.com/gsi/client';

const handleCredentialResponse = async (
  credentialResponse: CredentialResponse
) => {
  const credentials = getGoogleCredentialResponse(credentialResponse);
  const accountExist = await emailExist(credentials.email);
  const { returnUrl } = getUrlParams(window.location.href) || {};
  if (accountExist || !BRAND_HAS_TOS_VALIDATION_WITH_GOOGLE) {
    // Send to SSO the OpenId from One Tap
    connectWithGoogle({ token: credentialResponse.credential }).then(() => {
      if (returnUrl) {
        window.location.href = returnUrl;
      } else {
        window.location.reload();
      }
    });
  } else {
    postData(
      path('signup', {
        redirectUrl: returnUrl
      }),
      [{ name: 'GOOGLE_OPEN_ID', value: credentialResponse.credential }]
    );
  }
};

const loadOneTap = async () => {
  const googleConsent = await getConsentForGoogle();

  if (BRAND_HAS_GOOGLE_ONE_TAP && googleConsent) {
    // wait to know if the user is not logged to display google One Tap
    waitForLoginStatus().then(user => {
      if (!user.loggedIn) {
        // lazy load
        runAfterDeferAssets(() => {
          // load google one tap library
          loadScript(GOOGLE_ONE_TAP_URL, null, () => {
            if (window.google) {
              // The google.accounts.id.initialize method initializes
              // the One Tap prompt or the browser native credential manager
              // based on the configuration object.
              // https://developers.google.com/identity/gsi/web/reference/js-reference#google.accounts.id.initialize
              loadGoogleClient(() => {
                // The google.accounts.id.prompt method displays the One Tap prompt
                // or the browser native credential manager after
                // the initialize() method has been invoked.
                //developers.google.com/identity/gsi/web/reference/js-reference#google.accounts.id.prompt
                window.google.accounts.id.prompt();
              }, handleCredentialResponse);
            }
          });
        });
      }
    });
  }
};

export default loadOneTap;
