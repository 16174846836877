import { Maybe, Ticketing } from 'types/graphql-api.generated';

import { BRAND } from 'common/configuration/constants';

// Take the link indicated with the name of the corresponding brand and, if
// it doesn't exist, switch to the "default" link. If there is no data for the
// brand or by default, no session link should appear.
const getTicketingUrl = (ticketing?: Maybe<Array<Maybe<Ticketing>>>) =>
  ticketing?.find(ticket => ticket?.provider === BRAND)?.urls?.[0] ??
  ticketing?.find(ticket => ticket?.provider === 'default')?.urls?.[0];

export default getTicketingUrl;
