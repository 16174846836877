export const COOKIE_INFO_COOKIE = 'ACCOK';
export const USERID_COOKIE = 'ACID';
export const SOCIAL_PROVIDER_COOKIE = 'ACSP';
export const GRAPH_TOKEN_COOKIE = 'GraphToken';

export const ALLOCINE_COUNT_SESSION = '__acts';

export const GEOLOCALISATION_GEOCODE = 'geocode';
export const GEOLOCALISATION_LEVEL1 = 'geolevel1';
export const GEOLOCALISATION_LEVEL2 = 'geolevel2';
export const GEOLOCALISATION_LEVEL3 = 'geolevel3';

export const GEOCODE_COUNTRY_CODE = 'country_code';

export const NEWSLETTER_USER_REGISTRED = 'k7hid';

export const USER_PROVENANCE = 'USER_PROVENANCE';
