import { RELEASE_WEEK_DAY } from 'common/configuration/constants';
import formatDate, { HOURS_FORMATS } from 'common/tools/date/format';

export type DateProcessingFromAString = {
  date: Date;
  hour: string;
  isPast: boolean;
  originalString: string;
  time: number;
};

const DAY: Record<string, number> = {
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
  sunday: 7
};

const getShowtimeReleaseWeekDay = () => RELEASE_WEEK_DAY.toLowerCase();

/**
 * @returns {number} the next week day time by calculating the number of days until it happens and adding it to now
 */
export const getNextWeekStartTime = () => {
  const releaseWeekDay = DAY[getShowtimeReleaseWeekDay()];
  const now = new Date();
  const day = now.getDay();
  const date = now.getDate();
  const month = now.getMonth();
  const year = now.getFullYear();

  const nextWeekDayIn =
    day > releaseWeekDay ? 7 - (day - releaseWeekDay) : releaseWeekDay - day;

  return (
    new Date(year, month, date).getTime() + nextWeekDayIn * 24 * 60 * 60 * 1000
  );
};

export { getShowtimeReleaseWeekDay as getShowtimeReleaseWeekDayForTest };

export const dateProcessingFromAString = (stringDate: string) => {
  // WARNING : issue on safari with new Date only date, we can't use new Date(stringDate);
  // "fix" found on https://stackoverflow.com/questions/6427204/date-parsing-in-javascript-is-different-between-safari-and-chrome
  const dateSplit = stringDate.split(/[^0-9]/);

  const date = new Date(
    parseInt(dateSplit[0], 10),
    parseInt(dateSplit[1], 10) - 1,
    parseInt(dateSplit[2], 10),
    parseInt(dateSplit[3], 10),
    parseInt(dateSplit[4], 10),
    parseInt(dateSplit[5], 10)
  );

  const hour: string = formatDate(date, { ...HOURS_FORMATS.SHORT });

  const time = date.getTime();

  const isPast = time < Date.now();

  return {
    date,
    hour,
    isPast,
    originalString: stringDate,
    time
  };
};
