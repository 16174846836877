import { ROUTE } from 'common/configuration/constants';
import { ShowtimeFilterButtonTracking } from 'common/constants/trackingEventsNames';
import { hit } from 'common/tracking/googleanalytics';
import { GA4Entity } from 'common/tracking/helpers';
import { hit as hit_jan } from 'common/tracking/jan';

import { Filter } from 'website/containers/showtimes/utils/types';

// For each active filter, we add its label if it exists.
const getActiveFilterValues = (filter: Filter) =>
  filter.values
    ?.filter(value => value?.active)
    ?.reduce<Array<string>>((filterAcc, value) => {
      if (value?.key) filterAcc.push(value.key.toLowerCase());
      return filterAcc;
    }, []) || [];

const trackShowtimeFilterButtonClick = ({
  eventLabel,
  filterName,
  movie,
  theater,
  filters
}: ShowtimeFilterButtonTracking) => {
  const filters_value = filterName?.toLowerCase();

  // Retrieve the labels of all active filters.
  const activeFilters = filters?.reduce<Array<string>>((acc, filter) => {
    acc.push(...getActiveFilterValues(filter));
    return acc;
  }, []);

  hit(
    {
      eventAction: 'filters',
      eventCategory: 'ux_questions',
      eventLabel,
      dimension90: filters_value
    },
    true
  );
  hit_jan('internal_click', {
    position_name: 'showtimes_filter',
    site_route: ROUTE,
    movie_entity: movie
      ? GA4Entity(movie.internalId, movie.title)
      : 'undefined',
    theater_entity: theater
      ? GA4Entity(theater.internalId, theater.name)
      : 'undefined',
    filters_value: activeFilters
      ? activeFilters.length
        ? activeFilters.join('|')
        : 'empty'
      : 'undefined'
  });
};

export default trackShowtimeFilterButtonClick;
