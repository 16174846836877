import Cookies from 'cookies-js';

import { SOCIAL_PROVIDER_COOKIE } from 'common/constants/CookieNames';

export type Credentials = {
  email: string;
  name: string;
  termsOfService?: boolean;
  token: string;
};

export const isAuthenticatedViaSocialNetworks = () =>
  ['GG', 'FB'].includes(Cookies.get(SOCIAL_PROVIDER_COOKIE));

export const isAuthenticatedViaFacebook = () =>
  Cookies.get(SOCIAL_PROVIDER_COOKIE) === 'FB';
