import eventEmitter, { EventsTypes } from 'common/services/events/eventEmitter';
import { addClass } from 'common/tools/dom/classes';
import * as events from 'common/tools/dom/events';
import isVisible from 'common/tools/dom/is-visible';

let loaded = false;

async function injectThirdNav(
  thirdNav: HTMLCollectionOf<Element>,
  forcedItem: Element | null
) {
  if (loaded) {
    return;
  }

  const module = await import(
    /* webpackChunkName: "thirdNav.lazy" */ './thirdNav.lazy'
  );
  module.createThirdNav(thirdNav, forcedItem);
  loaded = true;
}

export default function init() {
  const thirdNav = document.getElementsByClassName('js-third-nav');

  if (!thirdNav.length) {
    return;
  }

  for (const nav of thirdNav) {
    const triggerSubNav = nav.getElementsByClassName(
      'js-trigger-third-nav-sub'
    )[0];

    if (triggerSubNav) {
      events.on(triggerSubNav, 'click', () => {
        injectThirdNav(thirdNav, nav);
      });
    }

    const currentItem = nav.getElementsByClassName('current')[0];

    if (currentItem && !isVisible(currentItem)) {
      addClass(
        nav.getElementsByClassName('js-third-sub-nav-holder')[0],
        'current'
      );
    }

    eventEmitter.on(EventsTypes.MQ_STATE, () => {
      injectThirdNav(thirdNav, null);
    });
  }
}
