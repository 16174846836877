import polyfillWithResolvers from 'common/tools/polyfills/withResolvers';

import polyfillFetch from './fetch';

const injectPolyfills = async () => {
  // Using Promise.all to run any polyfill in parallel
  return Promise.all([polyfillFetch(), polyfillWithResolvers()]);
};

export default injectPolyfills;
