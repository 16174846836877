import merge from 'lodash/merge';

import {
  ADD_ENTITIES,
  GET_USER_SOCIAL_ACTIONS_FOR_ENTITIES_SUCCESS,
  CREATE_OPINION_REQUEST,
  CREATE_OPINION_SUCCESS,
  CREATE_SOCIAL_ACTION_REQUEST,
  CREATE_SOCIAL_ACTION_FAILURE,
  CREATE_SOCIAL_ACTION_SUCCESS,
  WRITE_REVIEW_SUCCESS,
  GET_AFFINITY_SUGGESTIONS_SUCCESS,
  OPINION_ACTIONS_RETRIEVAL_SUCCESS,
  CREATE_OPINION_FAILURE,
  DELETE_SOCIAL_ACTION_SUCCESS,
  DELETE_OPINION_SUCCESS,
  DELETE_REVIEW_SUCCESS
} from 'website/constants/ActionTypes';
import { Entity, isHelpful, isUnhelpful } from 'website/types';

import {
  jsEntityMapper,
  userEntityLeafMapper,
  actionMapper,
  typeNameToNodeNameMapper,
  titaniaMapper
} from './helper';
import { JsEntity, GraphUserEntityLeaf, GraphSocialAction } from './types';

export type ActionsPerEntityState = {
  [key: string]:
    | {
        opinion: string | null;
        wantToSee: string | null;
        seenIt: string | null;
        helpful: string | null;
        unhelpful: string | null;
      }
    | undefined;
};

const initialActionPerEntityState = {
  opinion: null,
  wantToSee: null,
  helpful: null,
  unhelpful: null,
  seenIt: null
};

const generateStateUpdateForNewEntities = (
  newEntities: Entity[]
): ActionsPerEntityState => {
  return newEntities.reduce((acc: ActionsPerEntityState, newEntity) => {
    acc[newEntity.id] = {
      ...initialActionPerEntityState
    };
    return acc;
  }, {});
};

const setSocialActionTo = (
  state: ActionsPerEntityState,
  action: { entity?: { id?: string } } | undefined,
  socialActionName: string,
  value: any
) => {
  if (!action?.entity?.id) {
    return state;
  }
  const stateUpdate = {
    [action.entity.id]: {
      [socialActionName]: value
    }
  };
  return merge({}, state, stateUpdate);
};

export default function actionsPerEntity(
  state: ActionsPerEntityState = {},
  action: any
): ActionsPerEntityState {
  const payload = action.payload;
  switch (action.type) {
    case ADD_ENTITIES: {
      const newEntities = jsEntityMapper(Object.values<JsEntity>(payload));
      const stateUpdate = generateStateUpdateForNewEntities(newEntities);
      return merge({}, state, stateUpdate);
    }

    case DELETE_SOCIAL_ACTION_SUCCESS: {
      return setSocialActionTo(
        state,
        action,
        typeNameToNodeNameMapper(action.socialActionTypename),
        null
      );
    }

    case DELETE_REVIEW_SUCCESS:
    case DELETE_OPINION_SUCCESS: {
      return setSocialActionTo(state, action, 'opinion', null);
    }

    case CREATE_SOCIAL_ACTION_REQUEST: {
      return setSocialActionTo(
        state,
        action,
        typeNameToNodeNameMapper(action.socialActionTypename),
        action.transactionId
      );
    }
    case CREATE_SOCIAL_ACTION_SUCCESS: {
      const userEntityLeaf = action.payload;
      const mappedUserEntityLeaf = userEntityLeafMapper(userEntityLeaf);
      const { entity: _, ...socialActions } = mappedUserEntityLeaf;

      const stateUpdate = {
        [action.entity.id]: Object.entries(socialActions).reduce(
          (acc: Record<string, string | null>, [key, value]) => {
            acc[key] = value ? value.id : null;
            return acc;
          },
          {}
        )
      };

      const newState = merge({}, state, stateUpdate);
      return newState;
    }

    case CREATE_SOCIAL_ACTION_FAILURE: {
      return setSocialActionTo(
        state,
        action,
        typeNameToNodeNameMapper(action.socialActionTypename),
        null
      );
    }

    case CREATE_OPINION_REQUEST: {
      return setSocialActionTo(state, action, 'opinion', action.transactionId);
    }

    case WRITE_REVIEW_SUCCESS:
    case CREATE_OPINION_SUCCESS: {
      const userEntityLeaf = action.payload;

      const mappedUserEntityLeaf = userEntityLeafMapper(userEntityLeaf);
      const { entity: _, ...socialActions } = mappedUserEntityLeaf;

      const stateUpdate = {
        [action.entity.id]: Object.entries(socialActions).reduce(
          (acc: Record<string, string | null>, [key, value]) => {
            acc[key] = value ? value.id : null;
            return acc;
          },
          {}
        )
      };

      const newState = merge({}, state, stateUpdate);
      return newState;
    }

    case CREATE_OPINION_FAILURE: {
      return setSocialActionTo(state, action, 'opinion', null);
    }

    case GET_AFFINITY_SUGGESTIONS_SUCCESS: {
      const newEntities = (action.payload.entities || []).map(titaniaMapper);
      const stateUpdate = generateStateUpdateForNewEntities(newEntities);
      return merge({}, state, stateUpdate);
    }

    case GET_USER_SOCIAL_ACTIONS_FOR_ENTITIES_SUCCESS: {
      const userEntityLeaves = payload as GraphUserEntityLeaf[];
      const newState = userEntityLeaves.reduce(
        (acc: ActionsPerEntityState, userEntityLeaf) => {
          const mappedUserEntityLeaf = userEntityLeafMapper(userEntityLeaf);
          const { entity, ...socialActions } = mappedUserEntityLeaf;
          const stateUpdate = {
            [entity.id]: {
              ...Object.entries(socialActions).reduce(
                (
                  acc2: Record<string, string | null>,
                  [socialActionName, socialAction]
                ) => {
                  acc2[socialActionName] = socialAction
                    ? socialAction.id
                    : null;
                  return acc2;
                },
                {}
              )
            }
          };
          return merge(acc, stateUpdate);
        },
        {}
      );
      return merge({}, state, newState);
    }

    case OPINION_ACTIONS_RETRIEVAL_SUCCESS: {
      const socialActions = (action.payload as GraphSocialAction[]).map(
        socialAction => actionMapper(socialAction)
      );
      const stateUpdate = socialActions.reduce(
        (acc: ActionsPerEntityState, socialAction) => {
          if (!socialAction) return acc;
          acc[socialAction.relatedEntity] = {
            ...initialActionPerEntityState,
            helpful: isHelpful(socialAction) ? socialAction.id : null,
            unhelpful: isUnhelpful(socialAction) ? socialAction.id : null
          };
          return acc;
        },
        {}
      );
      return merge({}, state, stateUpdate);
    }
    default:
      return state;
  }
}
