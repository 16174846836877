import { CSS_FONT_URL } from 'common/configuration/constants';
import {
  CSS_FONT_CODE_KEY,
  CSS_FONT_URL_KEY
} from 'common/constants/LocalStorageKeys';
import { addClass } from 'common/tools/dom/classes';
import request from 'common/tools/network/request';
import onUserAction from 'common/tools/ui/defer-on-user-action';
import { runAfterDeferAssets } from 'common/tools/ui/load-assets';

// Get CSS file content stored using localStorage
function getCachedFont() {
  return window.localStorage.getItem(CSS_FONT_CODE_KEY) || null;
}

function getDistantFontUrl() {
  return window.localStorage.getItem(CSS_FONT_URL_KEY) || null;
}

// Inject CSS using a <style> element
function injectStyles(cssText: string) {
  const style = window.document.createElement('style');
  const styleValue = window.document.createTextNode(cssText);
  style.type = 'text/css';
  style.appendChild(styleValue);

  window.document.head.appendChild(style);

  addClass(window.document.body, 'font-loaded');
}

function requestFont(path: string) {
  request(path, { credentials: 'same-origin' }, false)
    .then(response => {
      return response.text();
    })
    .then(function showCss(css) {
      // inject style in body once request is a succes and when no font are stored in localstorage.
      // commented here to avoid the "blink" and the "focus of unstyled content/text (fouc / fout)"
      // on the first load after asset version has changed
      // The Font will be visible with no blink or fouc/fout on the second page view.
      // nb. do tell product, if they see something we'll says it was a "bug" :p

      window.localStorage.setItem(CSS_FONT_CODE_KEY, css);
      window.localStorage.setItem(CSS_FONT_URL_KEY, CSS_FONT_URL);
    });
}

async function init() {
  // case if font is loaded from head (already present in local storage and no change)
  if (window.AC_FONT_LOADED) {
    addClass(window.document.body, 'font-loaded');
    return;
  }

  // if new version is present
  if (getDistantFontUrl() !== CSS_FONT_URL) {
    requestFont(CSS_FONT_URL);
  } else {
    // fallback if no font are not loaded from head but present in window.localStorage.
    injectStyles(getCachedFont() || '');
  }
}

export default function loadFonts() {
  // if we have to make the first request, don't block the load,
  // wait until assets load are trigger.

  runAfterDeferAssets(() => {
    onUserAction(init);
  });
}
