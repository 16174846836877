import React from 'react';
import { createRoot } from 'react-dom/client';

import UnwantedOverlayManager from 'common/services/unwantedOverlay/UnwantedOverlayManager';
import trans from 'common/tools/translations/trans';

import OverlayCandidate from './OverlayCandidate';

export default function globalPushNewsletter() {
  const componentAnchorModale = document.getElementById('newsletter-anchor');
  const componentAnchorRcModule = document.getElementById(
    'newsletter-anchor-rc'
  );
  const componentAnchorLcModule = document.getElementById(
    'newsletter-anchor-lc'
  );

  if (
    !componentAnchorModale &&
    !componentAnchorRcModule &&
    !componentAnchorLcModule
  ) {
    return false;
  }

  const infoMsg = trans('newsletter-modal.info-module');
  const subscribedMsg = trans('newsletter-modal.subscribed-info-module');
  const title = trans('newsletter-modal.title-module');
  const trackingSubject = 'all_newsletter';

  if (componentAnchorModale) {
    const newsletterModalCandidate = new OverlayCandidate(
      componentAnchorModale
    );
    UnwantedOverlayManager.registerCandidate(newsletterModalCandidate);
  }
  if (componentAnchorRcModule) {
    import(
      /* webpackChunkName: "rc-module-newsletter" */ 'website/components/push/Newsletter/module-newsletter'
    ).then(module => {
      const NewsletterRcModule = module.default;

      const root = createRoot(componentAnchorRcModule);
      root.render(
        <NewsletterRcModule
          campaignId={207}
          infoMsg={infoMsg}
          subscribedMsg={subscribedMsg}
          title={title}
          trackingSubject={trackingSubject}
        />
      );
    });
  }
  if (componentAnchorLcModule) {
    import(
      /* webpackChunkName: "lc-module-newsletter" */ 'website/components/push/Newsletter/module-newsletter'
    ).then(module => {
      const NewsletterLcModule = module.default;

      const root = createRoot(componentAnchorLcModule);
      root.render(
        <NewsletterLcModule
          campaignId={207}
          infoMsg={infoMsg}
          subscribedMsg={subscribedMsg}
          title={title}
          trackingSubject={trackingSubject}
        />
      );
    });
  }
}
