import Cookies from 'cookies-js';

import { COOKIE_INFO_COOKIE } from 'common/constants/CookieNames';
import { addClass, removeClass } from 'common/tools/dom/classes';
import * as events from 'common/tools/dom/events';

export default function () {
  if (!Cookies.get(COOKIE_INFO_COOKIE)) {
    const cookieInfo = document.getElementById('js-cookie-info');
    if (!cookieInfo) {
      return;
    }
    removeClass(cookieInfo, 'hidden');
    events.on(
      cookieInfo.getElementsByClassName('info-cookie-close'),
      'click',
      () => {
        Cookies.set(COOKIE_INFO_COOKIE, true, { expires: Infinity });
        addClass(cookieInfo, 'hidden');
      }
    );
  }
}
