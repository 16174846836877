import PropTypes from 'prop-types';
import React from 'react';

import Tooltip from 'common/components/Tooltip';
import noop from 'common/tools/noop';

function closeButtonHoc(WrappedComponent) {
  class CloseButtonTooltip extends React.PureComponent {
    constructor(props) {
      super(props);
      this.state = {
        visible: true
      };
    }

    handleClose() {
      this.setState({ visible: false });
      this.props.onClose();
    }

    handleTargetClick() {
      if (this.props.closeOnTargetClick) {
        this.handleClose();
      }
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          visible={this.state.visible}
          onClose={this.handleClose.bind(this)}
          onShow={this.props.onShow.bind(this)}
          onTargetClick={this.handleTargetClick.bind(this)}
        />
      );
    }
  }

  CloseButtonTooltip.propTypes = {
    onClose: PropTypes.func,
    onShow: PropTypes.func,
    closeOnTargetClick: PropTypes.bool
  };

  CloseButtonTooltip.defaultProps = {
    onClose: noop,
    onShow: noop,
    closeOnTargetClick: false
  };

  return CloseButtonTooltip;
}

export default closeButtonHoc(Tooltip);
