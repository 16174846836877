import { isEmailValid } from 'common/api/SsoApi';
import trans from 'common/tools/translations/trans';

const emailExist = async (email: string) => {
  const { available, valid } = await isEmailValid(email);

  if (!valid) {
    throw new Error(trans('error.mail-server-not-trusted'));
  }

  return !available;
};
export default emailExist;
