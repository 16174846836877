import { ROUTE } from 'common/configuration/constants';
import { hasAdblock } from 'common/tools/adblock';
import { hit } from 'common/tracking/googleanalytics';
import { print as print_jan } from 'common/tracking/jan';

const trackBottomAds = () => {
  // ALLOCINE-10553
  const dataGA = window.dataLayerGA;
  const adblock_user = hasAdblock() ? 'adblock' : 'noblock';
  const content_id = dataGA ? dataGA.dimension33 : null;
  const entity_tag_id = dataGA ? dataGA.dimension79 : null;
  const site_route = dataGA ? dataGA.dimension10 : ROUTE;

  hit({
    eventCategory: 'ob_compliance',
    eventAction: 'bottom_ads_detected',
    eventLabel: content_id,
    dimension1: adblock_user,
    dimension10: site_route,
    dimension33: content_id,
    dimension79: entity_tag_id
  });

  // ALLOCINE-13644
  print_jan('bottom_ads', {
    adblock_user,
    content_id,
    entity_tag_id,
    site_route
  });

  window.bottom_ads_detected = true;
};
export default trackBottomAds;
