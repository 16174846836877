import React from 'react';
import { createRoot } from 'react-dom/client';

import HeaderSearchForm from 'common/components/ui/HeaderSearchForm';

export default function initHeaderSearch() {
  const anchor = document.getElementById('search-engine');
  if (anchor) {
    const input = document.getElementById(
      'header-search-input'
    ) as HTMLInputElement | null;
    const hasFocus = input === document.activeElement;
    const value = input?.value;
    const root = createRoot(anchor);
    root.render(<HeaderSearchForm initialValue={value} withFocus={hasFocus} />);
  }
}
