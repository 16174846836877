import { runAfterDeferAssets } from 'common/tools/ui/load-assets';

let isRequestIdleCallbackScheduled = false;
let loadDone = false;

const RiCthreadSet = new Set();

type DeadlineParam = {
  timeRemaining: () => number;
};

function handleThreadSet(deadlineParam?: DeadlineParam) {
  isRequestIdleCallbackScheduled = false;

  let deadlineEvent = deadlineParam;

  if (typeof deadlineEvent === 'undefined') {
    deadlineEvent = {
      timeRemaining: () => {
        return Number.MAX_VALUE;
      }
    };
  }

  while (deadlineEvent.timeRemaining() > 0 && RiCthreadSet.size > 0) {
    for (const userFunction of RiCthreadSet) {
      if (typeof userFunction === 'function') userFunction();
      RiCthreadSet.delete(userFunction);
    }
  }

  // Check if there are more events still to send.
  if (RiCthreadSet.size > 0) {
    waitForIdle(handleThreadSet);
  }
}

function waitForIdle(callback: (_?: DeadlineParam) => void) {
  if (isRequestIdleCallbackScheduled) {
    return;
  }

  isRequestIdleCallbackScheduled = true;

  if ('requestIdleCallback' in window) {
    // Only schedule the rIC if one has not already been set.
    window.requestIdleCallback(callback);
  } else {
    // Do what you’d do today.
    callback();
  }
}

export function addToRicThread(userFunction: () => void) {
  RiCthreadSet.add(userFunction);

  if (loadDone) {
    waitForIdle(handleThreadSet);
  }
}

export default function init() {
  runAfterDeferAssets(() => {
    waitForIdle(handleThreadSet);
    loadDone = true;
  });
}
