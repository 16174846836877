import Cookies from 'cookies-js';

import { ASSETS_VERSION } from 'common/configuration/constants';
import {
  GEOLOCALISATION_GEOCODE,
  GEOCODE_COUNTRY_CODE
} from 'common/constants/CookieNames';
import eventEmitter, { EventsTypes } from 'common/services/events/eventEmitter';
import request from 'common/tools/network/request';
import { path } from 'common/tools/network/routing';

/**
 * makes a query to the geolocalization API
 *
 * The request itselfs returns nothing but sets geolocation cookies
 * @return {Promise} The request promise
 */
export function initialize() {
  const geocode = Cookies.get(GEOLOCALISATION_GEOCODE);
  const countryCode = Cookies.get(GEOCODE_COUNTRY_CODE);
  if (geocode && countryCode) {
    return eventEmitter.emit(EventsTypes.GEOLOCATION_AVAILABLE);
  }
  return request(path('geolocalize'), {
    credentials: 'same-origin'
  }).then(() => {
    eventEmitter.emit(EventsTypes.GEOLOCATION_AVAILABLE);
  });
}

/**
 * Makes a query to set the geocode cookies by setting directly an id.
 * Id can be a localization one or a theater by setting the type to 'theater'
 *
 * @param {string} id The of the localization (theaterId or geocodeId)
 * @param {string} type The type of localization (theater or efault => localization)
 *
 * The request itselfs returns nothing but sets geolocation cookies
 * @return {Promise} The request promise
 */
export function setGeocodeCookieById(id, type = 'localization') {
  return request(
    path('geolocalizeById', { id: id, type: type, v: ASSETS_VERSION }),
    {
      credentials: 'same-origin'
    }
  ).then(() => {
    eventEmitter.emit(EventsTypes.GEOLOCATION_AVAILABLE);
  });
}

export function searchLocations(term) {
  return request(
    path('internal_api_city_autocomplete', {
      search: term,
      v: ASSETS_VERSION
    }),
    {
      credentials: 'same-origin'
    }
  );
}
