import { getCurrentUser } from 'common/api/GraphApi';
import { resendActivationEmail } from 'common/api/SsoApi';
import { ReduxUserAccount } from 'common/types';

import { passiveLogin as passiveLoginAction } from 'website/actions/AuthenticationActions';
import { openAccountActivationEmailSentModal } from 'website/services/modal';
import store from 'website/store';

import { hasToken } from './tokens';

/**
 * passive login is done on page load / session init
 * if the necessary data are available on the browser
 * @return {Promise} - A promise object representing the login request
 */
export const tryPassiveLogin = (): Promise<ReduxUserAccount> => {
  if (hasToken()) {
    // if the user has a token it means he is / was connected
    // no call is made to the Secure server,
    // but we directly request the user information
    // on the ApiSecure
    return getCurrentUser();
  }
  return Promise.reject(new Error('No valid token'));
};

export const resendAccountActivationEmail = async () => {
  const state = store.getState();
  try {
    await resendActivationEmail();
    openAccountActivationEmailSentModal(false, state.user.account?.email);
  } catch (e) {
    openAccountActivationEmailSentModal(true, state.user.account?.email);
  }
};

export const updateUserProfile = () => {
  // attempt a passive login :
  // - dispatch to the store the successful result
  // - fails silently (empty catch) in case of error (no token, token expired...)
  tryPassiveLogin()
    .then(user => {
      store.dispatch(passiveLoginAction(user));
    })
    .catch(() => {
      store.dispatch(passiveLoginAction(null));
    });
};
