import { RewindModalParams } from 'common/components/RewindModal';
import { ROUTE } from 'common/configuration/constants';
import { hit as hit_jan } from 'common/tracking/jan';

export const trackOpenRewindModal = () => {
  hit_jan('social_action_click', {
    social_action_type: 'rewind',
    social_action_update_type: 'open',
    site_route: ROUTE
  });
};

export const trackCloseRewindModal = (params?: RewindModalParams) => {
  hit_jan('social_action_click', {
    social_action_type: 'rewind',
    social_action_update_type: `close_${params?.screenName}`,
    site_route: ROUTE
  });
};

export const trackShareRewindModal = () => {
  hit_jan('social_action_click', {
    social_action_type: 'rewind',
    social_action_update_type: 'clic_share',
    site_route: ROUTE
  });
};
