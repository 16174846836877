import { AnyAction, createReducer } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';

import { updateObject } from 'common/tools/objects/update';

import * as actionTypes from 'website/constants/ActionTypes';
import { userEntityLeafMapper } from 'website/reducers/data/helper';
import { Entity, Opinion } from 'website/types';

export type ReviewState = {
  entity?: Entity | null;
  loading?: boolean;
  message?: string;
  opinion?: Opinion | null;
  submitted?: boolean;
  successful?: boolean;
};

const initialState: ReviewState = {
  entity: null,
  loading: false,
  message: '',
  opinion: null,
  submitted: false,
  successful: false
};

const opinionRelatesToEntity = (
  opinion?: Opinion | null,
  entity?: Entity | null
) => {
  if (!entity || !opinion) return false;
  return opinion.relatedEntity === entity.id;
};

const resetState = () => initialState;
const assignEntity = (state: ReviewState, action: AnyAction) =>
  updateObject(state, { entity: action.payload });
const loading = (state: ReviewState) =>
  updateObject(state, {
    loading: true
  });
const setOpinion = (state: ReviewState, action: AnyAction) => {
  let opinion = null;
  if (action.payload) {
    opinion = userEntityLeafMapper(action.payload).opinion || null;
  }
  return updateObject(state, {
    opinion,
    loading: false
  });
};
const reviewSuccess = (state: ReviewState, action: AnyAction) => {
  const { opinion } = userEntityLeafMapper(action.payload);
  if (opinionRelatesToEntity(opinion, state.entity)) {
    return updateObject(state, {
      successful: true,
      submitted: true,
      loading: false,
      message: '',
      opinion
    });
  }
  return state;
};
const reviewFailure = (state: ReviewState) =>
  updateObject(state, {
    loading: false,
    successful: false,
    submitted: true,
    message: 'generic'
  });
const deleteReview = (state: ReviewState) =>
  updateObject(state, {
    loading: false,
    opinion: null
  });
const loadingEnd = (state: ReviewState) =>
  updateObject(state, {
    loading: false
  });

export const reviewReducer = createReducer(initialState, builder => {
  builder
    .addCase(actionTypes.CREATE_OPINION_SUCCESS, reviewSuccess)
    .addCase(actionTypes.DELETE_OPINION_SUCCESS, deleteReview)
    .addCase(actionTypes.DELETE_REVIEW_FAILURE, loadingEnd)
    .addCase(actionTypes.DELETE_REVIEW_REQUEST, loading)
    .addCase(actionTypes.DELETE_REVIEW_SUCCESS, deleteReview)
    .addCase(actionTypes.GET_REVIEW_FAILURE, deleteReview)
    .addCase(actionTypes.GET_REVIEW_REQUEST, loading)
    .addCase(actionTypes.GET_REVIEW_SUCCESS, setOpinion)
    .addCase(actionTypes.REVIEW_FORM_SELECT_ENTITY, assignEntity)
    .addCase(actionTypes.REVIEW_FORM_SET_PRISTINE, resetState)
    .addCase(actionTypes.UPDATE_OPINION_SUCCESS, reviewSuccess)
    .addCase(actionTypes.WRITE_REVIEW_FAILURE, reviewFailure)
    .addCase(actionTypes.WRITE_REVIEW_REQUEST, loading)
    .addCase(actionTypes.WRITE_REVIEW_SUCCESS, reviewSuccess);
});
export default combineReducers({
  review: reviewReducer
});
