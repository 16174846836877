export default function once(func) {
  let ran = false;
  let result;
  // eslint-disable-next-line func-names
  return function () {
    if (ran) {
      return result;
    }
    ran = true;
    result = func.apply(this, arguments); // eslint-disable-line prefer-rest-params
    func = undefined; // eslint-disable-line no-param-reassign
    return result;
  };
}
