import Cookies from 'cookies-js';

import {
  GEO_IP_ADSERVER_ID,
  TVTY_KEY
} from 'common/constants/LocalStorageKeys';
import { MQ_MEDIUM } from 'common/constants/MediaQueries';
import eventEmitter, { EventsTypes } from 'common/services/events/eventEmitter';
import loadIas from 'common/third_party/iasDisplay';
import { hasAdblock } from 'common/tools/adblock';
import { getFrameElement } from 'common/tools/dom/frameElement';
import { getState, getStateIndex } from 'common/tools/dom/mq-state';
import once from 'common/tools/functions/once';
import request from 'common/tools/network/request';
import { path } from 'common/tools/network/routing';

import {
  moveMobileAds,
  handleNewspageAds,
  handleLongreadAds
} from './utils-ads';

type prepareAdsPositionContext = {
  news: HTMLElement | null | boolean;
  slideshow: HTMLElement | null | boolean;
  longread: HTMLElement | null | boolean;
};

export const prepareAdsPosition = (
  context: prepareAdsPositionContext = {
    news: false,
    slideshow: false,
    longread: false
  },
  inreadItem?: Element,
  arrayPosition: Element[] = [],
  arrayTarget: (HTMLElement | null)[] = []
) => {
  // Set the ads anchor position for mobile if necessary
  if (hasAdblock()) {
    return;
  }

  /* Move ads on news but never if its a slideshow due to some weird */
  /* error with slideshow injection and ads moved on it on mobile */
  /* (because they also use <p> in the slide description) that will probably trigger a document.write (or something similar) */
  /* cf. ALLOCINE-4614 */
  if (context.news && !context.slideshow && !context.longread) {
    handleNewspageAds(context.news, arrayPosition, inreadItem);
  } else if (context.news && context.longread) {
    handleLongreadAds(context.news, arrayPosition, inreadItem);
  } else {
    moveMobileAds(arrayPosition, arrayTarget);
  }
};

export const getTagIdByGeoIp = async (fallbackId?: string | null) => {
  // here get the account it corresponding at the IP code
  // - Check if the accountId is already set in localStorage, if so return it
  // - Else call the tech route to get a response for adServerGeolocalizeIpAction
  // wich will return the corresponding account_id.
  // Set it in the localStorage and start the adsever with this accountId
  let accountId = localStorage.getItem(GEO_IP_ADSERVER_ID);

  if (accountId) {
    return accountId;
  }

  try {
    accountId = await request(path('adgeoiplocalize'));

    accountId = accountId?.replace(/'/g, '') ?? ''; // JSONResponse of string put some extra quotes on the response and create an issue.
    localStorage.setItem(GEO_IP_ADSERVER_ID, accountId);
  } catch (error) {
    accountId = fallbackId ?? null;
  }

  return accountId;
};

export const getMqState = () => {
  let state;

  if (getFrameElement()) {
    const parentWidth = window.parent.document.body.clientWidth;
    if (parentWidth <= 720) {
      state = 'mobile';
    } else if (parentWidth <= 992) {
      state = 'tablet';
    } else {
      state = 'desktop';
    }
  } else if (getStateIndex() < MQ_MEDIUM) {
    state = 'mobile';
  } else if (getState() === MQ_MEDIUM) {
    state = 'tablet';
  } else {
    state = 'desktop';
  }
  return state;
};

export const filterAdsIdArray = (
  basePositionIds: string[] | undefined,
  positionToRemove: string[]
) => basePositionIds?.filter(id => !positionToRemove.includes(id));

export const getAdTrustContentHeight = (
  domElements: (HTMLElement | null)[] = []
) => {
  if (getMqState() !== 'mobile' || !domElements.length) {
    return false;
  }

  const STICKY_TAKEOVER = 200;
  const ATF_600 = 600;
  const ATF_250 = 250;
  const INSTREAM = 230;

  const digitalAdTrustValue = 30;

  const contentHeight = domElements
    .filter(Boolean)
    .reduce((accumulator, currentItem) => {
      return accumulator + (currentItem?.getBoundingClientRect().height ?? 0);
    }, 0);

  if (!contentHeight) {
    return false;
  }

  // we want to check if ads height are 30% supperior of our main content height to be digitalAdTrusted
  //
  const getPercentage = (value: number) => (value * 100) / contentHeight;

  if (
    getPercentage(STICKY_TAKEOVER + ATF_600 + INSTREAM) < digitalAdTrustValue
  ) {
    return 4;
  }

  if (
    getPercentage(STICKY_TAKEOVER + ATF_600 + INSTREAM) > digitalAdTrustValue &&
    getPercentage(STICKY_TAKEOVER + ATF_600) < digitalAdTrustValue
  ) {
    return 3;
  }

  if (
    getPercentage(STICKY_TAKEOVER + ATF_600) > digitalAdTrustValue &&
    getPercentage(STICKY_TAKEOVER + ATF_250 + INSTREAM) < digitalAdTrustValue
  ) {
    return 2;
  }

  if (
    getPercentage(STICKY_TAKEOVER + ATF_250 + INSTREAM) > digitalAdTrustValue &&
    getPercentage(STICKY_TAKEOVER + ATF_250) < digitalAdTrustValue
  ) {
    return 1;
  }

  return 0;
};

/**
 * function asked by Traffic to handle digitalAdTrust compliency.
 */
export const getAdTrustVideoDurationStepTargeting = (duration: number) => {
  if (duration < 20) {
    return 0;
  }

  if (duration < 30) {
    return 1;
  }

  if (duration < 40) {
    return 2;
  }

  if (duration < 50) {
    return 3;
  }

  if (duration < 60) {
    return 4;
  }

  return 5;
};

// get Mediarithmics keyword
export const getMediarithmicsKeywords = () => {
  let keywords = '';

  if (window.localStorage) {
    const value = window.localStorage.getItem('mics_sgmts');
    if (value) {
      try {
        keywords = JSON.parse(value).webediaMics;
      } catch (error) {
        keywords = '';
      }
    }
  }

  return keywords;
};

// get BlueKai keyword
export const getBlueKaiKeywords = () =>
  Cookies.get('bk_r') || window.bk_results;

export const getApKeywords = (video = false) => {
  let keywords: string | string[] = '';

  if (window.localStorage) {
    const value = window.localStorage.getItem('apr_tdc');
    if (value) {
      try {
        keywords = JSON.parse(value).d.ap_x;
      } catch (error) {
        keywords = '';
      }
    }
  }

  if (!keywords) {
    return false;
  }

  if (!video) {
    return { ap_x: keywords };
  } else {
    return `ap_x=${Array.isArray(keywords) ? keywords.join(',') : keywords}`;
  }
};

// https://jira.webedia-group.net/browse/ALLOCINE-9037
export const handleIAS = async (
  GPT_CONFIG_PAGE: Record<string, any>,
  GPT_LIST_ID: string[],
  callback: (args: any) => void
) => {
  if (!GPT_LIST_ID || !GPT_CONFIG_PAGE || !GPT_CONFIG_PAGE['ias-display-id']) {
    return false;
  }

  loadIas();

  const __iasPET = window.__iasPET || {};

  __iasPET.queue = __iasPET.queue || [];
  __iasPET.pubId = GPT_CONFIG_PAGE['ias-display-id'];

  const __iasPETTimeoutRequestAds = setTimeout(() => {
    callback(GPT_LIST_ID);
  }, 2000);

  const iasDataHandler = () => {
    clearTimeout(__iasPETTimeoutRequestAds);
    __iasPET.setTargetingForGPT();
    callback(GPT_LIST_ID);
  };

  window.googletag.cmd.push(() => {
    const gptSlots = window.googletag.pubads().getSlots();
    const iasPETSlots = [];

    for (let i = 0; i < gptSlots.length; i++) {
      const sizes = gptSlots[i].getSizes().map(size => {
        if (size.getWidth && size.getHeight) {
          return [size.getWidth(), size.getHeight()];
        } else {
          return [1, 1];
        }
      });

      iasPETSlots.push({
        adSlotId: gptSlots[i].getSlotElementId(),
        size: sizes,
        adUnitPath: gptSlots[i].getAdUnitPath()
      });
    }

    __iasPET.queue.push({
      adSlots: iasPETSlots,
      dataHandler: iasDataHandler
    });
  });
};

export const handleTvty = async () =>
  new Promise(resolve => {
    const tvtyStoredObj = JSON.parse(sessionStorage.getItem(TVTY_KEY) ?? '{}');

    if (
      tvtyStoredObj &&
      (new Date().getTime() - new Date(tvtyStoredObj.date).getTime()) /
        1000 /
        60 <
        5
    ) {
      resolve(tvtyStoredObj.data);
      return;
    }

    let timeout = null;
    let fallback = false;
    let data:
      | {
          tvty_movie: string | null;
          tvty_series: string | null;
        }
      | false = false;
    let date = Date.now();

    timeout = setTimeout(() => {
      // eslint-disable-next-line no-console
      console.warn('TVTY timeout: return false');
      sessionStorage.setItem(TVTY_KEY, JSON.stringify({ date, data }));
      fallback = true;
      resolve(data);
    }, 300);

    try {
      request(path('get_tvty')).then(tvtyData => {
        if (timeout) {
          clearTimeout(timeout);
        }

        if (tvtyData.date && (tvtyData.movie || tvtyData.series)) {
          data = {
            tvty_movie: tvtyData.movie ? tvtyData.movie : null,
            tvty_series: tvtyData.series ? tvtyData.series : null
          };
          date = tvtyData.date;
        }

        sessionStorage.setItem(TVTY_KEY, JSON.stringify({ date, data }));

        if (!fallback) {
          resolve(data);
        }
      });
    } catch (error) {
      resolve(false);
    }
  });

export const triggerAdsPositionsReady = once(() => {
  window.adsPositionsReady = true;
  eventEmitter.emit(EventsTypes.ADS_POSITIONS_READY);
});

export { handleNewspageAds as handleNewspageAdsForTest };
export { moveMobileAds as moveMobileAdsForTest };
