import { BRAND_HAS_REWIND_OPE } from 'common/configuration/constants';

const TEST_ACCOUNTS: string[] = [
  'Z20180328113017863991752',
  'Z20240223101542816704199',
  'Z20221003084051723213385',
  'Z20230809154025336371336',
  'Z20230918125002007807532',
  'Z20130926102759707395347',
  'Z20230209103102091639759',
  // test account
  'Z20240702141558338238002',
  'Z20210106173004063432546',
  'Z20241004112310207530691',
  'Z20190612154516953727633',
  'Z20220218134305016550530',
  'Z20241004095618465468104',
  'Z20241004095712983647777',
  'Z20241004095815631953246',
  'Z20240704142032276774486',
  'Z20241004095903867959405',
  'Z20240702115548599637617',
  'Z20241004095348169891559',
  'Z20241107105351342417877',
  'Z20241004100043405531665',
  'Z20240705135229508447419',
  'Z20241004095957305020617',
  'Z20240702141502820569659',
  'Z20241004100127492979712',
  'Z20140808110416453162376',
  'Z20230330145819865381056',
  'Z20190708080931854079406',
  'Z20190910171119360183504',
  'Z20220215143344459562300',
  'Z20241004100213180116052'
];

export const displayRewind = (userId?: string | number) =>
  BRAND_HAS_REWIND_OPE || TEST_ACCOUNTS.includes(`${userId}`);
