import * as events from 'common/tools/dom/events';
import scrollTo from 'common/tools/dom/scrollTo';
import { runAfterDesobfuscation } from 'common/tools/seo/desobfuscation';

function anchorScroll(href) {
  let elem = null;
  let anchor = href || document.location.hash;

  if (!anchor || anchor.trim() === '') {
    return false;
  }

  anchor = anchor.split('#')[1];
  elem = document.getElementById(anchor);

  if (!elem) {
    return false;
  }

  scrollTo(elem, 800, true);
}

function init() {
  const links = document.getElementsByClassName('js-anchor-link');

  events.on(links, 'click', function scrollWindow(e) {
    // only scroll if the target is on the current page
    if (e.target.pathname === document.location.pathname) {
      e.preventDefault();
      anchorScroll(this.href);
    }
  });
}

export default function initAnchors() {
  runAfterDesobfuscation(init);
}
