import { hit } from 'common/tracking/googleanalytics';

export const trackPromoteAffinity = () => {
  hit(
    {
      eventCategory: 'recommandation_program',
      eventLabel: 'clic',
      eventAction: 'pop_up'
    },
    true
  );
};
