import React, { MouseEvent } from 'react';

import { openPopup } from 'common/module/ui/share-btn';

import ShareButton, { Network, Props as ShareButtonProps } from './ShareButton';

type Props = Omit<ShareButtonProps, 'url' | 'network'> & {
  link: string;
  onClick?: (e: MouseEvent) => void;
};

const FacebookShareButton = ({ link, onClick, ...rest }: Props) => {
  const href = 'https://www.facebook.com/sharer/sharer.php?u=' + link;

  return (
    <ShareButton
      {...rest}
      onClick={e => {
        e.preventDefault();
        openPopup(href);
      }}
      network={Network.FACEBOOK}
    >
      Facebook
    </ShareButton>
  );
};

export default FacebookShareButton;
