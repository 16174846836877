// Converts any type of value to a number and
// avoids returning "Not a Number" (NaN).
//
// Useful for cases of reading attributes of a DOM tag
// with the function readAttribute(domElement,
// attributeName, defaultValue) which is supposed to
// return a number, if we have a non-conforming
// value (except null), we will get a string instead. And
// this despite the default value.
const forceNumber = (value: unknown, defaultValue?: number) => {
  const parsed = parseInt(`${value}`);
  return isNaN(parsed) ? defaultValue ?? 0 : parsed;
};

export default forceNumber;
