import { getUserSocialActionsForEntities } from 'common/api/GraphApi';
import request from 'common/tools/network/request';
import { path } from 'common/tools/network/routing';

import {
  GET_AFFINITY_SUGGESTIONS_REQUEST,
  GET_AFFINITY_SUGGESTIONS_SUCCESS,
  GET_AFFINITY_SUGGESTIONS_FAILURE,
  CLOSE_AFFINITY_SUGGESTIONS
} from 'website/constants/ActionTypes';
import { GraphUserEntityLeaf } from 'website/reducers/data/types';
import { SuggestableEntity } from 'website/types';

export type SuccessPayload = {
  entities: { id: string }[];
  entitiesWithOpinions: GraphUserEntityLeaf[];
};

export function getAffinitySuggestions(entity: SuggestableEntity) {
  return {
    types: [
      GET_AFFINITY_SUGGESTIONS_REQUEST,
      GET_AFFINITY_SUGGESTIONS_SUCCESS,
      GET_AFFINITY_SUGGESTIONS_FAILURE
    ],
    callAPI: async (): Promise<SuccessPayload> => {
      let route = 'movie_suggests';

      if (entity.typename === 'Series') {
        route = 'series_suggests';
      }

      // Todo pass response type as a template param when request will be migrated
      const response: { internalId: number }[] = await request(
        path(route, { id: entity.legacyId }),
        {},
        true
      );

      const entities = response.map(sug => ({
        ...sug,
        id: window.btoa(`${entity.typename}:${sug.internalId}`)
      }));

      const ids = entities.map(item => item.id);

      const entitiesWithOpinions: GraphUserEntityLeaf[] =
        await getUserSocialActionsForEntities(ids);

      return { entities, entitiesWithOpinions };
    },
    payload: { entity }
  };
}

export function closeAffinitySuggestions(entity: SuggestableEntity) {
  return {
    type: CLOSE_AFFINITY_SUGGESTIONS,
    payload: { entity }
  };
}
