import React from 'react';
import { createRoot, Root } from 'react-dom/client';

import UserMenu from 'common/components/Header/UserMenu';
import RewindMenu from 'common/components/RewindMenu';
import { IMAGE_EMPTY_AVATAR } from 'common/configuration/constants';
import { displayRewind } from 'common/configuration/rewind';
import { isAuthenticatedViaFacebook } from 'common/services/authentication/tokens';
import loadFacebook from 'common/third_party/facebook';
import {
  addClass,
  hasClass,
  removeClass,
  toggleClass
} from 'common/tools/dom/classes';
import * as events from 'common/tools/dom/events';
import { getStateIndex } from 'common/tools/dom/mq-state';
import { onYScroll } from 'common/tools/dom/on-scroll';
import { requestAnimationFrame } from 'common/tools/functions/request-animation-frame';
import { getCroppedAvatar } from 'common/tools/images/avatars';
import {
  getImageUrl,
  removeScheme,
  addUrlParam
} from 'common/tools/network/url';
import { ReduxUser } from 'common/types';

// private vars
const targetMainNav = document.getElementById('header-main-mobile-btn-menu');
const targetUserspace = document.getElementById(
  'header-main-mobile-btn-userspace'
);
const targetSearch = document.getElementById('header-main-mobile-btn-search');

const elemMobileLayerSearch = document.getElementById(
  'header-main-search-mobile-layer'
);

const elemMainNav = document.getElementById('header-main-nav');
const elemUserspaceNavSubContent = document.getElementsByClassName(
  'header-nav-userspace-subcontent'
)[0];

const OPEN_HEADER_MAIN_NAV_CLASS = 'open-header-main-nav';
const OPEN_HEADER_USERSPACE_NAV_CLASS = 'open-header-userspace-nav';
const OPEN_HEADER_SEARCH_CLASS = 'open-header-main-search';

const body = document.body;

const MINIFY_HEADER_CLASS = 'header-min';
const MAIN_HEADER_ID = 'header-main';

let headerMinified = false;
let windowTop =
  window.scrollY || window.pageYOffset || document.documentElement.scrollTop;

function handleHeaderMobileMainNav() {
  if (targetMainNav) {
    events.on(targetMainNav, 'click', () => {
      requestAnimationFrame(() => {
        removeClass(body, OPEN_HEADER_USERSPACE_NAV_CLASS);
        removeClass(body, OPEN_HEADER_SEARCH_CLASS);

        toggleClass(body, OPEN_HEADER_MAIN_NAV_CLASS);
      });
    });
  }

  if (elemMainNav) {
    events.on(elemMainNav, 'click', () => {
      requestAnimationFrame(() => {
        removeClass(body, OPEN_HEADER_MAIN_NAV_CLASS);
      });
    });

    events.on(
      elemMainNav.getElementsByClassName('header-nav-holder')[0],
      'click',
      (e: Event) => {
        // prevent any click handler on the nav items to trigger the hide action of the sub-nav component
        e.stopPropagation();
      }
    );
  }
}

function handleHeaderMobileUserspaceNav() {
  if (targetUserspace) {
    events.on(targetUserspace, 'click', () => {
      requestAnimationFrame(() => {
        removeClass(body, OPEN_HEADER_MAIN_NAV_CLASS);
        removeClass(body, OPEN_HEADER_SEARCH_CLASS);

        toggleClass(body, OPEN_HEADER_USERSPACE_NAV_CLASS);
      });
    });
  }
}

const handleHeaderMobileSearch = (mqStateMobile: number) => {
  if (targetSearch) {
    events.on(targetSearch, 'click', () => {
      if (getStateIndex() > mqStateMobile) {
        return false;
      }

      requestAnimationFrame(() => {
        removeClass(body, OPEN_HEADER_MAIN_NAV_CLASS);
        removeClass(body, OPEN_HEADER_USERSPACE_NAV_CLASS);

        toggleClass(body, OPEN_HEADER_SEARCH_CLASS);

        const elemInputSearch = document.getElementById('header-search-input');

        if (elemInputSearch && hasClass(body, OPEN_HEADER_SEARCH_CLASS)) {
          elemInputSearch.focus();
        }

        if (elemInputSearch && !hasClass(body, OPEN_HEADER_SEARCH_CLASS)) {
          elemInputSearch.blur();
        }
      });
    });
  }

  if (elemMobileLayerSearch) {
    events.on(elemMobileLayerSearch, 'click', () => {
      if (getStateIndex() > mqStateMobile) {
        return false;
      }

      requestAnimationFrame(() => {
        removeClass(body, OPEN_HEADER_SEARCH_CLASS);
      });
    });
  }
};

function handleHeaderStickyOnScroll(mqStateMobile: number, yScroll: number) {
  if (getStateIndex() < mqStateMobile) {
    return false;
  }

  // Edge behavior.
  // Don't start this minified header under 100 px of the top.
  // Don't remove minify header on the bottom of the scroll.
  // Avoid unique FX on tablet bouncing scroll.
  const viewportBottom =
    window.innerHeight || document.documentElement.clientHeight;

  if (yScroll < 100) {
    removeClass(body, MINIFY_HEADER_CLASS);
    headerMinified = false;
    windowTop = yScroll;
    return false;
  }

  if (
    yScroll >= document.body.scrollHeight - viewportBottom - 150 &&
    headerMinified
  ) {
    windowTop = yScroll;
    return false;
  }

  // Classic behavior
  if (windowTop < yScroll && !headerMinified) {
    addClass(body, MINIFY_HEADER_CLASS);
    headerMinified = true;
  }

  if (windowTop > yScroll && headerMinified) {
    removeClass(body, MINIFY_HEADER_CLASS);
    headerMinified = false;
  }

  windowTop = yScroll;
}

const onUserAvatarError = (e: Event) => {
  (e.target as HTMLImageElement).src = getImageUrl({
    src: IMAGE_EMPTY_AVATAR
  });
};

let rewindMenuRoot: Root;

const initRewindMenu = (user?: ReduxUser) => {
  if (displayRewind(user?.account?.id)) {
    if (!rewindMenuRoot) {
      const container = document.createElement('div');
      container.className = 'rewind-mobile-menu';

      if (elemMainNav) {
        elemMainNav
          .getElementsByClassName('header-nav-holder')[0]
          .appendChild(container);

        rewindMenuRoot = createRoot(container);
      }
      rewindMenuRoot.render(<RewindMenu logged={user?.loggedIn} />);
    } else if (user?.loggedIn) {
      rewindMenuRoot.render(<RewindMenu logged />);
    }
  }
};

export const updateUserMenu = async (user: ReduxUser) => {
  const headerMainDiv = document.getElementById('header-account');
  if (user.loggedIn && user.account) {
    const { label, picture } = user.account;
    const myAccountAvatar = document.getElementsByClassName(
      'js-myaccount-avatar'
    ) as HTMLCollectionOf<HTMLImageElement>;
    if (myAccountAvatar.length) {
      let avatarUrl = getCroppedAvatar(picture);
      if (isAuthenticatedViaFacebook()) {
        const authResponse = await loadFacebook();
        if (authResponse?.accessToken) {
          avatarUrl = addUrlParam(
            avatarUrl,
            'access_token',
            authResponse?.accessToken
          );
        }
      }
      avatarUrl = removeScheme(avatarUrl);
      for (const item of myAccountAvatar) {
        item.src = avatarUrl;
        item.addEventListener('error', onUserAvatarError);
        item.alt = label ?? '';
      }
    }
    if (headerMainDiv) {
      addClass(headerMainDiv, 'is-connected');
    }
    addClass(body, 'logged-in');
    removeClass(body, 'logged-out');

    const root = createRoot(elemUserspaceNavSubContent);
    root.render(<UserMenu user={user} />);
  } else {
    addClass(body, 'logged-out');
    removeClass(body, 'logged-in');
    if (headerMainDiv) {
      removeClass(headerMainDiv, 'is-connected');
    }
  }
  initRewindMenu(user);
};

export default function init(mqStateMobile: number) {
  if (!document.getElementById(MAIN_HEADER_ID)) {
    return false;
  }

  handleHeaderMobileMainNav();
  handleHeaderMobileUserspaceNav();
  handleHeaderMobileSearch(mqStateMobile);
  onYScroll((yScroll: number) => {
    handleHeaderStickyOnScroll(mqStateMobile, yScroll);
  });
  initRewindMenu();
}
