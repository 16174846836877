import { ROUTE } from 'common/configuration/constants';
import {
  TrackingContextObject,
  TrackingEventNames,
  UserTheaterTracking
} from 'common/constants/trackingEventsNames';
import { trackPermanentSpeednoterButtonClick } from 'common/module/tracking/permanentSpeednoterButtonTracking';
import eventEmitter, { EventsTypes } from 'common/services/events/eventEmitter';
import {
  trackUnfollowCollection,
  trackFollowCollection,
  trackAddCollectionEntities,
  trackRemoveCollectionEntities
} from 'common/tracking/collectionTracking';
import { trackFollowUser } from 'common/tracking/followUserTracking';
import { ga as gaFromCommon } from 'common/tracking/googleanalytics';

import { trackOpenAccessibilityModal } from 'website/tracking/listener/accessLibreTracking';
import { trackAffinityTooltip } from 'website/tracking/listener/affinityTooltipTracking';
import { trackAuthenticatedLink } from 'website/tracking/listener/authenticatedLinkTracking';
import {
  trackAutoSuggestSearchEmergence,
  trackOnSelectAutoSuggestSearch,
  trackOnSelectAutoSuggestSearchEmergence
} from 'website/tracking/listener/autocompleteTracking';
import trackBottomAds from 'website/tracking/listener/bottomAdsTracking';
import { gtmTrackOpinion } from 'website/tracking/listener/gtm.opinionTracking';
import { gtmTrackWantToSee } from 'website/tracking/listener/gtm.wantToSeeTracking';
import {
  trackOpenModal,
  trackCloseModal
} from 'website/tracking/listener/modalTracking';
import { trackOpinion } from 'website/tracking/listener/opinionTracking';
import { trackPromoteAffinity } from 'website/tracking/listener/promoteAffinityTracking';
import { trackPromoteSignup } from 'website/tracking/listener/promoteSignupTracking';
import trackReservationClick, {
  trackReservationImpression
} from 'website/tracking/listener/reservationTracking';
import trackRollerDateItemClick from 'website/tracking/listener/rollerDateItemTracking';
import { trackSeen } from 'website/tracking/listener/seenTracking';
import trackShowtimeFilterButtonClick from 'website/tracking/listener/showtimeFilterButtonTracking';
import { trackUserTheater } from 'website/tracking/listener/theaterTracking';
import {
  trackTooltipClick,
  trackTooltipHover
} from 'website/tracking/listener/tooltipTracking';
import { trackWantToSee } from 'website/tracking/listener/wantToSeeTracking';

import { trackNewsletterPush } from './newsletterPush';

export type EventAction = 'add' | 'remove';

/**
 * Tracking initialization
 * @returns {void}
 */
const initializeTracking = (): void => {
  gaFromCommon('set', 'dimension10', ROUTE || null);

  const {
    ADD_OPINION_EVENT,
    ADD_SEEN_IT_EVENT,
    ADD_USER_THEATER_EVENT,
    ADD_WANT_TO_SEE_EVENT,
    AFFINITY_TOOLTIP_EVENT,
    AUTO_SUGGEST_SEARCH_EMERGENCE,
    DELETE_OPINION_EVENT,
    DELETE_WANT_TO_SEE_EVENT,
    FOLLOW_COLLECTION_EVENT,
    FOLLOW_USER_EVENT,
    NEWSLETTER_PUSH,
    ON_SELECT_AUTO_SUGGEST_SEARCH,
    ON_SELECT_AUTO_SUGGEST_SEARCH_EMERGENCE,
    OPEN_ACCESSIBILITY_MODAL,
    OPEN_PERMANENT_SPEEDNOTER_BUTTON_CLICK,
    PROMOTE_AFFINITY_CLICK,
    PROMOTE_SIGNUP_CLICK,
    REMOVE_SEEN_IT_EVENT,
    REMOVE_USER_THEATER_EVENT,
    THEATER_RESERVATION_LINK_CLICK_EVENT,
    TRACKING_LOGIN_REQUEST,
    TRACKING_ROLLER_DATE_ITEM_HANDLE_CLICK,
    TRACKING_SHOWTIME_FILTER_BUTTON_HANDLE_CLICK,
    UNFOLLOW_COLLECTION_EVENT,
    UNFOLLOW_USER_EVENT,
    UPDATE_OPINION_EVENT
  } = TrackingEventNames;

  eventEmitter.on(FOLLOW_COLLECTION_EVENT, trackFollowCollection);
  eventEmitter.on(UNFOLLOW_COLLECTION_EVENT, trackUnfollowCollection);

  eventEmitter.on(FOLLOW_USER_EVENT, trackFollowUser.bind(null, 'add'));
  eventEmitter.on(UNFOLLOW_USER_EVENT, trackFollowUser.bind(null, 'remove'));

  eventEmitter.on(ADD_OPINION_EVENT, trackOpinion.bind(null, 'add'));
  eventEmitter.on(ADD_OPINION_EVENT, gtmTrackOpinion.bind(null, 'add'));
  eventEmitter.on(DELETE_OPINION_EVENT, trackOpinion.bind(null, 'remove'));
  eventEmitter.on(DELETE_OPINION_EVENT, gtmTrackOpinion.bind(null, 'remove'));
  eventEmitter.on(UPDATE_OPINION_EVENT, trackOpinion.bind(null, 'edit'));
  eventEmitter.on(UPDATE_OPINION_EVENT, gtmTrackOpinion.bind(null, 'edit'));

  eventEmitter.on(
    ADD_SEEN_IT_EVENT,
    (trackingContextObject: TrackingContextObject) => {
      trackSeen('add', trackingContextObject);
    }
  );
  eventEmitter.on(
    REMOVE_SEEN_IT_EVENT,
    (trackingContextObject: TrackingContextObject) =>
      trackSeen('remove', trackingContextObject)
  );

  eventEmitter.on(ADD_WANT_TO_SEE_EVENT, (...args) =>
    // @ts-expect-error type args
    trackWantToSee('add', ...args)
  );
  eventEmitter.on(ADD_WANT_TO_SEE_EVENT, gtmTrackWantToSee.bind(null, 'add'));
  eventEmitter.on(
    DELETE_WANT_TO_SEE_EVENT,
    trackWantToSee.bind(null, 'remove')
  );
  eventEmitter.on(
    DELETE_WANT_TO_SEE_EVENT,
    gtmTrackWantToSee.bind(null, 'remove')
  );

  eventEmitter.on(
    ADD_USER_THEATER_EVENT,
    (trackingContext: UserTheaterTracking) =>
      trackUserTheater('add', trackingContext)
  );
  eventEmitter.on(
    REMOVE_USER_THEATER_EVENT,
    (trackingContext: UserTheaterTracking) =>
      trackUserTheater('remove', trackingContext)
  );

  eventEmitter.on(EventsTypes.OPEN_MODAL, trackOpenModal);
  eventEmitter.on(EventsTypes.CLOSE_MODAL, trackCloseModal);

  eventEmitter.on(THEATER_RESERVATION_LINK_CLICK_EVENT, trackReservationClick);

  eventEmitter.on(
    TRACKING_ROLLER_DATE_ITEM_HANDLE_CLICK,
    trackRollerDateItemClick
  );
  eventEmitter.on(
    TRACKING_SHOWTIME_FILTER_BUTTON_HANDLE_CLICK,
    trackShowtimeFilterButtonClick
  );

  eventEmitter.on(NEWSLETTER_PUSH, trackNewsletterPush);

  eventEmitter.on(AFFINITY_TOOLTIP_EVENT, trackAffinityTooltip);

  eventEmitter.on(
    OPEN_PERMANENT_SPEEDNOTER_BUTTON_CLICK,
    trackPermanentSpeednoterButtonClick
  );

  eventEmitter.on(TRACKING_LOGIN_REQUEST, trackAuthenticatedLink);

  eventEmitter.on(PROMOTE_AFFINITY_CLICK, trackPromoteAffinity);
  eventEmitter.on(PROMOTE_SIGNUP_CLICK, trackPromoteSignup);

  eventEmitter.on(OPEN_ACCESSIBILITY_MODAL, trackOpenAccessibilityModal);

  eventEmitter.on(
    ON_SELECT_AUTO_SUGGEST_SEARCH,
    trackOnSelectAutoSuggestSearch
  );
  eventEmitter.on(
    ON_SELECT_AUTO_SUGGEST_SEARCH_EMERGENCE,
    trackOnSelectAutoSuggestSearchEmergence
  );
  eventEmitter.on(
    AUTO_SUGGEST_SEARCH_EMERGENCE,
    trackAutoSuggestSearchEmergence
  );
  eventEmitter.on(
    TrackingEventNames.ON_CLICK_THEATER_TOOLTIP,
    trackTooltipClick
  );
  eventEmitter.on(
    TrackingEventNames.ON_HOVER_THEATER_TOOLTIP,
    trackTooltipHover
  );
  eventEmitter.on(TrackingEventNames.BOTTOM_ADS_DETECTED, trackBottomAds);
  eventEmitter.on(
    TrackingEventNames.ADD_ENTITIES_TO_COLLECTION_EVENT,
    trackAddCollectionEntities
  );
  eventEmitter.on(
    TrackingEventNames.REMOVE_ENTITIES_FROM_COLLECTION_EVENT,
    trackRemoveCollectionEntities
  );
  eventEmitter.on(
    TrackingEventNames.OPEN_SHOWTIME_MODAL,
    trackReservationImpression
  );
};

export default initializeTracking;
