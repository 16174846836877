/* used for jwp and adManger cf https://jira.webedia-group.net/browse/ALLOCINE-6929 */
import loadScript from 'load-script';

const IAS_URL = '//cdn.adsafeprotected.com/iasPET.1.js';

let loaded = false;

/**
 * This module / function allows the dynamic loading of google client
 * javascript library
 */
export default (callback?: () => void, options = {}) => {
  if (loaded && typeof callback === 'function') {
    callback();
    return false;
  }

  if (loaded) {
    return false;
  }

  loadScript(IAS_URL, options, () => {
    loaded = true;
    if (typeof callback === 'function') {
      callback();
    }
  });
};
