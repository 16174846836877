const unescape = (str: string | null | undefined) => {
  const element = document.createElement('div');
  let unescapedString = str;
  if (unescapedString && typeof unescapedString === 'string') {
    // strip script/html tags
    unescapedString = unescapedString.replace(
      /<script[^>]*>([\S\s]*?)<\/script>/gim,
      ''
    );
    unescapedString = unescapedString.replace(
      /<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gim,
      ''
    );
    element.innerHTML = unescapedString;
    unescapedString = element.textContent ? element.textContent : '';
    element.textContent = '';
  }

  return unescapedString;
};

export default unescape;
