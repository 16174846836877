import slugify from 'common/tools/string/slugify';

export const SHOWTIME_TYPE_PREMIERE = 'premiere';
export const SHOWTIME_TYPE_PRESALE = 'presale';
export const SHOWTIME_TYPE_STANDARD = 'booking';
export const SHOWTIME_TYPE_NO_TICKETING = 'no_ticketing';

export const standardizeTrackingType = (typelabel?: string) => {
  const label = typelabel?.toLowerCase();

  if (label && ['seriesepisode', 'episode'].includes(label)) {
    return 'series_episode';
  }

  if (label && ['seriesseason', 'season'].includes(label)) {
    return 'series_season';
  }

  return label;
};

export const GA4Entity = (id?: number | string | null, name?: string | null) =>
  `${id}|${slugify(name)}`;
