import classNames from 'classnames';
import React, { AnchorHTMLAttributes } from 'react';

type Props = Pick<
  AnchorHTMLAttributes<HTMLAnchorElement>,
  'onClick' | 'href'
> & {
  className?: string;
  icon: string;
  text: string;
};

const UserMenuBorderedItem = ({
  className,
  icon,
  text,
  onClick,
  href
}: Props) => {
  return (
    <li
      className={classNames(
        className,
        'header-nav-userspace-item',
        'userspace-item-bordered'
      )}
    >
      <a
        className={classNames('header-nav-userspace-link', 'icon', icon)}
        title={text}
        href={href}
        onClick={onClick}
      >
        {text}
      </a>
    </li>
  );
};

export default UserMenuBorderedItem;
