import { Country, Maybe, Movie, Video } from 'types/graphql-api.generated';

import eventEmitter, { EventsTypes } from 'common/services/events/eventEmitter';
import { addClass } from 'common/tools/dom/classes';
import readAttribute from 'common/tools/dom/readAttribute';
import { isHTMLElement, isString } from 'common/types';

export type PlayerCustomData = PlayerVideo & PlayerParams;

export type PlayerCustomDataPartner = {
  customName?: string | null;
  endScreen?: boolean;
  hideTopRightMenu?: boolean | null;
  logoSource?: string | null;
  logoTarget?: string | null;
  name: string;
  postRoll?: boolean;
  postrollOverlayDisplay?: boolean;
  postrollPreviewOverlayMessage?: string | null;
  preRoll?: boolean;
  soundOnOver?: boolean;
  uidPartner?: number | null;
};

type PlayerGenre = {
  id: string;
  name: string;
};

type PlayerParams = {
  campaign?: string | null;
  context?: string;
  disableAutoPause?: boolean;
  disablePostroll?: boolean;
  disablePreroll?: boolean;
  editorialContent?: string;
  endScreen?: boolean;
  expandPlayer?: boolean;
  file_name?: PlayerSource;
  followScroll?: boolean;
  isHomeProduced?: boolean;
  launch: 'play' | 'auto-play' | 'scroll-play';
  partner?: PlayerCustomDataPartner;
  playerDmId?: string | null;
  player_position?: 'playertop' | 'playercontent' | 'playerfooter';
  soundOnOver?: 'undefined' | boolean;
  videos?: PlayerVideo[];
  smartMute?: boolean | null;
};

type PlayerRelease = {
  date: string;
  timezone_type: number;
  timezone: string;
};

export type PlayerSource = {
  high: string;
  low?: string;
  medium: string;
  standard?: string;
};

type PlayerVideoMetas = {
  genre_main_movie?: PlayerGenre[] | null;
  genre_main_series?: PlayerGenre[] | null;
  id_main_movie?: number | null;
  id_main_person?: number | null;
  id_main_season?: number | null;
  id_main_series?: number | null;
  id_main_show?: number | null;
  id_meta_category?: number | null;
  image_main_movie?: string | null;
  image_main_person?: string | null;
  image_main_season?: string | null;
  image_main_series?: string | null;
  image_main_show?: string | null;
  localized_file_type: string | null;
  main_movie_type: number | null;
  main_season_number?: number | null;
  main_season_rating?: number | null;
  main_season_user_note_count?: number | null;
  main_series_rating?: number | null;
  name_main_movie?: string | null;
  name_main_person?: string | null;
  name_main_season?: string | null;
  name_main_series?: string | null;
  name_main_show?: string | null;
  name_meta_category?: string | null;
  nb_days_release_main_movie: number | null;
  release_date_main_movie?: PlayerRelease;
  release_status_movie?: string | null;
  release_status_series?: string | null;
  trans_file_type: string | null;
  use_portal?: string | null;
};

export type PlayerVideo = Pick<Movie, 'genres' | 'id'> &
  Pick<Video, 'description' | 'duration' | 'title'> & {
    added_at?: PlayerRelease;
    countries?: Maybe<Array<Maybe<Pick<Country, 'id'>>>>;
    embedUrl?: string;
    entityStatus?: string;
    file_type?: string;
    genresIds?: number[];
    idDailymotion?: string;
    id_type?: number;
    image?: string;
    is_emergence_inhibited?: boolean;
    mediaUrl?: string;
    metas?: PlayerVideoMetas;
    player_thumbnail?: string;
    relatedEntityDistributor?: string | null;
    relatedEntityDistributorId?: number | null;
    relatedEntityId?: number;
    relatedEntityTitle?: string;
    relatedEntityTrackingIdentifier?: string;
    relatedEntityType?: string;
    relatedEntityUrl?: string;
    sources?: PlayerSource;
    trackingIdentifier?: string;
    view_count?: number;
  };

const playerInsertedClass = 'player-inserted';

const injectPlayer = async (
  playerContainer: HTMLElement,
  data: PlayerCustomData
) => {
  addClass(playerContainer, playerInsertedClass);
  const module = await import('./player.dm.lazy');
  await module.createPlayer(playerContainer, data);
};

const initPlayersFromDom = () => {
  const playerHolderCollection = document.getElementsByClassName('js-player');

  for (const playerContainer of playerHolderCollection) {
    if (isHTMLElement(playerContainer)) {
      const playerData = readAttribute<PlayerCustomData, PlayerCustomData>(
        playerContainer,
        'data-model',
        { id: '', launch: 'play' }
      );

      if (!isString(playerData)) {
        playerData.playerDmId =
          playerContainer.getAttribute('data-player-dm-id');

        if (playerData && playerData.videos && playerData.videos.length) {
          playerData.videos[0].player_thumbnail = readAttribute(
            playerContainer,
            'data-thumb'
          );
        }

        injectPlayer(playerContainer, playerData);
      }
    }
  }
};

const init = () => {
  if (window.adsReady) {
    initPlayersFromDom();
  } else {
    eventEmitter.on(EventsTypes.ADS_READY, initPlayersFromDom);
  }
};

export default init;
