/* src : http://stackoverflow.com/questions/7838680/detecting-that-the-browser-has-no-mouse-and-is-touch-only */
import { addClass } from 'common/tools/dom/classes';

const touchBrowser =
  'ontouchstart' in window || // html5 browsers
  navigator.maxTouchPoints > 0;

const hasTouch = () => {
  return touchBrowser;
};

// set no-touch class on desktop browser
export const setTouchBrowser = () => {
  const HTMLTAG = document.getElementsByTagName('html')[0];
  if (!hasTouch()) {
    addClass(HTMLTAG, 'no-touch');
  }
};

export default hasTouch;
