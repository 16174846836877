import loadScript from 'load-script';

import { isProd } from 'common/tools/env';

export default function loadGoogleAnalytics() {
  return new Promise((resolve, reject) => {
    // from https://developers.google.com/analytics/devguides/collection/analyticsjs/advanced?hl=en
    window.GoogleAnalyticsObject = 'ga';
    if (isProd()) {
      loadScript('//www.google-analytics.com/analytics.js', error => {
        if (!error) resolve();
        else reject();
      });
    } else {
      // eslint-disable-next-line no-console
      console.warn('Google analytics is disabled in development mode');
      // Let's log GA calls to console in non prod env
      // eslint-disable-next-line no-console
      window.ga = (...args) => console.log('ga', ...args);
      resolve();
    }
  });
}
