import { MQ_LARGE } from 'common/constants/MediaQueries';
import { TrackingEventNames } from 'common/constants/trackingEventsNames';
import eventEmitter, { EventsTypes } from 'common/services/events/eventEmitter';
import { getStateIndex } from 'common/tools/dom/mq-state';
import { requestAnimationFrame } from 'common/tools/functions/request-animation-frame';

const moveAdsOnStart = (
  elem?: Element,
  target?: Element,
  insertAfter = false
) => {
  if (!elem || !target) {
    return;
  }

  let referenceNode: Element | null = target;

  if (insertAfter) {
    referenceNode = target.nextElementSibling;
  }

  requestAnimationFrame(() => {
    target.parentElement?.insertBefore(elem, referenceNode);
  });
};

const moveAdsOnMqStateChange = (
  elem: Element,
  target: Element | null,
  insertAfter = false
) => {
  if (!elem || !target) {
    return;
  }

  let movedInLeftCol = false;

  const targetBack = elem.nextElementSibling || elem.parentElement?.lastChild;
  let referenceNode: Element | null = target;

  if (insertAfter) {
    referenceNode = target.nextElementSibling;
  }

  if (getStateIndex() < MQ_LARGE) {
    requestAnimationFrame(() => {
      target.parentElement?.insertBefore(elem, referenceNode);
      movedInLeftCol = true;
    });
  }

  eventEmitter.on(EventsTypes.MQ_STATE, state => {
    if (state < MQ_LARGE && !movedInLeftCol) {
      requestAnimationFrame(() => {
        target.parentElement?.insertBefore(elem, referenceNode);
        movedInLeftCol = true;
      });
    } else if (state >= MQ_LARGE && targetBack) {
      requestAnimationFrame(() => {
        targetBack.parentElement?.insertBefore(elem, targetBack);
        movedInLeftCol = false;
      });
    }
  });
};

export const moveMobileAds = (
  positionAds: Element[],
  targetAds: (Element | null)[],
  insertAfter = false
) => {
  positionAds.forEach((item, index) => {
    if (item && targetAds[index]) {
      moveAdsOnMqStateChange(item, targetAds[index], insertAfter);
    }
  });
};

const getParagraphs = (news: HTMLElement | null | boolean) => {
  // news test
  const paragraph =
    news instanceof HTMLElement ? news.getElementsByTagName('p') : [];
  const cleanedParagraph: HTMLParagraphElement[] = [];

  for (const p of paragraph) {
    if (p.innerText.trim() !== '') {
      cleanedParagraph.push(p);
    }
  }
  // remove the last item to avoid getting an ads after the content (Outbrain compliancy)
  cleanedParagraph.pop();

  return cleanedParagraph;
};

const handleInreadAdded = (
  paragraphArray: HTMLParagraphElement[],
  modulo: number
) => {
  const newParagraphArray = paragraphArray;
  const newParagraphArrayLength = newParagraphArray.length;
  let idNumber = 1;
  newParagraphArray.forEach((item, index) => {
    // add anchors to serve ads every 6 paragraphs
    //Do not add a position after last paragraph that's why we add the condition with iDnumber
    if (index % modulo === 0 && idNumber != newParagraphArrayLength) {
      const element = document.createElement('div');
      element.id = `dfp-added-ad-${idNumber}`;
      element.className = 'js-added-ad';
      item.parentElement?.insertBefore(element, item.nextElementSibling);
      idNumber++;
    }
  });
};

const handleInread = (
  inreadItem: Element | undefined,
  newsContext: HTMLElement | null | boolean,
  cleanedParagraph:
    | Array<HTMLParagraphElement>
    | NodeListOf<HTMLParagraphElement>,
  longread = false
) => {
  if (inreadItem && newsContext) {
    const triggerInread = longread ? 2 : 3;

    if (cleanedParagraph.length >= triggerInread) {
      moveAdsOnStart(inreadItem, cleanedParagraph[triggerInread - 1], true);
    } else {
      eventEmitter.emit(TrackingEventNames.BOTTOM_ADS_DETECTED);
    }
  }
};

export const handleNewspageAds = (
  newsContext: HTMLElement | null | boolean,
  arrayPosition: Element[],
  inreadItem?: Element
) => {
  const cleanedParagraph = getParagraphs(newsContext);
  const paragraphLength = cleanedParagraph.length;

  if (arrayPosition.length && paragraphLength && newsContext) {
    const targetAds = [
      cleanedParagraph[0],
      cleanedParagraph[5],
      cleanedParagraph[8]
    ];

    moveMobileAds(arrayPosition, targetAds, true);
    if (paragraphLength >= 10) {
      // we get the other P if any and remove the last P for outbrain compliency
      // start inserting ads after the 11th paragraph
      const othersP = cleanedParagraph.slice(11);
      if (getStateIndex() < MQ_LARGE && othersP.length) {
        handleInreadAdded(othersP, 6);
      }
    }
  }

  handleInread(inreadItem, newsContext, cleanedParagraph);
};

export const handleLongreadAds = (
  newsContext: HTMLElement | null | boolean,
  arrayPosition: Element[],
  inreadItem?: Element
) => {
  const cleanedParagraph =
    document.querySelectorAll<HTMLParagraphElement>('.longread-block');
  const paragraphLength = cleanedParagraph.length;

  if (arrayPosition.length && paragraphLength && newsContext) {
    const targetAds = [
      cleanedParagraph[0],
      cleanedParagraph[2],
      cleanedParagraph[3]
    ];

    moveMobileAds(arrayPosition, targetAds, true);
    if (paragraphLength > 4) {
      // we get the other P if any and remove the last P for outbrain compliency
      // start inserting ads after the 11th paragraph
      const othersP: Array<HTMLParagraphElement> = Array.prototype.slice.call(
        cleanedParagraph,
        4
      );
      if (othersP.length) {
        handleInreadAdded(othersP, 1);
      }
    }
  }

  handleInread(inreadItem, newsContext, cleanedParagraph, true);
};
