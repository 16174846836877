import { createReducer, UnknownAction } from '@reduxjs/toolkit';

import { getTheaterId } from 'common/api/SecureApi.helper';
import { loginSuccessMapper } from 'common/reducers/user';
import { updateObject } from 'common/tools/objects/update';
import { ReduxUser, ReduxUserAccount } from 'common/types';

import * as actionTypes from 'website/constants/ActionTypes';
import {
  REQUEST_LOADING,
  REQUEST_SUCCESSFULL,
  REQUEST_ERROR
} from 'website/constants/RequestsStatuses';

import relationshipsReducer, { RelationshipsState } from './relationships';

export type TargetType<T> = T extends 'User'
  ? Partial<ReduxUserAccount> & {
      accountCreationDate?: string;
      avatarProvider?: string;
      birthDate?: string;
      firstName?: string;
      gender?: string;
      gtuValidated?: boolean;
      lastName?: string;
      url?: string;
      type?: T;
    }
  : T extends 'Theater'
    ? {
        id?: string | number;
        label?: string;
        picture?: string;
        type?: T;
        url?: string;
      }
    : T extends 'UserTheater'
      ? {
          date?: string;
          id?: string | number;
          order?: number;
          sourceKey?: string;
          targetKey?: string;
          type?: T;
          url?: string;
        }
      : { type?: T };

export type Theater<T extends string | number | symbol = string> = Record<
  T,
  TargetType<T> & { data?: TargetType<'Theater' | 'User'> }
>;

export type UserState<T extends string | number | symbol = string> = Partial<
  Omit<ReduxUser, 'account'>
> & {
  account?: Partial<ReduxUserAccount> | null;
  favTheatersStatus?: number | null;
  loading?: boolean;
  loginError?: boolean;
  relationships?: RelationshipsState;
  theaters?: Theater<T> | null;
  theatersCount?: number;
};

export type UserActionPayload = Partial<ReduxUserAccount> &
  TargetType<'UserTheater'> & {
    actions?: Record<string, TargetType<'UserTheater'>>;
    isEmailValid?: boolean;
    links?: { targetDetails?: string };
    loading?: boolean;
    loginError?: boolean;
    me?: string;
    pageCount?: number;
    provider?: string;
    relationId?: number;
    resultCount?: number;
    targets?: Record<string, TargetType<'Theater' | 'User'> | undefined>;
    theaterId?: string;
    token?: string;
  };

interface UserAction extends UnknownAction {
  payload?: UserActionPayload | null;
}

const initialState: UserState = {
  account: null,
  favTheatersStatus: null,
  loading: false,
  loggedIn: false,
  loginError: false,
  passiveLoginDone: false,
  relationships: undefined,
  theaters: null
};

const loginRequest = (state: UserState) =>
  updateObject(state, {
    account: null,
    loading: true,
    loggedIn: false,
    loginError: false
  });

const loginSuccess = (state: UserState, action: UserAction) =>
  updateObject(state, loginSuccessMapper(action.payload));

const loginFailure = (state: UserState) =>
  updateObject(state, {
    account: null,
    loading: false,
    loggedIn: false,
    loginError: true,
    passiveLoginDone: true
  });

const passiveLogin = (state: UserState, action: UserAction) =>
  updateObject(state, {
    ...loginSuccessMapper(action.payload),
    loggedIn: !!action.payload
  });

const resetUserTheaters = (state: UserState) =>
  updateObject(state, {
    favTheatersStatus: REQUEST_LOADING,
    theaters: {}
  });

const getUserTheaters = (state: UserState, action: UserAction) => {
  const allTheaters: Theater<string> = {};
  Object.keys(action.payload?.actions ?? {}).forEach(key => {
    const theater = action.payload?.actions?.[key];

    if (
      theater &&
      theater.targetKey &&
      typeof action.payload?.targets?.[theater.targetKey] !== 'undefined'
    ) {
      const theaterId = getTheaterId(theater.targetKey);
      if (theaterId) {
        allTheaters[theaterId] = {
          ...theater,
          data: action.payload.targets[theater.targetKey]
        };
      }
    }
  });
  return updateObject(state, {
    favTheatersStatus: REQUEST_SUCCESSFULL,
    theaters: allTheaters,
    theatersCount: action.payload?.resultCount
  });
};

const userTheatersFailure = (state: UserState) =>
  updateObject(state, {
    favTheatersStatus: REQUEST_ERROR,
    theaters: null
  });

const deleteFavoriteTheater = (state: UserState, action: UserAction) => {
  const theatersCopy = { ...state.theaters };
  if (action.payload?.theaterId) {
    delete theatersCopy[action.payload.theaterId];
  }
  return { ...state, theaters: theatersCopy };
};

const createFavoriteTheater = (state: UserState, action: UserAction) => {
  const theatersUpdated = { ...state.theaters };
  const key = getTheaterId(action.payload?.targetKey);
  if (key && action.payload) {
    theatersUpdated[key] = action.payload;
  }
  return updateObject(state, {
    theaters: theatersUpdated
  });
};

const followeeReducer = (state: UserState, action: UserAction) =>
  updateObject(state, {
    relationships: relationshipsReducer(state.relationships, action)
  });

export default createReducer(initialState, builder => {
  builder
    .addCase(actionTypes.CREATE_FAVORITE_THEATER_SUCCESS, createFavoriteTheater)
    .addCase(actionTypes.CREATE_FOLLOWEE_REQUEST, followeeReducer)
    .addCase(actionTypes.CREATE_FOLLOWEE_SUCCESS, followeeReducer)
    .addCase(actionTypes.DELETE_FAVORITE_THEATER_SUCCESS, deleteFavoriteTheater)
    .addCase(actionTypes.DELETE_FOLLOWEE_REQUEST, followeeReducer)
    .addCase(actionTypes.DELETE_FOLLOWEE_SUCCESS, followeeReducer)
    .addCase(actionTypes.GET_FOLLOWEES_OPINION_SUCCESS, followeeReducer)
    .addCase(actionTypes.GET_USER_FOLLOWEES_REQUEST, followeeReducer)
    .addCase(actionTypes.GET_USER_FOLLOWEES_SUCCESS, followeeReducer)
    .addCase(actionTypes.GET_USER_THEATERS_FAILURE, userTheatersFailure)
    .addCase(actionTypes.GET_USER_THEATERS_REQUEST, resetUserTheaters)
    .addCase(actionTypes.GET_USER_THEATERS_SUCCESS, getUserTheaters)
    .addCase(actionTypes.LOGIN_FAILURE, loginFailure)
    .addCase(actionTypes.LOGIN_REQUEST, loginRequest)
    .addCase(actionTypes.LOGIN_SUCCESS, loginSuccess)
    .addCase(actionTypes.PASSIVE_LOGIN, passiveLogin);
});
