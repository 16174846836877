import { LocalStorageKeys } from 'common/constants/LocalStorageKeys';

export const getCapping = (storageKey: LocalStorageKeys) => {
  let capping;
  let counter;

  try {
    const state = localStorage.getItem(storageKey);

    capping = state && state.length ? JSON.parse(state) : null;
  } finally {
    counter = capping?.counter ?? 0;
  }

  return {
    counter,
    day: capping?.day ?? 0,
    month: capping?.month ?? 0,
    now: new Date()
  };
};

export const setCapping = (storageKey: LocalStorageKeys) => {
  const capping = getCapping(storageKey);
  const month = capping.now.getMonth();

  localStorage.setItem(
    storageKey,
    JSON.stringify({
      month: month,
      day: capping.now.getDate(),
      counter: month > capping.month ? 1 : capping.counter + 1
    })
  );
};
