import Cookies from 'cookies-js';

import {
  USERID_COOKIE,
  NEWSLETTER_USER_REGISTRED
} from 'common/constants/CookieNames';
import {
  NEWSLETTER_CAPPING_MODAL_INFO,
  NEWSLETTER_SUBSCRIPTION_PUSH
} from 'common/constants/LocalStorageKeys';
import { MQ_SMALL } from 'common/constants/MediaQueries';
import eventEmitter, { EventsTypes } from 'common/services/events/eventEmitter';
import {
  Candidate,
  CandidateNames
} from 'common/services/unwantedOverlay/UnwantedOverlayManager';
import hasTouch from 'common/tools/dom/getTouchBrowser';
import { getStateIndex } from 'common/tools/dom/mq-state';
import readAttribute from 'common/tools/dom/readAttribute';
import { isNotOnFirstScreen } from 'common/tools/network';
import trans from 'common/tools/translations/trans';
import { isString } from 'common/types';

import { openNewsletterModal } from 'website/services/modal';

type NewsletterType = 'movie' | 'series';

const maxModalShow = 3;
const today = Date.now();
const sevenDays = 24 * 60 * 60 * 1000 * 7;
const oneMonth = 24 * 60 * 60 * 1000 * 31;

class OverlayCandidate implements Candidate {
  name = CandidateNames.Newsletter;
  newsletterType?: NewsletterType;
  componentAnchor: HTMLElement;
  modalCappingInfo?: { date: number; time: number };

  constructor(componentAnchor: HTMLElement) {
    this.componentAnchor = componentAnchor;
    const newsletterType = readAttribute<NewsletterType, undefined>(
      this.componentAnchor,
      'data-type'
    );
    this.newsletterType = isString(newsletterType) ? undefined : newsletterType;

    const modalCappingInfo = localStorage.getItem(
      NEWSLETTER_CAPPING_MODAL_INFO
    );
    this.modalCappingInfo = modalCappingInfo
      ? JSON.parse(modalCappingInfo)
      : null;

    if (
      this.modalCappingInfo &&
      this.modalCappingInfo.date >= today - oneMonth
    ) {
      // Reset capping modal info after on month
      this.setModalCapping(today, 1);
    }
  }

  async isEligible() {
    if (!this.componentAnchor) return false;
    if (!this.newsletterType) return false;

    if (this.shouldShowModal()) {
      return true;
    }
    return false;
  }

  render() {
    let title = trans('newsletter-modal.title_movie');
    let subscribedMsg = trans('newsletter-modal.subscribed-info-movie');
    let infoMsg = trans('newsletter-modal.info_movie');
    let trackingSubject = 'movies_releases';

    if (this.newsletterType === 'series') {
      title = trans('newsletter-modal.title_series');
      subscribedMsg = trans('newsletter-modal.subscribed-info-series');
      infoMsg = trans('newsletter-modal.info_series');
      trackingSubject = 'series_news';
    }

    // Set capping modal info
    this.setModalCapping(today, (this.modalCappingInfo?.time || 0) + 1);

    let campaignId;
    switch (this.newsletterType) {
      case 'movie':
        campaignId = 202;
        break;
      case 'series':
        campaignId = 201;
        break;
      default:
        campaignId = 200;
        break;
    }

    // initialize newsletter modal after the ad
    // (fixed the bug when closing the ad,
    // the className of the HTML is restored by the ad's script,
    // which re-opens the overlay)
    eventEmitter.on(
      EventsTypes.ADS_READY,
      openNewsletterModal.bind(null, {
        campaignId,
        infoMsg,
        subscribedMsg,
        title,
        trackingSubject
      })
    );
  }

  public mainConditionsAreMet(bypassReferrer = false) {
    const userConnected = Cookies.get(USERID_COOKIE);
    const userRegistered = Cookies.get(NEWSLETTER_USER_REGISTRED);
    const userAlreadySubscribed = localStorage.getItem(
      NEWSLETTER_SUBSCRIPTION_PUSH
    );
    const mobileDevice = hasTouch() && getStateIndex() <= MQ_SMALL;

    // we always should show toaster or modal on the second screen (if referrer is allocine so) OR in case of bypassReferrer
    const referrer = isNotOnFirstScreen() || bypassReferrer;

    return (
      !userConnected &&
      !userRegistered &&
      referrer &&
      !userAlreadySubscribed &&
      !mobileDevice
    );
  }

  private shouldShowModal() {
    if (!this.newsletterType) {
      return false;
    }

    if (!this.mainConditionsAreMet()) {
      return false;
    }

    if (this.modalCappingInfo && this.modalCappingInfo.time >= maxModalShow) {
      return false;
    }

    if (
      this.modalCappingInfo &&
      this.modalCappingInfo.date >= today - sevenDays
    ) {
      return false;
    }

    return true;
  }

  private setModalCapping(date: number, time: number) {
    localStorage.setItem(
      NEWSLETTER_CAPPING_MODAL_INFO,
      JSON.stringify({ date, time })
    );
  }
}

export default OverlayCandidate;
