/**
 * Extracts the theater id from a target key
 * @param  {String} targetKeyString a string like 'theater_c0097' returned by Secure Api
 * @return {String}                 The uppercased ID (C0097)
 */
export const getTheaterId = (targetKeyString?: string) => {
  const parts = (targetKeyString ?? '').match(/^theater_(\w+)/);
  if (parts === null) {
    return undefined;
  }

  return parts[1].toUpperCase();
};
