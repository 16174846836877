import eventEmitter, { EventsTypes } from 'common/services/events/eventEmitter';
import { on } from 'common/tools/dom/events';

let ASSETS_LOADED = false;
let timer = 0;
const timeBeforeLoad = 1500;

function triggerAssetsLoading() {
  if (timer) {
    window.clearTimeout(timer);
  }

  if (!ASSETS_LOADED) {
    eventEmitter.emit(EventsTypes.LOAD_DEFER_ASSETS);
    ASSETS_LOADED = true;
  }
}

export default function loadAssets() {
  on(window, 'load', () => {
    triggerAssetsLoading();
  });

  on(window, 'DOMContentLoaded', () => {
    timer = window.setTimeout(triggerAssetsLoading, timeBeforeLoad);
  });
}

export const runAfterDeferAssets = (callback: () => void) => {
  if (ASSETS_LOADED) {
    return callback();
  } else {
    eventEmitter.once(EventsTypes.LOAD_DEFER_ASSETS, callback);
  }
};
