const isVisibleOldVersion = (elem: HTMLElement) => {
  if (!(elem instanceof Element))
    throw Error('DomUtil: elem is not an element.');
  const style = getComputedStyle(elem);
  if (style.display === 'none') return false;
  if (style.visibility !== 'visible') return false;
  if (parseInt(style.opacity) < 0.1) return false;
  if (
    elem.offsetWidth +
      elem.offsetHeight +
      elem.getBoundingClientRect().height +
      elem.getBoundingClientRect().width ===
    0
  ) {
    return false;
  }
  const elemCenter = {
    x: elem.getBoundingClientRect().left + elem.offsetWidth / 2,
    y: elem.getBoundingClientRect().top + elem.offsetHeight / 2
  };
  if (elemCenter.x < 0) return false;
  if (
    elemCenter.x > (document.documentElement.clientWidth || window.innerWidth)
  )
    return false;
  if (elemCenter.y < 0) return false;
  if (
    elemCenter.y > (document.documentElement.clientHeight || window.innerHeight)
  )
    return false;
  let pointContainer: ParentNode | null | undefined = document.elementFromPoint(
    elemCenter.x,
    elemCenter.y
  );
  do {
    if (pointContainer === elem) return true;
  } while ((pointContainer = pointContainer?.parentNode));
  return false;
};

const isVisible = window.IntersectionObserver
  ? (elem: HTMLElement) =>
      new Promise(resolve => {
        new window.IntersectionObserver(
          (entries: IntersectionObserverEntry[]) =>
            resolve(!!entries[0].isIntersecting)
        ).observe(elem);
      })
  : (elem: HTMLElement) =>
      new Promise(resolve => resolve(isVisibleOldVersion(elem)));

export default isVisible;
