import { isAnchor } from 'common/tools/dom/anchor';
import * as events from 'common/tools/dom/events';

export const openPopup = (url: string) =>
  window.open(
    url,
    '',
    'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600'
  );

const showPopup = (event: Event, el?: Node | null) => {
  if (!isAnchor(el)) {
    return;
  }

  event.preventDefault();

  openPopup(el.href);
};

export default () => {
  events.live('.js-share-btn', 'click', showPopup);
};
