import Cookies from 'cookies-js';

import {
  GOOGLE_ANALYTICS_UA,
  GOOGLE_ANALYTICS_PARTNERS,
  BRAND_HAS_GA_ANONIMIZE_IP,
  BRAND_HAS_GROUP_CONSENTS
} from 'common/configuration/constants';
import { googleana, getConsentForId } from 'common/consent/Didomi';
import { USERID_COOKIE } from 'common/constants/CookieNames';
import { hasAdblock } from 'common/tools/adblock';
import { isDev } from 'common/tools/env';

const pendingGaCalls = [];
let finalDataLayerGa = null;

// Working with multiple trackers
// https://developers.google.com/analytics/devguides/collection/analyticsjs/creating-trackers#working_with_multiple_trackers
// GOOGLE_ANALYTICS_PARTNERS is an array of objects :
// [
//   {uid: 'UA-XXXXX-Y', name: 'clientTrackerY'},
//   {uid: 'UA-XXXXX-Z', name: 'clientTrackerZ'},
//   ...
// ]
const uidArray = [
  {
    name: 'ac'
  },
  ...(GOOGLE_ANALYTICS_PARTNERS || [])
];

const _ga = (...args) => {
  if (isDev()) {
    global.console.log('ga', ...args);
  } else if (typeof global.ga === 'function') {
    global.ga(...args);
  } else {
    pendingGaCalls.push([...args]);
  }
};

const withMultipleUids = (...args) => {
  const [action, ...rest] = args;
  if (action === 'create') {
    // Create is a specific case, we can't handle it automatically here
    _ga(...args);
  } else {
    // Mutliplying ga calls for each partner
    uidArray.forEach(tracker => {
      const overridenAction =
        tracker.name === 'ac' ? action : `${tracker.name}.${action}`;
      _ga(overridenAction, ...rest);
    });
  }
};

export function ga(...args) {
  withMultipleUids(...args);
}

function convertDatalayerData(dataLayer = {}) {
  Object.keys(dataLayer).forEach(key => {
    if (dataLayer[key] != null) {
      // make sure the value is a string
      dataLayer[key] = dataLayer[key].toString();
    } else {
      dataLayer[key] = 'null';
    }
  });
  return dataLayer;
}

function getUserId() {
  return Cookies.get(USERID_COOKIE);
}

export async function createUA(hasGaConsent) {
  const userID = getUserId();

  let options = {
    // 13 months for EU limitation
    cookieExpires: 34164000,
    cookieUpdate: 'false'
  };

  if (userID && hasGaConsent) {
    options = {
      ...options,
      userId: userID,
      allowAnchor: true
    };
  }

  ga('create', GOOGLE_ANALYTICS_UA, options);

  GOOGLE_ANALYTICS_PARTNERS.forEach(tracker => {
    ga('create', tracker.uid, 'auto', tracker.name);
  });

  if (hasGaConsent) {
    ga('set', 'allowAdPersonalizationSignals', false);
  } else {
    ga('set', 'allowAdFeatures', false);
  }
}

export async function setCommonDimensions(hasGaConsent) {
  const userID = getUserId();
  const adblockValue = hasAdblock() ? 'adblock' : 'noblock';
  const migrationStatus = document.body.getAttribute('data-migration');

  if (userID && hasGaConsent) {
    ga('set', 'dimension80', userID);
  }

  if (BRAND_HAS_GROUP_CONSENTS) {
    ga('set', 'dimension97', getConsentForId(googleana));
  }

  ga('set', 'dimension1', adblockValue);
  ga('set', 'dimension2', adblockValue);
  ga('set', 'dimension98', document.referrer);
  ga('set', 'dimension102', migrationStatus);
}

export async function anonimizeIp(hasGaConsent) {
  if (BRAND_HAS_GA_ANONIMIZE_IP || !hasGaConsent) {
    ga('set', 'anonymizeIp', true);
  }
}

function getFinalDatalayer() {
  if (!finalDataLayerGa) {
    finalDataLayerGa = convertDatalayerData(window.dataLayerGA);
  }

  return finalDataLayerGa;
}

export function sendPendingGaCalls() {
  if (!global.ga) return;
  for (const pendingGaCall of pendingGaCalls) {
    ga(...pendingGaCall);
  }
}

/**
 * Sends a pageview event to GA. If the pageUrl param is not provided
 * window.location.pathname + window.location.search will be used
 *
 * @param {string} pageUrl the url to send to GA (relative)
 * @returns {undefined}
 */
export function pageview(pageUrl) {
  const _pageUrl = pageUrl || global.location.pathname + global.location.search;
  ga('set', 'page', _pageUrl);
  ga('send', 'pageview', _pageUrl, getFinalDatalayer());
}

export function hit(eventData, nonInteraction = false) {
  const finalData = convertDatalayerData({
    hitType: 'event',
    eventAction: 'clic', // setup default action
    ...eventData // override eventAction if exist
  });
  if (nonInteraction) {
    // we can't add this value before the call to convertDatalayerData
    // because it "stringifies" all values, and GA expects this one to be a boolean
    finalData.nonInteraction = true;
  }

  ga('send', finalData);
}

export function print(eventData) {
  hit(
    {
      eventAction: 'impression', // set action to impression
      ...eventData // event action can be rewrite in the eventData obj. Requested by Sofia.
    },
    true
  );
}

export function hover(eventData) {
  hit({ ...eventData, eventAction: 'hover' }, true);
}
