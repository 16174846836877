/* global AC */
import MessageFormat from '@messageformat/core';
import DOMPurify from 'dompurify';
import memoize from 'lodash/memoize';

import { isString } from 'common/types';

import { isProd } from '../env';

const mf = new MessageFormat(AC.config.lang);

const memoizedSanitizer = memoize(DOMPurify.sanitize);

function getMessage(id: string) {
  const translation = AC.translations[id];
  if (isString(translation)) {
    return translation;
  }
  if (!isProd()) {
    window.console.warn(`Unknown translation ${id}`);
    return `Unknown translation ${id}`;
  }
  return '';
}

/**
 * Translation function, based on Symfony Trans
 * @param  {String} id         The message id
 * @param  {object} parameters The translation parameters
 * @return {String}            The translated message
 */
export default function trans(
  id: string,
  parameters: { [key: string]: string | number | null | undefined } = {}
): string {
  // body...
  const message = getMessage(id);
  try {
    const msg = mf.compile(message);
    return msg(parameters);
  } catch (e) {
    return e instanceof Error ? e.message : message;
  }
}

export function translationExists(id: string): boolean {
  return Object.prototype.hasOwnProperty.call(AC.translations, id);
}

/**
 * Transchoice.
 * @deprecated Please use the 'trans' instead.
 */
export function transChoice(
  id: string,
  count: number | string,
  parameters = {}
) {
  const transParams = { ...parameters, count: count };

  return trans(id, transParams);
}

/* BELOW ARE CASE FOR PLAYER TO BE REMOVED ASAP / look also for postroll_preview_overlay_message in partner.yml when ready to remove */
const PLACEHOLDER_SUFFIX = '%';
const PLACEHOLDER_PREFIX = '%';

function replacePlaceholders(
  message: string,
  placeholders: Parameters<typeof trans>[1]
) {
  let replacedMessage = message;

  for (const index in placeholders) {
    if (Object.prototype.hasOwnProperty.call(placeholders, index)) {
      const regexp = new RegExp(
        PLACEHOLDER_PREFIX + index + PLACEHOLDER_SUFFIX,
        'g'
      );
      if (regexp.test(message)) {
        replacedMessage = replacedMessage.replace(
          regexp,
          placeholders[index]?.toString() ?? ''
        );
      }
    }
  }

  // TODO remove when we have https...
  // we want to escape any dangerous html tag or attribute
  return memoizedSanitizer(replacedMessage, {
    SAFE_FOR_TEMPLATES: true,
    FORBID_TAGS: ['style'],
    FORBID_ATTR: ['style'],
    ADD_TAGS: ['iframe']
  });
}

type CONST_TRANS = { [key: string]: string | undefined };

const VIDEO_PLAYER_PREVIEW_MESSAGE: CONST_TRANS = {
  fr: ']-Inf,1]<p class="first-paragraph">Dans <em class="time">%time%</em> seconde, Allociné vous présentera</p><p class="second-paragraph">%title%</p>|]1,Inf]<p class="first-paragraph">Dans <em class="time">%time%</em> secondes, Allociné vous présentera</p><p class="second-paragraph">%title%</p>',
  de: ']-Inf,1]<p class="first-paragraph">In <em class="time">%time%</em> Sekunden zeigt dir FILMSTARTS</p><p class="second-paragraph">%title%</p>|]1,Inf]<p class="first-paragraph">In <em class="time">%time%</em> Sekunde zeigt dir FILMSTARTS</p><p class="second-paragraph">%title%</p>',
  es: ']-Inf,1]En %time% segundos SensaCine te enseñará: <br /><strong>%title%</strong>|]1,Inf]En %time% segundo, SensaCine te enseñará <br /><strong>%title%</strong>',
  mx: ']-Inf,1]En %time% segundos SensaCine México te enseñará: <br /><strong>%title%</strong>|]1,Inf]En %time% segundo, SensaCine México te enseñará <br /><strong>%title%</strong>',
  tr: ']-Inf,1]<p class="first-paragraph">In <em class="time">%time%</em> saniye içerisinde Beyazperde\'de izleyeceksiniz</p><p class="second-paragraph">%title%</p>|]1,Inf]<p class="first-paragraph">In <em class="time">%time%</em> saniye içerisinde Beyazperde\'de izleyeceksiniz</p><p class="second-paragraph">%title%</p>',
  br: ']-Inf,1]<p class="first-paragraph">Em <em class="time">%time%</em> segundos AdoroCinema exibirá</p><p class="second-paragraph">%title%</p>|]1,Inf]<p class="first-paragraph">Em <em class="time">%time%</em> segundos, AdoroCinema exibirá</p><p class="second-paragraph">%title%</p>'
};

const VIDEO_PLAYER_TITLE_RELEASE_DATE: CONST_TRANS = {
  fr: '<strong>%title%</strong> (sortie cinéma le %day%/%month%/%year%)',
  de: '<strong>%title%</strong> (Im Kino ab: %day%.%month%.%year%)',
  es: '<strong>%title%</strong> (estreno en cines : %day%/%month%/%year%)',
  mx: '<strong>%title%</strong> (estreno en cines : %day%/%month%/%year%)',
  tr: '<strong>%title%</strong> (Vizyona giriş tarihi : %gün%/%ay%/%yıl%)',
  br: '<strong>%title%</strong> (lançamento nos cinemas : %day%/%month%/%year%)'
};

/**
 * Translation function, based on Symfony Trans
 * @param  {String} id         The message id
 * @param  {object} parameters The translation parameters
 * @return {String}            The translated message
 */
export function transPlayer(
  id: string,
  parameters: { [key: string]: string | number | null | undefined } = {}
): string {
  // body...

  let message = null;

  if (id === 'video-player.preview-message') {
    message = VIDEO_PLAYER_PREVIEW_MESSAGE[AC.config.lang];
  }

  if (id === 'video-player.title-release-date') {
    message = VIDEO_PLAYER_TITLE_RELEASE_DATE[AC.config.lang];
  }

  if (message) {
    return replacePlaceholders(message, parameters);
  }

  return '';
}
