import {
  requestAnimationFrame,
  cancelAnimationFrame
} from 'common/tools/functions/request-animation-frame';

/**
 * A performance friendly Y scroll listener.
 * It uses requestAnimationFrame (RAF) to prevent performance loss
 *
 * Adapted from https://www.html5rocks.com/en/tutorials/speed/animations/
 *
 * @param {Function} func a function to call on Y scroll
 * @return {Object} An object with start and stop methods
 */
export const onYScroll = (func: (currentScrollY: number) => void) => {
  if (!func || typeof func !== 'function') {
    return;
  }
  let started = false;
  let rafRequested = false;
  let myRaf: number | null = null;

  const update = () => {
    rafRequested = false; // Let's accept next RAF
    func(window.scrollY || document.documentElement.scrollTop);
  };

  const onScroll = () => {
    // RAF only if none has been requested yet
    if (!rafRequested) {
      myRaf = requestAnimationFrame(update);
    }
    rafRequested = true; // Let's refuse next RAF
  };

  started = true;

  window.addEventListener('scroll', onScroll);

  return {
    stop: () => {
      if (!started) {
        return;
      }
      window.removeEventListener('scroll', onScroll);
      cancelAnimationFrame(myRaf);
      started = false;
    },
    start: () => {
      if (started) {
        return;
      }
      window.addEventListener('scroll', onScroll);
      started = true;
    }
  };
};
