/* IMPORTANT : USE Jan WRAPPER */
import loadScript from 'load-script';

import { BRAND_HAS_JAN_TRACKING } from 'common/configuration/constants';
import { hasAdblock } from 'common/tools/adblock';
import { off, on } from 'common/tools/dom/events';
import { isDev } from 'common/tools/env';
import { isHTMLElement } from 'common/types';

const jan_src = window.AC.config?.jan_config?.src;
const JAN_OBJECT = window.dataLayerJan;
const AD_UNIT_PAGE = window.JadConfig
  ? `${window.JadConfig?.['tag-id']}${window.JadConfig?.['tag-other-levels']}`
  : `${window.GptConfig?.['tag-id']}${window.GptConfig?.['tag-other-levels']}`;

const elementSet = new Set<HTMLElement>();

const setAdblockTrackingObj = () => {
  const adblock = hasAdblock() ? 'adblock' : 'no-adblock';
  return {
    adblock_hit: adblock,
    adblock_session: adblock,
    adblock_user: adblock
  };
};
const getBaseTrackingObj = () => ({
  ...setAdblockTrackingObj(),
  ...JAN_OBJECT,
  ad_unit: AD_UNIT_PAGE,
  referrer: window.document.referrer
});

export const hit = (
  event: string,
  { eventName, eventAction, ...obj }: Record<string, any>
) => {
  if (!JAN_OBJECT || !BRAND_HAS_JAN_TRACKING) {
    return false;
  }

  if (isDev()) {
    window.console.debug('Jan sendEvent : ', event, obj);
  }

  window.jancmd('sendEvent', event, obj);
};

export const print = (event: string, obj: Record<string, any>) =>
  hit(`${event}_impression`, obj);

const sendJan = (
  eventName?: string,
  eventAction?: string,
  hitObj?: Record<string, any>,
  event?: Event
) => {
  if (eventName && hitObj) hit(eventName, hitObj);
  if (eventAction?.indexOf('once') !== -1) {
    const item = event?.currentTarget;
    if (isHTMLElement(item))
      off(
        item,
        'click',
        sendJan.bind(item, eventName, eventAction, hitObj, event)
      );
  }
};

export const sendPageView = (data?: Record<string, any>) => {
  if (isDev()) {
    window.console.debug('Jan send Page View : ', data);
  }
  if (data) window.jancmd('sendEvent', 'pageview', data);
  else window.jancmd('sendEvent', 'pageview');
};

export const handleDomHitElements = () => {
  const elements = document.querySelectorAll('*[data-jan]');

  elements.forEach(item => {
    if (item instanceof HTMLElement) {
      if (elementSet.has(item)) {
        elementSet.delete(item);
      } else {
        elementSet.add(item);
      }
    }
  });

  elementSet.forEach(item => {
    let trackingObj: Record<string, any> | Record<string, any>[] | null = null;

    try {
      trackingObj = item.dataset.jan ? JSON.parse(item.dataset.jan) : null;
    } catch (e) {
      window.console.error(e);
    }

    if (!Array.isArray(trackingObj)) {
      trackingObj = [trackingObj];
    }

    trackingObj?.forEach((data: Record<string, any> | null) => {
      if (data) {
        const hitObj = { ...data };
        const eventAction = data.eventAction;
        const eventName = data.eventName;

        delete hitObj.eventAction;
        delete hitObj.eventName;

        // only once by eventName for the impression event, that's why we use the event name as filter, AJAX mode or not.
        if (['impression', 'both', 'bothonce'].includes(eventAction)) {
          print(eventName, hitObj);
        }

        // only once by element for the click event, that's why we use the item as filter
        // we do that to avoid sending double hit in AJAX mode when DOM is retrived for the second time or more
        if (['click', 'clickonce', 'both', 'bothonce'].includes(eventAction)) {
          on(
            item,
            'click',
            sendJan.bind(item, `${eventName}_click`, eventAction, hitObj)
          );
        }
      }
    });
  });
};
export default (pageView = true) => {
  if (!jan_src || !JAN_OBJECT || !BRAND_HAS_JAN_TRACKING || !window.jancmd) {
    return;
  }

  if (isDev()) {
    window.console.debug('Jan setConfig : ', getBaseTrackingObj());
  }

  window.jancmd('setConfig', {
    metadata: {
      ...getBaseTrackingObj()
    }
  });

  loadScript(jan_src, {}, () => {
    if (pageView) {
      sendPageView();
    }
    handleDomHitElements();
  });
};
