export default function get(object, properties) {
  if (!object || !properties) {
    return;
  }

  const property = properties[0];

  if (object[property] !== undefined && properties.length === 1) {
    return object[property];
  }

  return get(object[property], properties.slice(1));
}
