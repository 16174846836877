import { MQ_MEDIUM } from 'common/constants/MediaQueries';
import eventEmitter, { EventsTypes } from 'common/services/events/eventEmitter';
import loadFacebookClient from 'common/third_party/facebook';
import { on } from 'common/tools/dom/events';
import { getState } from 'common/tools/dom/mq-state';

const loadFbSdk = () => {
  // first init at load
  initFbSdk(getState());

  // check on media queries change
  eventEmitter.on(EventsTypes.MQ_STATE, initFbSdk);
};

export default function () {
  if (window.document.readyState === 'complete') {
    return loadFbSdk();
  }
  return on(window, 'load', loadFbSdk);
}

function initFbSdk(mqState) {
  const rcFbWidget = document.querySelectorAll('.rc-fb-widget')[0];
  // on mobile this block is not visible
  if (rcFbWidget && mqState > MQ_MEDIUM) {
    // asynchronously load the facebook client if the page push is present
    loadFacebookClient();
    eventEmitter.removeListener(EventsTypes.MQ_STATE, initFbSdk);
  }
}
