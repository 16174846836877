export const NEWSLETTER = 'NEWSLETTER';
export const REVIEW = 'REVIEW';
export const FOLLOWEES_RATING = 'FOLLOWEES_RATING';
export const ACCOUNT_CREATED_SUCCESS = 'ACCOUNT_CREATED_SUCCESS';
export const ACCOUNT_CONFIRMED_SUCCESS = 'ACCOUNT_CONFIRMED_SUCCESS';
export const ACCOUNT_CONFIRMED_ERROR = 'ACCOUNT_CONFIRMED_ERROR';
export const ACCOUNT_ALREADY_CONFIRMED = 'ACCOUNT_ALREADY_CONFIRMED';
export const ACCOUNT_ACTIVATION_EMAIL_SENT = 'ACCOUNT_ACTIVATION_EMAIL_SENT';
export const EXPORT_PLAYER = 'EXPORT_PLAYER';
export const RATE_MOVIE_MODAL = 'RATE_MOVIE_MODAL';
export const ADD_THEATER_TO_LIST = 'ADD_THEATER_TO_LIST';
export const SPEED_NOTER_RATE_USER_SUGGESTED_MOVIES_MODAL =
  'SPEED_NOTER_RATE_USER_SUGGESTED_MOVIES_MODAL';
export const SPEED_NOTER_RATE_TOP_250_MOVIES_MODAL =
  'SPEED_NOTER_RATE_TOP_250_MOVIES_MODAL';
export const SPEED_NOTER_WANT_TO_SEE_SUGGESTED_MOVIES_MODAL =
  'SPEED_NOTER_WANT_TO_SEE_SUGGESTED_MOVIES_MODAL';
export const SERIES_PROGRESS = 'SERIES_PROGRESS';
export const ADD_SERIES_REVIEW_MODAL = 'ADD_SERIES_REVIEW_MODAL';
export const ADD_MOVIE_REVIEW_MODAL = 'ADD_MOVIE_REVIEW_MODAL';
export const ADD_COLLECTION_MODAL = 'ADD_COLLECTION_MODAL';
export const REMOVE_COLLECTION_MODAL = 'REMOVE_COLLECTION_MODAL';
export const REMOVE_COLLECTION_ENTITY_MODAL = 'REMOVE_COLLECTION_ENTITY_MODAL';
export const SPEED_NOTER_FOLLOW_USER_SUGGESTED_SERIES_MODAL =
  'SPEED_NOTER_FOLLOW_USER_SUGGESTED_SERIES_MODAL';
export const DISABLE_ACCOUNT_MODAL = 'DISABLE_ACCOUNT_MODAL';
export const ADD_ENTITY_TO_COLLECTION_MODAL = 'ADD_ENTITY_TO_COLLECTION_MODAL';
export const ADD_ENTITIES_TO_NEW_COLLECTION_MODAL =
  'ADD_ENTITIES_TO_NEW_COLLECTION_MODAL';
export const ENABLE_ACCOUNT_MODAL = 'ENABLE_ACCOUNT_MODAL';
export const SHARE_MODAL = 'SHARE_MODAL';
export const ADD_TO_COLLECTION = 'ADD_TO_COLLECTION';

export enum ModalNames {
  ACCOUNT_NEEDS_CONFIRMATION = 'ACCOUNT_NEEDS_CONFIRMATION',
  FACEBOOK_ACCOUNT_RECOVERY = 'FACEBOOK_ACCOUNT_RECOVERY',
  PROMOTE_AFFINITY = 'PROMOTE_AFFINITY',
  PROMOTE_SIGNUP = 'PROMOTE_SIGNUP',
  REWIND_MODAL = 'REWIND_MODAL',
  SEARCH_RATINGS_MOVIE_MODAL = 'SEARCH_RATINGS_MOVIE_MODAL',
  SEARCH_RATINGS_SERIES_MODAL = 'SEARCH_RATINGS_SERIES_MODAL',
  SHOWTIME_MODAL = 'SHOWTIME_MODAL',
  SPEED_NOTER_WANT_TO_SEE_USER_SUGGESTED_SERIES_MODAL = 'SPEED_NOTER_WANT_TO_SEE_USER_SUGGESTED_SERIES_MODAL',
  TOS_VALIDATION_MODAL = 'TOS_VALIDATION_MODAL'
}
