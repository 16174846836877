import { NewsLetterPushParams } from 'common/constants/trackingEventsNames';
import { hit } from 'common/tracking/googleanalytics';

export const trackNewsletterPush = ({
  eventAction,
  eventLabel,
  subject
}: NewsLetterPushParams) => {
  hit(
    {
      eventCategory: 'email_acquisition_program',
      eventAction,
      eventLabel,
      dimension77: subject,
      dimension10: window.AC.route
    },
    true
  );
};
