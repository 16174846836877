import { ReduxUserAccount } from 'common/types';

import { PASSIVE_LOGIN } from 'website/constants/ActionTypes';

/**
 * This is a classic redux action, called after a successfull passive login
 * @param  {Object} user - the user informations comming from the Secure call.
 * @return {Object}  the action to dispatch
 */
export const passiveLogin = (user: ReduxUserAccount | null) => ({
  type: PASSIVE_LOGIN,
  payload: user
});
