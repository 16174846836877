import classNames from 'classnames';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators } from 'redux';

import UserRating, { Size } from 'common/components/UserRating';
import { SocialActionPlacements } from 'common/constants/trackingEventsNames';
import { isBoundByAReview } from 'common/tools/opinion/rating';
import trans from 'common/tools/translations/trans';

import {
  closeAffinitySuggestions,
  getAffinitySuggestions
} from 'website/actions/AffinitySuggestionsActions';
import {
  updateOpinion,
  deleteOpinion,
  createOpinion
} from 'website/actions/OpinionActions';
import AuthenticatedLink from 'website/components/user/AuthenticatedLink';
import { State, Dispatch } from 'website/reducers';
import { getOpinionForEntity } from 'website/reducers/data/helper';
import { openReviewModal } from 'website/services/modal';
import { isSeries, isMovie } from 'website/types';

export type StandaloneRatingProps = {
  baseEntityId?: string;
  entityId: string;
  showTitle?: boolean;
  size?: Size;
  socialActionPlacement?: SocialActionPlacements;
  title?: string;
  showDeleteRating?: boolean;
  withSuggestions?: boolean;
};

type PropsFromRedux = ConnectedProps<typeof connector>;

const mapStateToProps = (state: State, ownProps: StandaloneRatingProps) => {
  const { entityId } = ownProps;

  const entity = state.data.all[entityId];
  const opinion = getOpinionForEntity(entityId, state.data);

  const affinitySuggestions =
    state.affinitySuggestions.suggestionsBlocks[ownProps.entityId];
  const suggestionsOpen = affinitySuggestions
    ? affinitySuggestions.open
    : false;

  const baseEntity = ownProps.baseEntityId
    ? state.data.all[ownProps.baseEntityId]
    : undefined;

  return {
    baseEntity: baseEntity && isSeries(baseEntity) ? baseEntity : undefined,
    entity,
    opinion,
    suggestionsOpen
  };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      createOpinion,
      deleteOpinion,
      updateOpinion,
      getAffinitySuggestions,
      closeAffinitySuggestions
    },
    dispatch
  );

const connector = connect(mapStateToProps, mapDispatchToProps);

const StandaloneRating = ({
  baseEntity,
  entity,
  opinion,
  createOpinion,
  deleteOpinion,
  updateOpinion,
  showTitle = false,
  size,
  socialActionPlacement = 'standalone',
  title,
  showDeleteRating = true,
  withSuggestions = false,
  suggestionsOpen = false,
  getAffinitySuggestions,
  closeAffinitySuggestions
}: StandaloneRatingProps & PropsFromRedux) => {
  if (!entity) return null;
  // we need to keep the entity type for tracking
  // if entity is series_season or series_episode
  // we override series title and id only
  let trackingEntity;
  if (baseEntity) {
    trackingEntity = {
      ...entity,
      title: baseEntity.title,
      legacyId: baseEntity.legacyId
    };
  } else {
    trackingEntity = { ...entity };
  }
  const trackingContext = {
    entity: trackingEntity,
    eventLabel: 'ratings',
    socialActionPlacement
  };

  return (
    <AuthenticatedLink>
      <div className={classNames('user-rating-holder')}>
        {showTitle ? <span className="user-rating-title">{title}</span> : null}
        <UserRating
          value={opinion?.rating ?? undefined}
          size={size}
          onDelete={() => {
            if (
              opinion &&
              opinion.review &&
              // eslint-disable-next-line no-alert
              window.confirm(trans('opinion.rating.remove'))
            ) {
              deleteOpinion(opinion, entity, trackingContext);
            } else if (opinion && !opinion.review) {
              deleteOpinion(opinion, entity, trackingContext);
            }

            if (
              withSuggestions &&
              suggestionsOpen &&
              (isMovie(entity) || isSeries(entity))
            ) {
              // if the current state is inactive
              // the next state will open the suggestions
              closeAffinitySuggestions(entity);
            }
          }}
          onCreate={newValue => {
            if (
              (isMovie(entity) || isSeries(entity)) &&
              isBoundByAReview(entity)
            ) {
              openReviewModal({ entityId: entity.id, rating: newValue });

              return;
            }

            createOpinion(
              { ...opinion, rating: newValue },
              entity,
              trackingContext
            );

            if (
              withSuggestions &&
              !suggestionsOpen &&
              (isMovie(entity) || isSeries(entity))
            ) {
              // if the current state is inactive
              // the next state will open the suggestions
              getAffinitySuggestions(entity);
            }
          }}
          onUpdate={(newValue, previousValue) => {
            updateOpinion(
              { ...opinion, rating: newValue },
              entity,
              previousValue,
              trackingContext
            );
          }}
          showDeleteRating={showDeleteRating}
        />
      </div>
    </AuthenticatedLink>
  );
};

export default connector(StandaloneRating);
