import eventEmitter, { EventsTypes } from 'common/services/events/eventEmitter';
import { on } from 'common/tools/dom/events';
import debounce from 'common/tools/functions/debounce';
import isNumeric from 'common/tools/string/isNumeric';

const mqIndicator = document.getElementById('mq-state');
let currentState: 'unknown' | number | false | null = null;

const MQ_UNKNOWN = 'unknown';

export const getState = () => currentState;

export const isStateBiggerThan = (state: number) =>
  (typeof currentState === 'number' ? currentState : 0) < state;

// The state may not return a number, which becomes
// problematic when you have to compare with other
// numbers. This function will return a number anyway.
export const getStateIndex = () => (isNumeric(currentState) ? currentState : 0);

const computeState = () => {
  if (!mqIndicator) {
    return false;
  }

  const index = parseInt(
    window.getComputedStyle(mqIndicator).getPropertyValue('z-index'),
    10
  );
  return index || MQ_UNKNOWN;
};

const initMqState = () => {
  currentState = computeState(); // on start, store the current state

  on(
    window,
    'resize',
    debounce(function resizeCallback() {
      const newState = computeState();
      if (currentState !== newState) {
        currentState = newState; // store the current state
        eventEmitter.emit(EventsTypes.MQ_STATE, currentState);
      }
    })
  );

  // media queries state detection
  window.MqState = {
    getState: getState
  };
};

export default initMqState;
