export default function isVisible(elem) {
  const w = elem.offsetWidth;
  const h = elem.offsetHeight;
  if (w === 0 && h === 0 && elem.style.display !== 'none') {
    return false;
  }
  if (w > 0 && h > 0 && elem.style.display !== 'none') {
    return true;
  }
}
