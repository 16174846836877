import memoizeIntlConstructor from 'intl-format-cache';

import { LOCALE } from 'common/configuration/constants';

// Infer from MemoizeFormatConstructorFn and Intl.DateTimeFormat constructor
let getDateTimeFormat: (
  locales?: string | string[],
  options?: Intl.DateTimeFormatOptions
) => Intl.DateTimeFormat;

// locales are coming from PHP, and use "fr_FR" formatting, while Javascript wants "fr-FR" ...
const convertLocale = (locale?: string) => locale?.replace('_', '-');
const convertedLocale = convertLocale(LOCALE);

export const ISO_DATETIME = 'iso-date-time';
export const ISO_DATE = 'iso-date';

export const DATE_FORMATS: Record<string, Intl.DateTimeFormatOptions> = {
  SHORT: {
    // 17/12/2015
    day: '2-digit',
    month: '2-digit',
    year: 'numeric'
  },
  SHORT_UTC: {
    // 17/12/2015
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    timeZone: 'UTC'
  },
  MEDIUM: {
    // 17 déc. 2015
    day: '2-digit',
    month: 'short',
    year: 'numeric'
  },
  LONG: {
    // 17 décembre 2015
    day: '2-digit',
    month: 'long',
    year: 'numeric'
  },
  FULL: {
    // jeudi 17 décembre 2015
    weekday: 'long',
    day: '2-digit',
    month: 'long',
    year: 'numeric'
  }
};

export const HOURS_FORMATS: Record<string, Intl.DateTimeFormatOptions> = {
  SHORT: {
    // 17:15
    hour: '2-digit',
    minute: '2-digit'
  },
  LONG: {
    // 17:15:56
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit'
  },
  FULL: {
    // 17:15:56 UTC + 1
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZoneName: 'short'
  }
};

const isDateTimeFormat = (
  candidate: Intl.DateTimeFormatOptions | typeof ISO_DATETIME | typeof ISO_DATE
): candidate is Intl.DateTimeFormatOptions => typeof candidate !== 'string';

/**
 * Converts a date using Intl Native extension
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/DateTimeFormat
 */
export default (
  date: Date,
  options?: Intl.DateTimeFormatOptions | typeof ISO_DATETIME | typeof ISO_DATE
) => {
  const opt = options ?? DATE_FORMATS.SHORT;

  if (opt === ISO_DATETIME) {
    return date.toISOString();
  }
  if (opt === ISO_DATE) {
    return date.toISOString().substring(0, 10);
  }

  if (!getDateTimeFormat) {
    getDateTimeFormat = memoizeIntlConstructor(Intl.DateTimeFormat);
  }

  return getDateTimeFormat(
    convertedLocale,
    isDateTimeFormat(opt) ? opt : undefined
  ).format(date);
};
