/* global AC */

// ADS position constante referer for changing position
export const targetATF = document.getElementById('mobile-referrer-atf');
export const targetMTF = document.getElementById('mobile-referrer-mtf');
export const targetBTF = document.getElementById('mobile-referrer-btf');
export const targetPromo1 = document.getElementById('mobile-referrer-promo-1');

// ADS Newspage Context
export const newsContext = document.getElementById('article-content');
export const slideshowContext = document.getElementById('slideshow-anchor');
export const longreadContext = document.getElementById('longread-content');

export const DISPLAY_PROVIDER = AC.config.ads.displayProvider;

// EMERGENCE
export const bannerEmergence = document.getElementById('banner-emergence');

// AD STREAM PROVIDERS
export const POSTROLL_PROXY = AC.config.ads.postrollProxy;
export const PREROLL_PROVIDER = AC.config.ads.prerollProvider;
export const POSTROLL_PROVIDER = AC.config.ads.postrollProvider;
export const POSTROLL_TRAILER_PROVIDER = AC.config.ads.postrollTrailerProvider;

// POSITION
export const AD_ATF = AC.config.ads.atf;
export const AD_SLIDESHOW_ATF = AC.config.ads.slideshow;
export const AD_SLIDESHOW_NATIVE_BANNER = AC.config.ads.slideshownativebanner;
export const AD_NATIVE_BOOKING = AC.config.ads.nativebooking;

// DM Player (PREROLL)
export const DM_PREMIUM_KEYWORD = 'premium';

// DFP PREROLL
export const DFP_BASE_URL = AC.config.player.dfp.baseUrl;

// FACEBOOK PREROLL
export const FACEBOOK_PREROLL_BASE_URL =
  AC.config.player.facebook.prerollBaseUrl;
export const FACEBOOK_PREROLL_DESKTOP_ID =
  AC.config.player.facebook.prerollDesktopId;
export const FACEBOOK_PREROLL_MOBILE_ID =
  AC.config.player.facebook.prerollMobileId;

// STICKY POS CLASS REF
export const STICKY_POS_HOLDER_CLASS = 'sticky-ad-holder';

export const INHIBITION_AD_ID = 4884515466;

export const PROGRAMMATIC_ADVERTISER_IDS = {
  allocine: [
    4716679368, // WebediaAutopromo
    4799475562, // Improve Digital
    4704424043, // Index Exchange
    4704423806, // Rubicon
    4789704859, // Pubmatic
    4754188252, // Open X
    4772834290, // Rythmone
    4723486739, // Prebid
    4723192977, // Amazon
    4803343382, // Smart
    53717792 // GoogleAdNetwork
  ],
  adorocinema: [
    4879771914, // Appnexus
    4863394272, // Appnexus
    4768182848, // Criteo
    4864121015, // Criteo
    4538421767, // Google Adsense
    4660665523, // Mediawayss
    4512916229, // Prebid
    4863396363, // Seedtag
    4521972907, // SeedTag
    4594247181, // Smartclip
    4587473888, // Taboola
    4874165197, // Teads
    4521666852, // Teads
    4775054806, // TruVid
    4708445930, // Vidoomy
    4863586706, // Verizon
    4776960473, // Verizon
    4763742364, // AudienceRun
    4863584858, // Rubicon
    4577825693, // Rubicon
    4578059641, // Rubicon Project
    4577831192, // RubiconProject
    4838257582, // Smart
    0 // Open Bidding
  ]
};
