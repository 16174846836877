export const requestAnimationFrame =
  window.requestAnimationFrame ||
  window.mozRequestAnimationFrame ||
  window.webkitRequestAnimationFrame ||
  function fakeAnimationFrame(fn) {
    window.setTimeout(fn, 15);
  };

export const cancelAnimationFrame =
  window.cancelAnimationFrame || window.mozCancelAnimationFrame;
