import { UserTheaterTracking } from 'common/constants/trackingEventsNames';
import slugify from 'common/tools/string/slugify';
import { hit } from 'common/tracking/googleanalytics';
import socialActionTracking from 'common/tracking/socialActionTracking';

import { isTheater } from 'website/types';

export const trackUserTheater = (
  eventAction: string,
  { context: { entity, socialActionPlacement } }: UserTheaterTracking
) => {
  // since data past to GQL
  // no data match entity status

  let internalId;
  let name;

  if (entity && isTheater(entity)) {
    internalId = entity.internalId;
    name = entity.name;
  }

  hit(
    {
      eventCategory: 'social',
      eventLabel: 'my_theaters',
      eventAction,
      dimension82: `${internalId}-${slugify(name)}`, // entity
      dimension84: 'theater', // entity theme
      dimension85: socialActionPlacement // social action placement
    },
    true
  );

  socialActionTracking(
    'fav_theater',
    eventAction === 'remove' ? 'delete' : eventAction
  );
};
