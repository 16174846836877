/**
 * here will be all the "common / basic" code
 * such as obfuscation, images lazy-loading, etc...
 * avoid any brand related treatment on the modules loaded by this file
 *
 */
import { enableMapSet } from 'immer';

import { triggerDidomiPopup } from 'common/consent/Didomi';
import { trackConsent } from 'common/consent/Tcf';
import { MQ_MEDIUM } from 'common/constants/MediaQueries';
import initializeSearchEngine from 'common/containers/generic/headerSearch';
import initAdvertisementSystem from 'common/module/advertisement';
import loadTracking from 'common/module/tracking-loader';
import headerNav from 'common/module/ui/header-main';
import initShareButtons from 'common/module/ui/share-btn';
import UnwantedOverlayManager from 'common/services/unwantedOverlay/UnwantedOverlayManager';
import { setTouchBrowser } from 'common/tools/dom/getTouchBrowser';
import initMqState from 'common/tools/dom/mq-state';
import { initialize as initializeRouter } from 'common/tools/network/routing';
import injectPolyfills from 'common/tools/polyfills';
import desobfuscation from 'common/tools/seo/desobfuscation';
import initAnchors from 'common/tools/ui/anchor';
import cookieInfo from 'common/tools/ui/cookieInfo';
import initLazyLoad from 'common/tools/ui/lazyload';
import loadAssets from 'common/tools/ui/load-assets';
import loadFonts from 'common/tools/ui/load-fonts';
import loadSecondaryCss from 'common/tools/ui/load-secondary-css';
import mxMessage from 'common/tools/ui/mxMessage';
import initializeRiC, { addToRicThread } from 'common/tools/ui/request-idle-cb';
import initJan from 'common/tracking/jan';

import globalPushNewsletter from 'website/containers/newsletter/global-push-newsletter';
import accountConfirmationReminder from 'website/containers/user/accountConfirmationReminder';
import globalPushPromoteAffinity from 'website/containers/user/global-push-promote-affinity';
import globalPushPromoteSignup from 'website/containers/user/global-push-promote-signup';
import welcomeModal from 'website/containers/user/welcomeModal';
import loadEmergenceCountdown from 'website/module/emergence/emergence-countdown';
import initPlayerEmergence from 'website/module/player/emergence';
import initBackToTop from 'website/module/ui/backtoTop';
import initKonamiCode from 'website/module/ui/konami';
import initMoreTrigger from 'website/module/ui/more-trigger';
import loadOneTap from 'website/third_party/googleonetap';
import initializeTracking from 'website/tracking/listener';

export default async (sendPageView = true) => {
  await injectPolyfills();

  enableMapSet();
  initMqState();
  desobfuscation();
  loadAssets();
  loadFonts();
  loadSecondaryCss();
  initLazyLoad();
  initAnchors();
  initShareButtons();
  initBackToTop();
  initKonamiCode();
  initMoreTrigger();
  initializeRouter(window.AC.routing);
  initializeTracking();
  initializeRiC();

  addToRicThread(headerNav.bind(null, MQ_MEDIUM));

  setTouchBrowser();
  loadTracking(true, false, {});
  trackConsent();
  initAdvertisementSystem();
  initPlayerEmergence();
  loadEmergenceCountdown();
  initializeSearchEngine();
  addToRicThread(accountConfirmationReminder);
  addToRicThread(welcomeModal);
  addToRicThread(cookieInfo);
  addToRicThread(mxMessage);
  globalPushNewsletter();
  globalPushPromoteAffinity();
  globalPushPromoteSignup();
  loadOneTap();

  triggerDidomiPopup('.cookie-notice', 'notice');
  triggerDidomiPopup('.cookie-preferencies', 'preferences');
  initJan(sendPageView);

  UnwantedOverlayManager.electCandidate();
};
