import copy from 'clipboard-copy';
import { useState, MutableRefObject } from 'react';

const useCopyToClipboard = (
  inputRef?: MutableRefObject<HTMLInputElement | null>
): [boolean, (text: string) => void] => {
  const [isCopied, setIsCopied] = useState(false);
  return [
    isCopied,
    (text: string) => {
      if (inputRef && inputRef.current) {
        inputRef.current.select();
      }
      copy(text).then(() => setIsCopied(true));
    }
  ];
};

export default useCopyToClipboard;
