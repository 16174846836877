import { TrackingContextObject } from 'common/constants/trackingEventsNames';
import slugify from 'common/tools/string/slugify';
import { hit } from 'common/tracking/googleanalytics';
import { standardizeTrackingType } from 'common/tracking/helpers';

import { isMovie, isSeries, isEpisode } from 'website/types';

import { EventAction } from '.';

export const trackSeen = (
  eventAction: EventAction,
  { context: { entity, socialActionPlacement } }: TrackingContextObject
): void => {
  // since data past to GQL
  // no data match entity status

  if (!isMovie(entity) && !isSeries(entity) && !isEpisode(entity)) {
    return;
  }

  const { legacyId, title, typename } = entity;

  hit(
    {
      eventCategory: 'social',
      eventLabel: 'seen',
      eventAction,
      dimension82: `${legacyId}-${slugify(title)}`, // entity
      dimension83: null, // entity status
      dimension84: standardizeTrackingType(typename), // entity theme
      dimension85: socialActionPlacement, // social action placement
      dimension87: legacyId // entity ID
    },
    true
  );
};
