import { combineReducers } from 'redux';

import actionsPerEntity, { ActionsPerEntityState } from './actionsPerEntity';
import all, { AllState } from './all';

export type DataState = {
  all: AllState;
  actionsPerEntity: ActionsPerEntityState;
};

export default combineReducers({ all, actionsPerEntity });
