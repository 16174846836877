import { geolocalizeIp } from 'common/api/TechApi';
import { addClass, removeClass } from 'common/tools/dom/classes';
import * as events from 'common/tools/dom/events';

const HIDE_MX_MESSAGE = 'HIDE_MX_MESSAGE';

export default async function mxMessage() {
  const messageContainer = document.getElementById('js-mx-message');
  if (messageContainer && !sessionStorage.getItem(HIDE_MX_MESSAGE)) {
    if ((await geolocalizeIp()) !== 'MX') return;
    removeClass(messageContainer, 'hidden');
    events.on(
      messageContainer.getElementsByClassName('message-close'),
      'click',
      () => {
        addClass(messageContainer, 'hidden');
        sessionStorage.setItem(HIDE_MX_MESSAGE, 'true');
      }
    );
  }
}
