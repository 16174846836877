import UnwantedOverlayManager from 'common/services/unwantedOverlay/UnwantedOverlayManager';

import PromoteAffinityOverlayCandidate from 'website/components/user/PromoteAffinityModal/OverlayCandidate';

export default function globalPushPromoteAffinity() {
  const componentAnchor = document.getElementById('promote-affinity');

  if (!componentAnchor) {
    return false;
  }

  UnwantedOverlayManager.registerCandidate(
    new PromoteAffinityOverlayCandidate()
  );
}
