import React, { ComponentType } from 'react';
import { Provider } from 'react-redux';

import getDisplayName from 'common/tools/react/getDisplayName';

import store from 'website/store/';

const allocineContainer = <T extends Record<string, unknown>>(
  ComposedComponent: ComponentType<T>
) => {
  const RenderedComponent = (props: T) => (
    <Provider store={store}>
      <ComposedComponent {...props} />
    </Provider>
  );
  RenderedComponent.displayName = `allocineContainer(${getDisplayName(
    ComposedComponent
  )})`;

  return RenderedComponent;
};

export default allocineContainer;
