import { TheaterHeaderTooltipTracking } from 'common/constants/trackingEventsNames';
import { hit } from 'common/tracking/googleanalytics';
import { hit as hit_jan } from 'common/tracking/jan';

export const trackTooltipClick = ({
  tracking,
  jan
}: TheaterHeaderTooltipTracking) => {
  hit({ ...tracking, eventAction: 'clic' }, false);

  if (jan) hit_jan('theater_technologies_click', jan);
};

export const trackTooltipHover = ({ jan }: TheaterHeaderTooltipTracking) => {
  if (jan) hit_jan('theater_technologies_hover', jan);
};
