const LS_FREE_FROM_ADS_KEY = 'free4ever';

function noAds4Ever() {
  const key = new RegExp(`${LS_FREE_FROM_ADS_KEY}$`);
  const alreadyFree = localStorage.getItem(LS_FREE_FROM_ADS_KEY) || null;
  const wantTobeFree =
    window.location.hash && key.exec(window.location.hash.split('#')[1]);

  if (wantTobeFree) {
    localStorage.setItem(LS_FREE_FROM_ADS_KEY, 'true');
  }

  if (wantTobeFree || alreadyFree) {
    return true;
  }

  return false;
}

export function noAds() {
  return (
    window.location.hash && /no-ads$/.exec(window.location.hash.split('#')[1])
  );
}

export function noGtm() {
  return (
    (window.location.hash &&
      /no-gtm$/.exec(window.location.hash.split('#')[1])) ||
    noAds4Ever() ||
    noAds()
  );
}

export function noAdserver() {
  return (
    (window.location.hash &&
      /no-adserver$/.exec(window.location.hash.split('#')[1])) ||
    noAds4Ever() ||
    noAds()
  );
}

export function noAutoplay() {
  return (
    (window.location.hash &&
      /no-autoplay$/.exec(window.location.hash.split('#')[1])) ||
    noAds4Ever() ||
    noAds()
  );
}
