import merge from 'lodash/merge';

import {
  ADD_ENTITIES,
  GET_USER_SOCIAL_ACTIONS_FOR_ENTITIES_SUCCESS,
  UPDATE_OPINION_REQUEST,
  UPDATE_OPINION_FAILURE,
  CREATE_OPINION_REQUEST,
  DELETE_OPINION_REQUEST,
  CREATE_OPINION_SUCCESS,
  CREATE_SOCIAL_ACTION_REQUEST,
  CREATE_SOCIAL_ACTION_FAILURE,
  CREATE_SOCIAL_ACTION_SUCCESS,
  DELETE_SOCIAL_ACTION_REQUEST,
  DELETE_SOCIAL_ACTION_FAILURE,
  WRITE_REVIEW_SUCCESS,
  GET_AFFINITY_SUGGESTIONS_SUCCESS,
  OPINION_ACTIONS_RETRIEVAL_SUCCESS,
  CREATE_OPINION_FAILURE,
  DELETE_OPINION_FAILURE,
  DELETE_SOCIAL_ACTION_SUCCESS,
  DELETE_OPINION_SUCCESS,
  DELETE_REVIEW_SUCCESS
} from 'website/constants/ActionTypes';
import {
  Entity,
  SocialAction,
  EntityTypename,
  isSocialAction,
  SocialActionTypename
} from 'website/types';

import {
  jsEntityMapper,
  userEntityLeafMapper,
  actionMapper,
  titaniaMapper
} from './helper';
import { GraphSocialAction, GraphUserEntityLeaf, JsEntity } from './types';

export type AllState = { [key: string]: Entity | SocialAction | undefined };

const getSocialActionForEntity = (
  state: AllState,
  entity: Entity,
  typename: SocialActionTypename
) => {
  return Object.values(state).find<SocialAction>(
    (candidate): candidate is SocialAction =>
      !!candidate &&
      isSocialAction(candidate) &&
      entity.id === candidate.relatedEntity &&
      candidate.typename === typename
  );
};

const generateStateUpdateForNewEntities = (newEntities: Entity[]): AllState => {
  return newEntities.reduce<AllState>((acc, newEntity) => {
    acc[newEntity.id] = newEntity;
    return acc;
  }, {});
};

export default function all(state: AllState = {}, action: any): AllState {
  const payload = action.payload;
  switch (action.type) {
    case ADD_ENTITIES: {
      const newEntities = jsEntityMapper(Object.values<JsEntity>(payload));
      const stateUpdate = generateStateUpdateForNewEntities(newEntities);
      return merge({}, state, stateUpdate);
    }

    case CREATE_SOCIAL_ACTION_REQUEST: {
      const tempId = action.transactionId;
      const stateUpdate = {
        [tempId]: {
          id: tempId,
          relatedEntity: action.entity.id,
          date: new Date(),
          typename: action.socialActionTypename
        }
      };

      return merge({}, state, stateUpdate);
    }
    case CREATE_SOCIAL_ACTION_SUCCESS: {
      const userEntityLeaf = action.payload;
      const mappedUserEntityLeaf = userEntityLeafMapper(userEntityLeaf);
      const { entity: _, ...socialActions } = mappedUserEntityLeaf;

      const stateUpdate = {
        ...Object.values(socialActions)
          .filter(Boolean)
          .reduce((acc: Record<string, unknown>, item) => {
            if (item?.id) acc[item.id] = item;
            return acc;
          }, {})
      };

      const newState = merge({}, state, stateUpdate);
      delete newState[action.transactionId];
      return newState;
    }

    case CREATE_SOCIAL_ACTION_FAILURE: {
      const newState = merge({}, state);
      delete newState[action.transactionId];
      return newState;
    }

    case DELETE_SOCIAL_ACTION_REQUEST: {
      const socialAction = getSocialActionForEntity(
        state,
        action.entity,
        action.socialActionTypename
      );

      if (!socialAction) {
        return { ...state };
      }

      const stateUpdate = {
        [socialAction.id]: {
          isDeleted: true
        }
      };

      return merge({}, state, stateUpdate);
    }

    case DELETE_SOCIAL_ACTION_SUCCESS: {
      const socialAction = getSocialActionForEntity(
        state,
        action.entity,
        action.socialActionTypename
      );
      if (!socialAction) {
        return { ...state };
      }
      const newState = merge({}, state);
      delete newState[socialAction.id];
      return newState;
    }

    case DELETE_SOCIAL_ACTION_FAILURE: {
      const socialAction = getSocialActionForEntity(
        state,
        action.entity,
        action.socialActionTypename
      );

      if (!socialAction) {
        return { ...state };
      }

      const stateUpdate = {
        [socialAction.id]: {
          isDeleted: false
        }
      };

      return merge({}, state, stateUpdate);
    }

    case UPDATE_OPINION_REQUEST: {
      return {
        ...state,
        [action.opinion.id]: {
          ...action.opinion
        }
      };
    }

    case UPDATE_OPINION_FAILURE: {
      return {
        ...state,
        [action.opinion.id]: {
          ...action.opinion,
          rating: action.previousValue
        }
      };
    }

    case CREATE_OPINION_REQUEST: {
      const tempId = action.transactionId;
      const stateUpdate = {
        [tempId]: {
          ...action.opinion,
          id: tempId,
          relatedEntity: action.entity.id,
          date: new Date(),
          typename: 'Opinion'
        }
      };

      return merge({}, state, stateUpdate);
    }
    case WRITE_REVIEW_SUCCESS:
    case CREATE_OPINION_SUCCESS: {
      const userEntityLeaf = action.payload;

      const mappedUserEntityLeaf = userEntityLeafMapper(userEntityLeaf);
      const { entity: _, ...socialActions } = mappedUserEntityLeaf;

      const stateUpdate = {
        ...Object.values(socialActions)
          .filter(Boolean)
          .reduce((acc: Record<string, unknown>, item) => {
            if (item?.id) acc[item.id] = item;
            return acc;
          }, {})
      };

      const newState = merge({}, state, stateUpdate);
      delete newState[action.transactionId];
      return newState;
    }

    case CREATE_OPINION_FAILURE: {
      const newState = merge({}, state);
      delete newState[action.transactionId];
      return newState;
    }

    case DELETE_OPINION_REQUEST: {
      const stateUpdate = {
        [action.opinion.id]: {
          isDeleted: true
        }
      };

      return merge({}, state, stateUpdate);
    }

    case DELETE_REVIEW_SUCCESS:
    case DELETE_OPINION_SUCCESS: {
      const newState = merge({}, state);
      const opinionId = action?.opinion?.id ?? action?.opinionId;
      if (newState[opinionId]) {
        delete newState[opinionId];
      }
      return newState;
    }

    case DELETE_OPINION_FAILURE: {
      const stateUpdate = {
        [action.opinion.id]: {
          isDeleted: false
        }
      };
      return merge({}, state, stateUpdate);
    }

    case GET_AFFINITY_SUGGESTIONS_SUCCESS: {
      const newEntities = (action.payload.entities || []).map(titaniaMapper);
      const stateUpdate = generateStateUpdateForNewEntities(newEntities);
      return merge({}, state, stateUpdate);
    }

    case GET_USER_SOCIAL_ACTIONS_FOR_ENTITIES_SUCCESS: {
      const userEntityLeaves: [GraphUserEntityLeaf] = payload;
      const newState = userEntityLeaves.reduce(
        (acc: AllState, userEntityLeaf) => {
          const { entity, ...socialActions } =
            userEntityLeafMapper(userEntityLeaf);
          const stateSocialUpdate = Object.values(socialActions).reduce(
            (acc2: AllState, item: SocialAction | null) => {
              if (item) {
                acc2[item.id] = item;
              }
              return acc2;
            },
            {}
          );

          // we need to update userAffinity status
          // is not given by window.jsEntities
          // it's come from the user social actions for entities request
          const stateEntityUpdate =
            entity.id && 'userAffinity' in entity && entity?.userAffinity
              ? {
                  [entity.id]: {
                    userAffinity: entity.userAffinity
                  }
                }
              : {};
          const stateUpdate = merge(stateEntityUpdate, stateSocialUpdate);

          return merge(acc, stateUpdate);
        },
        {}
      );
      return merge({}, state, newState);
    }

    case OPINION_ACTIONS_RETRIEVAL_SUCCESS: {
      const socialActions = action.payload.map(
        (socialAction: GraphSocialAction) => actionMapper(socialAction)
      );
      const stateUpdate = socialActions.reduce(
        (acc: AllState, socialAction: SocialAction) => {
          acc[socialAction.relatedEntity] = {
            id: socialAction.relatedEntity,
            legacyId: +window.atob(socialAction.relatedEntity).split(':')[1],
            typename: EntityTypename.UserReview
          };
          acc[socialAction.id] = { ...socialAction };
          return acc;
        },
        {}
      );

      return merge({}, state, stateUpdate);
    }
    default:
      return state;
  }
}
