import { Middleware } from 'redux';

import { hit } from 'common/tracking/googleanalytics';

import { isACAction } from 'website/store';

const tracking: Middleware = () => next => action => {
  const tracking = isACAction(action) && action.meta && action.meta.tracking;
  if (!tracking) {
    return next(action);
  }
  if (typeof tracking === 'function') {
    const data = tracking();
    if (data === false) {
      return next(action);
    }
    hit(data);
  } else {
    hit(tracking);
  }
  return next(action);
};

export default tracking;
