import React, { FC, ReactNode } from 'react';

import { hasClass } from 'common/tools/dom/classes';
import getDisplayName from 'common/tools/react/getDisplayName';

import { CloseButtonTooltip } from 'website/components/Tooltip';

export type TooltipBoosterHocProps = {
  entityId: string;
};

const TOOLTIP_BOOSTER_KEY = 'tooltip_booster';

/**
 * this HOC will define the conditions for
 * displaying a tooltip specific to the
 * boost of a feature
 *
 * @param {Component} WrappedComponent  a component
 * @param {Element}   anchor            dom anchor of wrapped component
 * @param {String}    boosterClass      className to display tooltip
 * @param {Component} BoosterContent    tooltip content
 * @param {String}    eventType         part that will complete the name of events emitted
 * @returns {Component} a component
 */
const tooltipBoosterHoc = <P,>(
  WrappedComponent: FC<P & TooltipBoosterHocProps>,
  anchor: Element,
  boosterClass: string,
  BoosterContent: ReactNode
) => {
  const Component = (props: P & TooltipBoosterHocProps) => {
    // define for each feature the key in local storage to not restart the tooltip once closed by the cross
    const localStorageKey = `${TOOLTIP_BOOSTER_KEY}.${boosterClass}`;
    // detects if the tooltip has already been closed on another page so as not to display it again
    const boosterSkip = localStorage.getItem(localStorageKey);

    // show the tooltip when the expected class css is on the target and the tooltip has not already been closed
    if (!boosterSkip && hasClass(anchor, boosterClass)) {
      // when closing the tooltip with the cross, save the associated key in local storage to prevent it from being displayed on future pages
      const tooltipClose = () => {
        localStorage.setItem(localStorageKey, 'true');
      };

      return (
        <CloseButtonTooltip
          position="bottom"
          theme="blue"
          tooltipContent={BoosterContent}
          onClose={tooltipClose}
          closeOnTargetClick
        >
          <WrappedComponent {...props} />
        </CloseButtonTooltip>
      );
    }
    return <WrappedComponent {...props} />;
  };

  Component.displayName = `tooltipBoosterHoc(${getDisplayName(
    WrappedComponent
  )})`;

  return Component;
};

export default tooltipBoosterHoc;
