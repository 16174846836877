import removeDiacritics from './removeDiacritics';
import unescape from './unescape';

const slugify = (str: any, separator = '_') => {
  if (typeof str === 'string') {
    const strCleaned = removeDiacritics(unescape(str));
    if (typeof strCleaned === 'string') {
      return strCleaned
        .toLowerCase() // lowercase
        .replace(/['\s-]+/g, separator) // replace ' spaces and -
        .replace(new RegExp('[^\\w\\' + separator + ']', 'g'), '') // replace non words
        .replace(new RegExp(separator + separator + '+', 'g'), separator) // replace multiple consecutive separators
        .replace(new RegExp('^' + separator + '+', 'g'), '') // Trim - from start of text
        .replace(new RegExp(separator + '+$', 'g'), ''); // Trim - from end of text
    }
  }

  return 'n_a';
};

export default slugify;
