import { BRAND_HAS_PAYWALL } from 'common/configuration/constants';
import { getConsentForGoogle } from 'common/consent/Didomi';
import { getConsentState } from 'common/consent/Tcf';
import loadGoogleAnalytics from 'common/third_party/googleanalytics';
import loadGoogleTagManager from 'common/third_party/googletagmanager';
import onUserAction from 'common/tools/ui/defer-on-user-action';
import { runAfterDeferAssets } from 'common/tools/ui/load-assets';
import {
  initForWebsite,
  initForSinglePageApp
} from 'common/tracking/listener/ga-init';

// This is in common folder even though it is dedicated to website pages
// Explanation to this is that the signup, signin and forgot password pages
// are technically in userspace scope but need this tracking (or maybe not?)
// todo fix that (those pages maybe do not belong to userspace)

function handleGA(userspace: boolean, settings: any) {
  runAfterDeferAssets(async () => {
    await loadGoogleAnalytics();
    if (userspace) {
      initForSinglePageApp(settings);
    } else {
      initForWebsite();
    }
  });
}

export default async function loadTracking(
  gtm: boolean,
  userspace: boolean,
  settings: any
) {
  // load Analytics after LOAD if possible, or on DOMREADY if LOAD is too long :

  if (BRAND_HAS_PAYWALL) {
    getConsentState().then(() => {
      getConsentForGoogle().then(okGoogle => {
        if (okGoogle) {
          handleGA(userspace, settings);
        }
      });

      if (gtm) {
        // load GTM tracking after load only (if noAds or noGtm isn't required)
        onUserAction(loadGoogleTagManager);
      }
    });
  } else {
    handleGA(userspace, settings);

    if (gtm) {
      // load GTM tracking after load only (if noAds or noGtm isn't required)
      onUserAction(loadGoogleTagManager);
    }
  }
}
