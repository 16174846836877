import { Action, AnyAction, createReducer } from '@reduxjs/toolkit';

import { getTheaterId } from 'common/api/SecureApi.helper';
import formatDate, { ISO_DATE } from 'common/tools/date/format';
import { updateObject } from 'common/tools/objects/update';

import * as actionTypes from 'website/constants/ActionTypes';

type ShowtimeItem = {
  hasBooking: boolean;
  movieEnd: string;
  movieStart: string;
  showStart: string;
  urlTicketing: string | null;
};

type ShowtimeData = {
  format: {
    id: number;
    name: string;
  };
  showtimes: ShowtimeItem[];
  version: string;
};

type ShowtimesData = Record<string, Record<number, Array<ShowtimeData>>>;

type ShowtimesPayload = Record<string, ShowtimesData | undefined>;

type DatePayload = { date: Date[] };

export type Choice = { label: string; value: number };
type Date = { label: string; value: string };
type Choices = {
  booking?: string[];
  card?: Choice[];
  date?: Date[];
  dolby?: string[];
  dolbycinema?: string[];
  eclaircolor?: string[];
  format?: Choice[];
  version?: Choice[];
};

type MoviePayload = {
  flags: {
    has_casting: boolean;
    isComingSoon: boolean;
  };
  id: number;
  poster: {
    added_at: {
      date: string;
      timezone: string;
      timezone_type: number;
    };
    copyright: string;
    description: string;
    file_type: string;
    id: number;
    id_type: number;
    path: string;
  };
  social: {
    fan_count: number;
    visit_count: number;
  };
  title: string;
  type: string;
};

type TheaterPayload = {
  address: {
    address: string;
    city: string;
    country: string;
    zip_code: string;
  };
  has_booking: boolean;
  id: number;
  id_ac: string;
  movies: string[];
  name: string;
  type: string;
};

type Pagination = {
  currentPage: number;
  includesFirst: boolean;
  includesLast: boolean;
  itemsPerPage: number;
  pageCount: number;
  pages: number[];
};

export type ShowtimesSuccessActionPayload = {
  booking?: boolean;
  card?: string;
  choices?: DatePayload;
  date?: string;
  dolby?: boolean;
  fakeResult?: Record<string, string>;
  format?: string;
  movies?: Record<string, MoviePayload>;
  pagination?: Pager;
  showtimes?: ShowtimesPayload;
  theaters?: Record<string, Theater>;
  version?: string;
};

export interface ShowtimesSuccessAction extends Action {
  choices?: Choices;
  movies?: Record<number, MoviePayload>;
  pagination?: Pagination;
  payload?: ShowtimesSuccessActionPayload;
  showtimes?: ShowtimesPayload;
  theaters?: Record<string, TheaterPayload>;
}

type Showtime = string[];
type Theater = Record<string, string>;
type Movie = Record<string, MoviePayload | undefined>;
type Pager = {
  currentPage: number;
  itemsPerPage: number;
  pages: number[];
  pageCount: number;
  includesFirst: boolean;
  includesLast: boolean;
};

export type ShowtimesState = {
  filters: {
    localization: string | null;
    date: string | null;
    version: string | null;
    format: string | null;
    card: string | null;
    booking: string | null;
    dolby: string | null;
    dolbycinema: string | null;
    eclaircolor: string | null;
    page: number;
  };
  choices: Choices;
  pager: Pager;
  showtimesByTheaters: ShowtimesPayload;
  showtimesDatePerMovie: Record<string, Showtime>;
  theaters: Record<string, Theater>;
  movies: Movie;
  userFavorites: string[];
  loading: boolean;
  searchMode: string;
};

const initialState: ShowtimesState = {
  filters: {
    localization: null,
    date: formatDate(new Date(), ISO_DATE),
    version: null,
    format: null,
    card: null,
    booking: null,
    dolby: null,
    dolbycinema: null,
    eclaircolor: null,
    page: 1
  },
  choices: {
    date: [],
    version: [],
    format: [],
    card: [],
    booking: [],
    dolby: [],
    dolbycinema: [],
    eclaircolor: []
  },
  pager: {
    currentPage: 1,
    itemsPerPage: 20,
    pages: [1],
    pageCount: 1,
    includesFirst: true,
    includesLast: true
  },
  showtimesByTheaters: {},
  showtimesDatePerMovie: {},
  theaters: {},
  movies: {},
  userFavorites: [],
  loading: false,
  searchMode: ''
};

const getDatesForMovie = (movieId: string, showtimes?: ShowtimesPayload) => {
  const datesForMovie: string[] = [];
  if (showtimes) {
    Object.keys(showtimes).forEach(theaterId => {
      const theaterShowtimes = showtimes[theaterId];
      if (theaterShowtimes) {
        Object.keys(theaterShowtimes).forEach(date => {
          if (Object.hasOwn(theaterShowtimes[date], movieId)) {
            datesForMovie.push(date);
          }
        });
      }
    });
  }
  return datesForMovie.filter(
    (value, index, self) => self.indexOf(value) === index
  );
};

const showtimeTheaterInitAll = (
  state: ShowtimesState,
  { theaters, showtimes }: AnyAction
) => {
  const movies: Movie = {};
  const showtimesByTheater: ShowtimesPayload = {};
  const theaterEntry: Record<string, Theater> = {};

  for (let index = 0; index < theaters.length; index++) {
    showtimesByTheater[theaters[index].id_ac] = showtimes[index];
    theaterEntry[theaters[index].id_ac] = theaters[index];
  }

  return updateObject(state, {
    showtimesByTheaters: showtimesByTheater,
    theaters: theaterEntry,
    movies: movies
  });
};

const loading = (state: ShowtimesState) =>
  updateObject(state, {
    loading: true
  });

const fetchShowtimes = (
  state: ShowtimesState,
  { payload }: ShowtimesSuccessAction
) => {
  const showtimesDatePerMovie: Record<string, Showtime> = {};
  const movies: Movie = {};
  const theatersWithShowtimes: Record<string, Theater> = {};
  let nextSelectedDate = state.filters.date ?? '';
  if (payload) {
    const {
      choices,
      fakeResult,
      movies: moviesPayload,
      showtimes,
      theaters
    } = payload;

    if (!moviesPayload && fakeResult) {
      return updateObject(state, {
        loading: false,
        showtimesByTheaters: {},
        theaters: {}
      });
    }
    for (const movieId of Object.keys(moviesPayload ?? {})) {
      movies[movieId] = moviesPayload?.[movieId];
      showtimesDatePerMovie[movieId] = getDatesForMovie(movieId, showtimes);
    }

    if (theaters) {
      for (const theaterId of Object.keys(theaters)) {
        if (typeof showtimes?.[theaterId] !== 'undefined') {
          theatersWithShowtimes[theaterId] = theaters[theaterId];
        }
      }
    }
    const avalaibleDates = choices?.date.map(e => e.value);
    if (avalaibleDates && avalaibleDates.indexOf(nextSelectedDate) === -1) {
      nextSelectedDate = avalaibleDates[0];
    }
  }
  return updateObject(state, {
    loading: false,
    theaters: theatersWithShowtimes,
    choices: payload?.choices,
    showtimesByTheaters: payload?.showtimes,
    showtimesDatePerMovie,
    pager: payload?.pagination,
    movies,
    filters: updateObject(state.filters, {
      page: payload?.pagination?.currentPage,
      date: nextSelectedDate
    })
  });
};

const loadingEnd = (state: ShowtimesState) =>
  updateObject(state, {
    loading: false
  });

const showtimeFiltersInit = (
  state: ShowtimesState,
  { values, searchMode, choices }: AnyAction
) =>
  updateObject(state, {
    filters: values,
    choices,
    searchMode
  });

const showtimeFiltersUpdate = (state: ShowtimesState, { payload }: AnyAction) =>
  updateObject(state, {
    filters: updateObject(state.filters, payload)
  });

const getUserTheaters = (state: ShowtimesState, action: AnyAction) => {
  const userFavorites: string[] = [];
  Object.keys(action.payload.actions).forEach(key => {
    const theater = action.payload.actions[key];
    const theaterId = getTheaterId(theater.targetKey);
    if (theaterId) {
      userFavorites.push(theaterId);
    }
  });
  return updateObject(state, {
    userFavorites
  });
};

const showtimePagerInit = (state: ShowtimesState, action: AnyAction) =>
  updateObject(state, {
    pager: action.values
  });

const showtimeSearchModeChange = (state: ShowtimesState, action: AnyAction) =>
  updateObject(state, {
    searchMode: action.searchMode
  });

export default createReducer(initialState, builder => {
  builder
    .addCase(actionTypes.FETCH_SHOWTIMES_FAILURE, loadingEnd)
    .addCase(actionTypes.FETCH_SHOWTIMES_REQUEST, loading)
    .addCase(actionTypes.FETCH_SHOWTIMES_SUCCESS, fetchShowtimes)
    .addCase(actionTypes.GET_USER_THEATERS_SUCCESS, getUserTheaters)
    .addCase(actionTypes.SHOWTIME_FILTERS_INIT, showtimeFiltersInit)
    .addCase(actionTypes.SHOWTIME_FILTERS_UPDATE, showtimeFiltersUpdate)
    .addCase(actionTypes.SHOWTIME_PAGER_INIT, showtimePagerInit)
    .addCase(actionTypes.SHOWTIME_SEARCH_MODE_CHANGE, showtimeSearchModeChange)
    .addCase(actionTypes.SHOWTIME_THEATER_INIT_ALL, showtimeTheaterInitAll);
});
