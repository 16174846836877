import React from 'react';
import { createRoot } from 'react-dom/client';

import { isSize } from 'common/components/UserRating';
import { isSocialActionPlacements } from 'common/constants/trackingEventsNames';
import readAttribute, {
  readBooleanAttribute
} from 'common/tools/dom/readAttribute';
import trans from 'common/tools/translations/trans';

import ratingBoosterHoc from 'website/components/Tooltip/ratingBooster';
import StandaloneRating, {
  StandaloneRatingProps
} from 'website/components/push/Ratings/StandaloneRating';
import allocineContainer from 'website/containers/allocineContainer';

export default function userRatings() {
  const anchors = document.getElementsByClassName('js-user-rating');
  if (anchors.length) {
    for (const anchor of anchors) {
      const entityId = readAttribute<string, undefined>(
        anchor,
        'data-entity-id'
      );
      if (entityId) {
        const size = readAttribute<string, undefined>(anchor, 'data-size');
        const title = readAttribute<string, undefined>(anchor, 'data-title');
        const showSuggestion = readBooleanAttribute(
          anchor,
          'data-show-suggestion'
        );
        const socialActionPlacement =
          readAttribute<string, undefined>(
            anchor,
            'data-social-action-placement'
          ) || 'standalone';
        const showTitle = title !== null;

        const ConnectedStandaloneRating =
          allocineContainer<StandaloneRatingProps>(
            ratingBoosterHoc(
              StandaloneRating,
              anchor,
              'js-tooltip-rating-booster',
              <span>{trans('tooltip-booster.rating')}</span>
            )
          );

        const root = createRoot(anchor);
        root.render(
          <ConnectedStandaloneRating
            entityId={entityId}
            size={isSize(size) ? size : undefined}
            title={title}
            showTitle={showTitle}
            socialActionPlacement={
              isSocialActionPlacements(socialActionPlacement)
                ? socialActionPlacement
                : undefined
            }
            withSuggestions={showSuggestion}
          />
        );
      }
    }
  }
}
