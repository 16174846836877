import { hit } from 'common/tracking/googleanalytics';
import socialActionTracking from 'common/tracking/socialActionTracking';

export const trackUpdateCollection = () => {
  hit(
    {
      eventCategory: 'social',
      eventLabel: 'collections',
      eventAction: 'edit'
    },
    true
  );
};

export const trackAddCollection = () => {
  hit(
    {
      eventCategory: 'social',
      eventLabel: 'collections',
      eventAction: 'add'
    },
    true
  );
};

export const trackFollowCollection = () => {
  hit(
    {
      eventCategory: 'social',
      eventLabel: 'collections',
      eventAction: 'follow'
    },
    true
  );
};

export const trackUnfollowCollection = () => {
  hit(
    {
      eventCategory: 'social',
      eventLabel: 'collections',
      eventAction: 'unfollow'
    },
    true
  );
};

export const trackShareCollection = () => {
  hit(
    {
      eventCategory: 'social',
      eventLabel: 'collections',
      eventAction: 'share'
    },
    true
  );
};

export const trackDeleteCollection = () => {
  hit(
    {
      eventCategory: 'social',
      eventLabel: 'collections',
      eventAction: 'remove'
    },
    true
  );
};

export const trackAddCollectionEntities = () => {
  hit(
    {
      eventCategory: 'social',
      eventLabel: 'collections',
      eventAction: 'add_entities'
    },
    true
  );

  socialActionTracking('collection', 'add');
};

export const trackRemoveCollectionEntities = () => {
  hit(
    {
      eventCategory: 'social',
      eventLabel: 'collections',
      eventAction: 'remove_entities'
    },
    true
  );

  socialActionTracking('collection', 'delete');
};
