import React, { FC, ReactNode } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import tooltipBoosterHoc, {
  TooltipBoosterHocProps
} from 'website/components/Tooltip/tooltipBooster';
import { DataState } from 'website/reducers/data';
import { getOpinionForEntity } from 'website/reducers/data/helper';

type PropsFromRedux = ConnectedProps<typeof connector>;

type ComponentProps = TooltipBoosterHocProps &
  PropsFromRedux & {
    rating?: number | null;
  };

// prevents the display of the tooltip if we disable the button rating
let hideRatingBooster = false;

const mapStateToProps = (
  state: { data: DataState },
  ownProps: { entityId: string }
) => {
  const opinion = getOpinionForEntity(ownProps.entityId, state.data);
  return {
    rating: opinion ? opinion.rating : null
  };
};

const connector = connect(mapStateToProps);

/**
 * this HOC will define the display
 * conditions of the tooltip booster for the
 * rating section only
 *
 * @param {Component} WrappedComponent  a component
 * @param {Element}   anchor            dom anchor of wrapped component
 * @param {String}    boosterClass      className to display tooltip
 * @param {Component} BoosterContent    tooltip content
 * @returns {Component} a component
 */
const ratingBoosterHoc = (
  WrappedComponent: FC<Omit<ComponentProps, 'rating'>>,
  anchor: Element,
  boosterClass: string,
  BoosterContent: ReactNode
) =>
  connector((props: ComponentProps) => {
    const { rating, ...rest } = props;

    // if the tooltip is already hidden or if the movie is already rated
    if (hideRatingBooster || rating) {
      // prevents the display of the tooltip if we disable the button rating
      hideRatingBooster = true;
      // displays the button without the tooltip booster hoc
      return <WrappedComponent {...rest} />;
    }

    const TooltipBoosterComponent = tooltipBoosterHoc(
      WrappedComponent,
      anchor,
      boosterClass,
      BoosterContent
    );

    return <TooltipBoosterComponent {...props} />;
  });

export default ratingBoosterHoc;
