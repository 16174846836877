/* global dataLayer */
import Cookies from 'cookies-js';
import loadScript from 'load-script';

import { GOOGLE_TAG_MANAGER_ID } from 'common/configuration/constants';
import {
  GEOCODE_COUNTRY_CODE,
  USERID_COOKIE
} from 'common/constants/CookieNames';
import eventEmitter, { EventsTypes } from 'common/services/events/eventEmitter';
import { hasAdblock } from 'common/tools/adblock';
import { noGtm } from 'common/tools/ads';
import { isProd } from 'common/tools/env';

const GOOGLE_TAG_MANAGER_URL = `//www.googletagmanager.com/gtm.js?id=${GOOGLE_TAG_MANAGER_ID}`;

let loaded = false;

/**
 * This module / function allows the dynamic loading of google client
 * javascript library
 * @param  {Function} callback - the function to execute once google client api is loaded
 * @param  {Object}   options  - options for the loading of the script
 * @return {void}
 */
export default function (callback = null, options = {}) {
  if (loaded && typeof callback === 'function') {
    callback();
    return false;
  }

  // Sometimes the GEOLOCATION_AVAILABLE event fires before we event listen to it.
  if (Cookies.get(GEOCODE_COUNTRY_CODE)) {
    loadGoogleTagManager(callback, options);
  } else {
    eventEmitter.on(
      EventsTypes.GEOLOCATION_AVAILABLE,
      loadGoogleTagManager.bind(null, callback, options)
    );
  }
}

/**
 * The actual loading of the google client, called once cookies are set.
 * @param  {Function} callback - the function to execute once google client api is loaded
 * @param  {Object}   options  - options for the loading of the script
 * @return {void}
 */
function loadGoogleTagManager(callback = null, options = {}) {
  // Used by CIM for Belgium.
  if (dataLayer) {
    if (!dataLayer[0]) {
      dataLayer[0] = {};
    }

    dataLayer[0].current_url = document.location.href;
    dataLayer[0].visitor_country = Cookies.get(GEOCODE_COUNTRY_CODE);
    dataLayer[0].user_id = Cookies.get(USERID_COOKIE) || null;

    dataLayer.push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });

    dataLayer.push({ isAdblock: hasAdblock() });
  }

  const onScriptLoaded = () => {
    loaded = true;
    if (typeof callback === 'function') {
      callback();
    }
  };

  if (isProd() && !noGtm()) {
    loadScript(GOOGLE_TAG_MANAGER_URL, options, onScriptLoaded);
  } else {
    // eslint-disable-next-line no-console
    console.warn('GTM is disabled in development mode');
    onScriptLoaded();
  }
}
