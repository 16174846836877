/*
 * roller.js
 *
 * This module is used to scroll over several elements in a row.
 * The needed dom can be found below :
 *
 * <div class="xxx-class-roller roller-holder js-roller">
 *     <div class="roller-overflow">
 *         <div class="roller-slider">
 *             <div class="roller-item"></div>
 *             <div class="roller-item"></div>
 *             <div class="roller-item"></div>
 *             <div class="roller-item"></div>
 *             <div class="roller-item"></div>
 *             <div class="roller-item"></div>
 *         </div>
 *     </div>
 *     <div class="roller-btn-holder">
 *         <button class="roller-btn btn-left icon icon-arrow-left"></button>
 *         <button class="roller-btn btn-right icon icon-arrow-right"></button>
 *     </div>
 * </div>
 *
 * used on : view/default/section/homepage/movie-this-week.html.twig
 *
 */
import eventEmitter, { EventsTypes } from 'common/services/events/eventEmitter';
import { addClass, hasClass, removeClass } from 'common/tools/dom/classes';
import * as events from 'common/tools/dom/events';
import { readNumberAttribute } from 'common/tools/dom/readAttribute';
import scrollTo from 'common/tools/dom/scrollTo';
import debounce from 'common/tools/functions/debounce';

type RollerEvent = {
  [key: string]: { [key: string]: () => void };
};

type Config = {
  parent: Element;
  slider: Element;
  btnLeft: Element | null;
  btnRight: Element | null;
  leftBound: number;
  rightBound: number;
  disabledLeft: boolean;
  disabledRight: boolean;
  hideArrow: boolean;
  scrollBound: number;
  scrollTarget: number;
  sliderScroll: number;
  containerWidth: number;
  itemWidth: number;
  offsetMargin: number;
};

let initialize = false;

let bodyWidth = window.document.body.offsetWidth; // referer, used to check if window width change on resize (chrome nav bar issue on mobile)
const rollerEvent: RollerEvent = {};
let rollerCount = 0;
let resizeSetup = false;

const getScrollTarget = (config: Config) => {
  const target = config.scrollTarget;
  const margin = config.offsetMargin;

  if (target >= config.rightBound - margin) {
    config.disabledRight = true;
    return config.rightBound;
  }

  if (target <= config.leftBound + margin) {
    config.disabledLeft = true;
    return config.leftBound;
  }

  return target;
};

const getScrollConfig = (parent: Element, slider: Element): Config => {
  const containerWidth = parent
    .getElementsByClassName('roller-overflow')[0]
    .getBoundingClientRect().width;
  const offsetMargin = readNumberAttribute(parent, 'data-offset');
  const sliderScroll = slider.scrollWidth;
  const itemWidth =
    parent.getElementsByClassName('roller-item')[0].getBoundingClientRect()
      .width + offsetMargin;
  const itemModulo = containerWidth % itemWidth;
  const scrollBound =
    containerWidth - itemModulo < itemWidth
      ? containerWidth
      : containerWidth - itemModulo;
  const rightBound = sliderScroll - containerWidth;

  return {
    parent: parent,
    slider: slider,
    btnLeft: parent.querySelector('.btn-left'),
    btnRight: parent.querySelector('.btn-right'),
    leftBound: 0,
    rightBound: rightBound,
    disabledLeft: true,
    disabledRight: false,
    hideArrow: rightBound <= 1,
    scrollBound: scrollBound,
    scrollTarget: 0,
    sliderScroll: sliderScroll,
    containerWidth: containerWidth,
    itemWidth: itemWidth,
    offsetMargin: offsetMargin
  };
};

const displayNavArrow = (config: Config, roller: Element) => {
  if (config.hideArrow) {
    addClass(roller, 'roller-no-nav');
  } else {
    removeClass(roller, 'roller-no-nav');
  }
};

const handleNavArrow = (config: Config) => {
  const btnLeft = config.btnLeft;
  const btnRight = config.btnRight;
  const scrollLeft = config.slider.scrollLeft;
  const rightBound = config.rightBound;

  if (scrollLeft === 0) {
    config.disabledLeft = true;
    if (btnLeft) addClass(btnLeft, 'disabled');
  }

  if (scrollLeft > 0) {
    config.disabledLeft = false;
    if (btnLeft) removeClass(btnLeft, 'disabled');
  }

  if (scrollLeft >= rightBound - 1) {
    config.disabledRight = true;
    if (btnRight) addClass(btnRight, 'disabled');
  } else {
    config.disabledRight = false;
    if (btnRight) removeClass(btnRight, 'disabled');
  }
};

const handleScroll = (config: Config) => {
  const start = config.slider.scrollLeft;
  const target = getScrollTarget(config);
  scrollTo(target, 500, false, false, config.slider, 'left', start);
};

export const handleCurrent = (parent: Element) => {
  const slider = parent.querySelector('.roller-slider');
  const items = parent.querySelectorAll('.roller-item');

  if (!slider || !items.length) {
    return;
  }

  const config = getScrollConfig(parent, slider);

  let i;
  const l = items.length;

  for (i = 0; i < l; i++) {
    if (hasClass(items[i], 'current')) {
      const itemLeft = config.itemWidth * i;
      const itemRight = config.itemWidth * i + config.itemWidth;
      const limitLeft = slider.scrollLeft;
      const limitRight = limitLeft + config.containerWidth;

      if (
        itemRight > limitRight ||
        (limitLeft === 0 && itemRight > config.containerWidth)
      ) {
        config.scrollTarget = itemLeft;
      } else if (itemLeft < limitLeft) {
        config.scrollTarget =
          itemLeft + config.itemWidth - config.containerWidth;
      } else {
        return false;
      }

      handleScroll(config);
    }
  }
};

const handleDirection = (direction: number, config: Config) => {
  switch (direction) {
    case +1:
      if (config.disabledRight) {
        return;
      }
      config.scrollTarget = config.slider.scrollLeft + config.scrollBound;
      break;
    case -1:
      if (config.disabledLeft) {
        return;
      }
      config.scrollTarget = config.slider.scrollLeft - config.scrollBound;
      break;
    default:
      return;
  }

  handleScroll(config);
  handleNavArrow(config);
};

const scrollCallback = (config: Config) => {
  eventEmitter.emit(EventsTypes.LAZY_REVALIDATE);
  handleNavArrow(config);
};

export const scrollOn = (parent: Element, forceScroll?: boolean) => {
  if (parent.id === '') {
    rollerCount += 1;
    parent.id = `roller-${rollerCount}`;
  }

  const slider = parent.querySelector('.roller-slider');
  const items = parent.querySelectorAll('.roller-item');

  if (!items.length || !slider) {
    addClass(parent, 'roller-no-nav');
    return null;
  }

  // make sure scrollLeft is to 0 at first render.
  slider.scrollLeft = 0;

  const config = getScrollConfig(parent, slider);

  displayNavArrow(config, parent);
  handleNavArrow(config);

  // scroll to current item if any
  handleCurrent(parent);

  // remove events in case of reset :
  if (typeof rollerEvent[parent.id] !== 'undefined') {
    if (config.btnLeft) {
      events.off(config.btnLeft, 'click', rollerEvent[parent.id].goLeft);
    }
    if (config.btnRight) {
      events.off(config.btnRight, 'click', rollerEvent[parent.id].goRight);
    }

    events.off(slider, 'scroll', rollerEvent[parent.id].scroll);
  }

  // push reference event in rollerEvent object for each roller :
  rollerEvent[parent.id] = {
    goLeft: handleDirection.bind(null, -1, config),
    goRight: handleDirection.bind(null, +1, config),
    scroll: debounce(() => scrollCallback(config))
  };

  // add events for slider :
  if (config.btnLeft) {
    events.on(config.btnLeft, 'click', rollerEvent[parent.id].goLeft);
  }

  if (config.btnRight) {
    events.on(config.btnRight, 'click', rollerEvent[parent.id].goRight);
  }

  events.on(slider, 'scroll', rollerEvent[parent.id].scroll);

  if (forceScroll) {
    rollerEvent[parent.id].goRight();
  }
};

const startRollers = (rollerElements: HTMLCollectionOf<Element>) => {
  for (const roller of rollerElements) {
    scrollOn(roller);
    handleCurrent(roller);
  }
};

const createRollers = (
  rollerElements: HTMLCollectionOf<Element>,
  refreshRollerList = false
) => {
  let rollers = rollerElements || [];

  if (refreshRollerList) {
    rollers = document.getElementsByClassName('js-roller');
  }

  if (!rollers.length) {
    return;
  }

  startRollers(rollers);

  if (resizeSetup) {
    return;
  }

  events.on(
    window,
    'resize',
    debounce(() => {
      // Check if window width changes on resize because of Chrome mobile nav bar who hides/shows on scroll,
      // and triggers a resize.
      if (window.document.body.offsetWidth === bodyWidth) {
        return;
      }

      startRollers(rollers);

      bodyWidth = window.document.body.offsetWidth;
    })
  );

  resizeSetup = true;
};

export default async (revalidate = false) => {
  if (!initialize) {
    initialize = true;

    const rollerElements = document.getElementsByClassName('js-roller');

    eventEmitter.on(EventsTypes.ROLLER_REVALIDATE, () => {
      /*
      Some rollers may arrive at the dom later (react created ones)
      so force an init with refresh
    */
      createRollers(rollerElements, true);
    });

    eventEmitter.on(EventsTypes.ROLLER_GETCURRENT_ITEM, (roller: Element) => {
      handleCurrent(roller);
    });

    if (!rollerElements.length) {
      return;
    }

    createRollers(rollerElements);
  }
  if (revalidate) {
    eventEmitter.emit(EventsTypes.ROLLER_REVALIDATE);
  }
};
