/**
 * Adblocker detection, based on https://www.christianheilmann.com/2015/12/25/detecting-adblock-without-an-extra-http-overhead/
 *
 * Basically we have on the DOM a div with a class ('easyAdsBox') that we KNOW will get hidden by adblockers
 * so all we have to do is check the height of this div, and if it is 0 it will mean
 * that an adblock is present
 */
import 'common/tools/polyfills/Element.remove';

let previousRun: boolean | null = null;
export function hasAdblock() {
  if (previousRun) {
    // if we already detected an adblock, return this value now
    return previousRun;
  }
  let bait = document.querySelector('.easyAdsBox');
  let baitHidden = false;
  if (bait && bait instanceof HTMLElement) {
    baitHidden = bait.offsetHeight === 0;
    bait.remove();
    bait = null;
  }
  previousRun = baitHidden;
  return baitHidden || isGoogleAnalyticsObjectSpoofed();
}

/**
 * check that the ga object is present
 */
function hasGoogleAnalyticsObject() {
  return (
    typeof window.ga === 'function' &&
    typeof window.ga.create === 'function' &&
    typeof window.ga.create.toString === 'function'
  );
}

/**
 * Detect spoofed GA made by uBlock
 */
function isGoogleAnalyticsObjectSpoofed() {
  return (
    hasGoogleAnalyticsObject() &&
    Function.prototype.toString.call(window.ga.create).match('Tracker') !== null
  );
}
