export default function elementPartiallyVisible(domElement, offset = 0) {
  const containerCoords = domElement.getBoundingClientRect();
  const viewportBottom =
    window.innerHeight || document.documentElement.clientHeight;

  if (
    !containerCoords.top &&
    !containerCoords.left &&
    !containerCoords.width &&
    !containerCoords.height
  ) {
    return false; // means that the element isn't shown problably because of adblock
  }

  return (
    (containerCoords.top >= 0 &&
      containerCoords.top - offset <= viewportBottom) || // upper bound is in viewtop
    (containerCoords.top <= 0 &&
      containerCoords.bottom + offset <= viewportBottom) // lower bound is in viewtop
  );
}
