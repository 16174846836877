import 'core-js';

import { addEntities } from 'website/actions/EntityActions';
import { initialize as initializeGeocoding } from 'website/api/Geocoding';
import { registerEntityForSocialInteractions } from 'website/bootstrap/socialActionsBootstrap';
import initConnectionEvents from 'website/containers/user/connection';
// @babel/preset-env will transform that into a set of relevant imports
import pageCommon from 'website/page-common';
import { updateUserProfile } from 'website/services/authentication';
import store from 'website/store/';

/**
 * This function must ideally be the first one present and called in universes pages
 * It takes care of bootstraping the application and
 * handles the passive login
 *
 * @return {void}
 */
export default async function bootstrap() {
  await pageCommon();

  // react to user events
  initConnectionEvents();

  if (window.jsEntities) {
    Object.keys(window.jsEntities).forEach(jsEntityId => {
      registerEntityForSocialInteractions(jsEntityId);
    });
  }

  updateUserProfile();

  if (window.jsEntities) {
    store.dispatch(addEntities(window.jsEntities));
  }

  initializeGeocoding();
}
