import { hit } from 'common/tracking/googleanalytics';

export const trackPromoteSignup = () => {
  hit(
    {
      eventCategory: 'recommandation_signup',
      eventLabel: 'clic',
      eventAction: 'pop_up'
    },
    true
  );
};
