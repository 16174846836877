export const MQ_XSMALL = 1;
export const MQ_SMALL = 2;
export const MQ_MEDIUM = 3;
export const MQ_LARGE = 4;
export const MQ_XLARGE = 5;
export const MQ_XXLARGE = 6;
export const MQ_XXXLARGE = 7;

export const MQ_STATES = [
  MQ_XSMALL,
  MQ_SMALL,
  MQ_MEDIUM,
  MQ_LARGE,
  MQ_XLARGE,
  MQ_XXLARGE,
  MQ_XXXLARGE
];
