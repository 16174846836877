import * as actionTypes from 'website/constants/ActionTypes';
import { JsEntity } from 'website/reducers/data/types';

type NormalizedObject = {
  [key: string]: JsEntity;
};

export const addEntities = (normalizedObject: NormalizedObject) => ({
  type: actionTypes.ADD_ENTITIES,
  payload: normalizedObject
});
