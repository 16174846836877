import { once as onceEvent } from 'common/tools/dom/events';
import once from 'common/tools/functions/once';
import { addToRicThread } from 'common/tools/ui/request-idle-cb';

// Use to start some feature after user action on the window. (kind of lazyload the lazyload for example)
const actions = [
  'mouseover',
  'mousewheel',
  'scroll',
  'keydown',
  'focus',
  'click'
];

// Using a "double once" because we want "callback" to be called on the first fire of any of the above events.
export default callback => {
  const callbackOnce = once(e => {
    if (e) {
      e.stopPropagation();
    }

    addToRicThread(callback);
  });

  actions.forEach(action => {
    onceEvent(window, action, callbackOnce);
  });
};
