// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".K60dgQ6yXxfylWJryu4q{overflow:hidden;height:100%}.K60dgQ6yXxfylWJryu4q body{overflow:hidden}.K60dgQ6yXxfylWJryu4q.hhmvCixQhH508Cj62Nen body{padding-right:1rem}.K60dgQ6yXxfylWJryu4q body{height:100%}.K60dgQ6yXxfylWJryu4q .G29TrvNw2IGbQiVwknlW{display:block}.K60dgQ6yXxfylWJryu4q .header-main{z-index:0}.G29TrvNw2IGbQiVwknlW,.Ecpr1rhQlFsTZzDnMZTl{top:0;right:0;left:0;overflow:hidden}.G29TrvNw2IGbQiVwknlW{position:fixed;z-index:2147483647;bottom:-1px;display:none;width:100%;background:#000;-ms-touch-action:none}.Ecpr1rhQlFsTZzDnMZTl{position:absolute;bottom:0;overflow:auto;width:100%;height:100%;padding-top:2rem;-webkit-overflow-scrolling:touch}.CasV6mVN5t6V2KcQkYxD{position:absolute;top:.3125rem;right:.3125rem;display:block;cursor:pointer;color:#fff}.xkAk0dMmBkMmVDJ8lpBq body{overflow:hidden}.xkAk0dMmBkMmVDJ8lpBq.hhmvCixQhH508Cj62Nen body{padding-right:1rem}.xkAk0dMmBkMmVDJ8lpBq body:after{position:fixed;z-index:101;top:0;right:0;left:0;display:block;width:100%;height:150vh;content:\"\";background:rgba(0,0,0,.8)}.xkAk0dMmBkMmVDJ8lpBq .G29TrvNw2IGbQiVwknlW{display:block;background-color:rgba(0,0,0,0)}.xkAk0dMmBkMmVDJ8lpBq .main-header{z-index:0}.xkAk0dMmBkMmVDJ8lpBq .Ecpr1rhQlFsTZzDnMZTl{padding-top:0}.xkAk0dMmBkMmVDJ8lpBq .CasV6mVN5t6V2KcQkYxD{display:none}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"openOverlay": "K60dgQ6yXxfylWJryu4q",
	"noTouch": "hhmvCixQhH508Cj62Nen",
	"overlay": "G29TrvNw2IGbQiVwknlW",
	"overlayContent": "Ecpr1rhQlFsTZzDnMZTl",
	"overlayClose": "CasV6mVN5t6V2KcQkYxD",
	"openModal": "xkAk0dMmBkMmVDJ8lpBq"
};
export default ___CSS_LOADER_EXPORT___;
