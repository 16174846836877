import { Experience, Projection, Sound } from 'types/graphql-api.generated';

import { ROUTE } from 'common/configuration/constants';
import {
  ReservationTrackingMovie,
  ReservationTrackingShowtime,
  ReservationTrackingTheater,
  TheaterReservationTracking
} from 'common/constants/trackingEventsNames';
import { getNextWeekStartTime } from 'common/tools/date/showtime';
import getShowtimeTechno from 'common/tools/showtime/getShowtimesTechno';
import getTicketingUrl from 'common/tools/showtime/getTicketingUrl';
import pad from 'common/tools/string/pad';
import slugify from 'common/tools/string/slugify';
import { hit } from 'common/tracking/googleanalytics';
import {
  SHOWTIME_TYPE_NO_TICKETING,
  SHOWTIME_TYPE_PREMIERE,
  SHOWTIME_TYPE_PRESALE,
  SHOWTIME_TYPE_STANDARD
} from 'common/tracking/helpers';
import { GA4Entity } from 'common/tracking/helpers';
import { hit as hit_jan } from 'common/tracking/jan';
import { EventData } from 'common/tracking/types';

const getTime = (dateString: string | number | Date) => {
  const date = new Date(dateString);
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hours = date.getHours();
  const minutes = date.getMinutes();

  return `${date.getFullYear()}-${pad(month)}-${pad(day)}-${pad(hours)}-${pad(
    minutes
  )}`;
};

const getBookingType = (showtime?: ReservationTrackingShowtime) => {
  if (!getTicketingUrl(showtime?.data?.ticketing))
    return SHOWTIME_TYPE_NO_TICKETING;
  if (showtime?.isPreview) return SHOWTIME_TYPE_PREMIERE;
  const time = new Date(showtime?.startsAt).getTime();
  if (time > getNextWeekStartTime()) return SHOWTIME_TYPE_PRESALE;

  return SHOWTIME_TYPE_STANDARD;
};

export const getReservationTracking = (
  showtime?: ReservationTrackingShowtime,
  theater?: ReservationTrackingTheater,
  movie?: ReservationTrackingMovie
) => {
  const trackingData: EventData = {
    dimension10: ROUTE,
    dimension84: 'movie'
  };

  if (showtime) {
    trackingData.dimension5 = getTime(showtime.startsAt);
    trackingData.dimension65 = showtime.diffusionVersion?.toLowerCase();
    trackingData.dimension66 = getShowtimeTechno(showtime)
      .join('|')
      .toLowerCase();
    trackingData.dimension69 = getBookingType(showtime);
  }

  if (theater) {
    trackingData.eventLabel = `${theater.internalId}-${slugify(theater.name)}`;
    trackingData.dimension6 = theater.theaterCircuits
      ? `${theater.theaterCircuits.internalId}-${slugify(
          theater.theaterCircuits.name
        )}`
      : '81007-independant';
    trackingData.dimension29 = slugify(theater.location?.city);
    trackingData.dimension30 = slugify(theater.location?.region);
  }

  if (movie) {
    trackingData.dimension82 = `${movie.internalId}-${slugify(movie.title)}`;
  }

  return trackingData;
};

export const getReservationTrackingGA4 = (
  showtime?: ReservationTrackingShowtime,
  theater?: ReservationTrackingTheater,
  movie?: ReservationTrackingMovie,
  fullTracking = false
) => {
  let trackingData: Record<string, any> = fullTracking
    ? {}
    : {
        geolocated: false
      };

  if (theater) {
    trackingData = {
      ...trackingData,
      theater_city: slugify(theater.location?.city),
      theater_entity: GA4Entity(theater.internalId, theater.name),
      theater_region: slugify(theater.location?.region),
      theater_type: theater.theaterCircuits
        ? GA4Entity(
            theater.theaterCircuits.internalId,
            theater.theaterCircuits.name
          )
        : '81007|independant'
    };
    if (fullTracking) {
      trackingData.theater_id = theater.internalId;
      trackingData.theater_name = slugify(theater.name);
      trackingData.theater_zip_code = theater.location?.zip;
    } else {
      trackingData.geolocated = true;
    }
  }

  if (showtime) {
    trackingData.showtime_date_hour = showtime.startsAt
      ? getTime(showtime.startsAt)
      : 'undefined';

    if (fullTracking) {
      const techno = getShowtimeTechno(showtime);

      trackingData.showtimes_techno = techno.join('|').toLowerCase();
      // linked to the icons displayed below the showtimes
      // SHOWTIMES_EXPERIENCES_ICONS_CLASSES
      // (app/Resources/src-front/js/app/common/constants/Showtimes.ts)
      trackingData.has_dolby = [
        Experience.DolbyAtmos,
        Experience.DolbyCinema,
        Sound.Atmos
      ].some(experience => techno.includes(experience));
      trackingData.has_imax = [
        Projection.Imax,
        Projection.Imax_3D,
        Projection.Imax_70Mm
      ].some(projection => techno.includes(projection));

      trackingData.theater_is_allocine_vad = !!getTicketingUrl(
        showtime.data?.ticketing
      );
    }
  }

  if (movie) {
    trackingData.movie_entity = GA4Entity(movie.internalId, movie.title);
    if (fullTracking) {
      trackingData.movie_id = movie.internalId;
      trackingData.movie_title = slugify(movie.title);
      trackingData.movie_genres = movie.genres?.join('|').toLowerCase();
    }
  }

  return trackingData;
};

const SHOWTIMES_BUTTON_CLICK = 'showtimes_button_click';

const trackReservationClick = ({
  showtime,
  theater,
  movie,
  eventData,
  ga4event,
  ga4data,
  ga4dataOverwrite
}: TheaterReservationTracking) => {
  hit({
    ...(eventData ?? {}),
    // Reservations via operations assign an event category
    // "ticket_office", which must not be replaced by "book_button".
    eventCategory:
      eventData?.eventCategory === 'ticket_office'
        ? 'ticket_office'
        : 'book_button',
    eventAction: 'clic',
    ...getReservationTracking(showtime, theater, movie)
  });

  const ga4EventName = ga4event ?? SHOWTIMES_BUTTON_CLICK;

  hit_jan(ga4EventName, {
    ...(ga4data ?? {}),
    ...getReservationTrackingGA4(
      showtime,
      theater,
      movie,
      ga4EventName === SHOWTIMES_BUTTON_CLICK
    ),
    ...(ga4dataOverwrite ?? {})
  });
};

export const trackReservationImpression = ({
  showtime,
  theater,
  movie,
  eventData,
  ga4dataOverwrite
}: TheaterReservationTracking) => {
  hit(
    {
      ...(eventData ?? {}),
      eventAction: 'impression',
      eventCategory: 'modale_showtimes',
      ...getReservationTracking(showtime, theater, movie)
    },
    true
  );

  hit_jan('showtimes_modal_open', {
    ...getReservationTrackingGA4(showtime, theater, movie, true),
    ...(ga4dataOverwrite ?? {})
  });
};

export default trackReservationClick;
