import { Maybe, Showtime } from 'types/graphql-api.generated';

export type ShowtimesExperiences = Pick<
  Showtime,
  'experience' | 'projection' | 'picture' | 'sound'
>;
const getShowtimeTechno = (showtime?: Maybe<ShowtimesExperiences>) =>
  [
    ...(showtime?.projection ?? []),
    ...(showtime?.picture ?? []),
    ...(showtime?.sound ?? []),
    ...(showtime?.experience ?? [])
  ].reduce<string[]>(
    (acc, exp) => (exp && !acc.includes(exp) ? [...acc, exp] : acc),
    []
  );

export default getShowtimeTechno;
