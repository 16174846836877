import React, {
  useState,
  useEffect,
  useRef,
  ReactNode,
  InputHTMLAttributes,
  ChangeEvent,
  RefObject,
  createRef,
  ReactElement
} from 'react';

import {
  Item,
  OnSearchTermChange,
  RenderAutocompleteItem
} from 'common/components/ui/types';
import noop from 'common/tools/noop';

/**
 * based on https://github.com/rackt/react-autocomplete
 */

type Props<T> = {
  initialValue?: string;
  onChange?: OnSearchTermChange;
  onSelect?: (
    value?: string | null,
    item?: Item,
    index?: number | null
  ) => void;
  onEnter: (value?: string | null, item?: Item) => void;
  renderItem: RenderAutocompleteItem<T>;
  inputProps?: InputHTMLAttributes<HTMLInputElement>;
  renderMenu: <R>(items: T[], props: R) => ReactElement<R> | null;
  getItemValue: (item?: Item) => string | null | undefined;
  items?: Item[] | null;
  focusOnFirstRender?: boolean;
  keepOpen?: boolean;
};

const Autocomplete = <T extends ReactNode>({
  inputProps = {},
  initialValue = '',
  onChange = noop,
  onSelect = noop,
  renderMenu = (items, props) => <div {...props}>{items}</div>,
  items = [],
  renderItem,
  focusOnFirstRender = false,
  keepOpen = false,
  getItemValue,
  onEnter
}: Props<T>) => {
  const [value, setValue] = useState<string | null | undefined>(initialValue);
  const [isOpen, setIsOpen] = useState(false);
  const [highlightedIndex, setHighlightedIndex] = useState<number | null>(null);

  const _ignoreBlur = useRef(false);
  const _performAutoCompleteOnKeyUp = useRef(false);
  const _keepOpenAutocomplete = useRef(false);

  const itemsRefs: { [key: string]: RefObject<HTMLDivElement> } = {};
  const inputRef = createRef<HTMLInputElement>();
  const menuRef = createRef<HTMLDivElement>();

  useEffect(() => {
    if (focusOnFirstRender) {
      inputRef.current?.focus();
    }
    if (initialValue) {
      onChange(null, initialValue);
    }
    _ignoreBlur.current = false;
    _performAutoCompleteOnKeyUp.current = false;
  }, []);

  useEffect(() => {
    if (isOpen && highlightedIndex !== null) {
      const itemNode = itemsRefs[`item-${highlightedIndex}`].current;
      if (itemNode) {
        itemNode.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'start'
        });
      }
    }
  }, [isOpen, highlightedIndex, value]);

  const isIgnoreBlur = () => {
    if (_ignoreBlur.current) {
      return true;
    }

    // check if the menu has to stay open
    // after a mouse event on it
    if (keepOpen && _keepOpenAutocomplete.current) {
      // this bool is turn "true" when a mouse down is trigger on the menu only
      // we don't keep this state for the next check
      _keepOpenAutocomplete.current = false;

      // we need to keep the focus on input to listen to the trigger event "blur" for the next check
      inputRef.current?.focus();
      return true;
    }

    return false;
  };

  const highlightItemFromMouse = (index: number) => {
    setHighlightedIndex(index);
  };

  const selectItemFromMouse = (item: Item) => {
    const index = highlightedIndex;
    const newValue = getItemValue(item);
    setValue(newValue);
    setIsOpen(false);
    setHighlightedIndex(null);
    onSelect(newValue, item, index);
    inputRef.current?.focus();
    _ignoreBlur.current = false;
  };

  const getActiveItemValue = () => {
    if (highlightedIndex === null || !items) {
      return inputProps.placeholder || '';
    }
    const item = items[highlightedIndex];
    return item ? getItemValue(item) ?? undefined : '';
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.persist();
    _performAutoCompleteOnKeyUp.current = true;
    setValue(event.target.value);
    onChange(event, event.target.value);
  };

  const handleKeyUp = () => {
    if (_performAutoCompleteOnKeyUp.current) {
      _performAutoCompleteOnKeyUp.current = false;
    }
  };

  const keyDownHandlers = {
    ArrowDown: (event: React.KeyboardEvent) => {
      event.preventDefault();
      if (value === '') {
        return false;
      }
      const index =
        highlightedIndex === null ||
        highlightedIndex === (items?.length ?? 0) - 1
          ? 0
          : highlightedIndex + 1;
      _performAutoCompleteOnKeyUp.current = true;
      setHighlightedIndex(index);
      setIsOpen(true);
    },

    ArrowUp: (event: React.KeyboardEvent) => {
      event.preventDefault();
      if (value === '') {
        return false;
      }
      const index =
        highlightedIndex === 0 || highlightedIndex === null
          ? (items?.length ?? 0) - 1
          : highlightedIndex - 1;
      _performAutoCompleteOnKeyUp.current = true;
      setHighlightedIndex(index);
      setIsOpen(true);
    },

    Enter: (event: React.KeyboardEvent) => {
      if (event) {
        event.preventDefault();
      }

      if (!isOpen) {
        // already selected this, do nothing

        onEnter(
          value,
          highlightedIndex !== null ? items?.[highlightedIndex] : undefined
        );
      } else if (highlightedIndex === null) {
        onEnter(value);
        // hit entering after focus but before typing anything so no autocomplete attempt yet
        setIsOpen(false);
        inputRef.current?.select();
      } else {
        const item = items?.[highlightedIndex];
        const index = highlightedIndex;
        setValue(getItemValue(item));
        setIsOpen(false);
        setHighlightedIndex(null);

        inputRef.current?.setSelectionRange(
          value?.length ?? 0,
          value?.length ?? 0
        );
        onSelect(value, item, index);
      }
    },

    Escape: () => {
      setIsOpen(false);
      setHighlightedIndex(null);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (
      window.Object.prototype.hasOwnProperty.call(keyDownHandlers, event.key)
    ) {
      const key = event.key as keyof typeof keyDownHandlers;
      keyDownHandlers[key](event);
    } else {
      setHighlightedIndex(null);
      setIsOpen(true);
    }
  };

  const handleInputBlur = () => {
    if (isIgnoreBlur()) {
      return;
    }
    setHighlightedIndex(null);
    setIsOpen(false);
  };

  const handleInputFocus = () => {
    if (_ignoreBlur.current) {
      return;
    }
    setIsOpen(true);
  };

  const handleInputClick = () => {
    if (!isOpen) {
      setIsOpen(true);
    }
  };

  const handleMouseDownMenu = () => {
    // turn on if the menu has to stay open after a mouse down event on it
    if (keepOpen) {
      _keepOpenAutocomplete.current = true;
    }
  };

  const renderMenuAutocomplete = () => {
    const itemsAutocomplete =
      items?.map((item, index) => {
        const itemId = item.id ?? item?.node?.id;
        itemsRefs[`item-${index}`] = createRef<HTMLDivElement>();

        return renderItem(
          item,
          {
            onMouseDown: () => (_ignoreBlur.current = true),
            onMouseEnter: () => highlightItemFromMouse(index),
            onClick: () => selectItemFromMouse(item),
            ref: itemsRefs[`item-${index}`],
            key: `item-${itemId}`
          },
          highlightedIndex === index,
          { cursor: 'default' }
        );
      }) ?? [];

    return renderMenu(itemsAutocomplete, {
      ref: menuRef,
      onMouseDown: handleMouseDownMenu
    });
  };

  return (
    <div
      aria-label={getActiveItemValue()}
      className="container-input-autocomplete"
      role="search"
    >
      <div className="container-input-mask">
        <input
          {...inputProps}
          aria-autocomplete="both"
          aria-label="search"
          aria-multiline="false"
          onBlur={handleInputBlur}
          onChange={handleChange}
          onClick={handleInputClick}
          onFocus={handleInputFocus}
          onKeyDown={handleKeyDown}
          onKeyUp={handleKeyUp}
          ref={inputRef}
          role="textbox"
          value={value ?? undefined}
        />
      </div>
      {(isOpen && renderMenuAutocomplete()) ?? null}
    </div>
  );
};

export default Autocomplete;
