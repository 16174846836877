import { BRAND_HAS_PAYWALL } from 'common/configuration/constants';
import { getConsentForAnalitycs } from 'common/consent/Didomi';
import request from 'common/tools/network/request';
import noop from 'common/tools/noop';
import { hit } from 'common/tracking/googleanalytics';

const PIXEL_URL = 'https://bpt.webedia-group.com/pixel.png?/';

type TcData = {
  eventStatus: string;
  listenerId: number;
};

export async function getConsentState(continueWoConsent = false) {
  return new Promise(resolve => {
    if (!window.__tcfapi) {
      resolve(false);
      return;
    }

    const getConsent = (tcData: TcData) => {
      try {
        if (
          tcData.eventStatus === 'useractioncomplete' ||
          tcData.eventStatus === 'tcloaded'
        ) {
          window.__tcfapi('removeEventListener', 2, getConsent);
          resolve(true);
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.warn(`Error in getting consent : ${e}`);

        if (continueWoConsent) {
          resolve(false);
        }
      }
    };

    window.__tcfapi('addEventListener', 2, getConsent);
  });
}

export function trackConsent() {
  if (!BRAND_HAS_PAYWALL) {
    return false;
  }

  let tcfok = false;

  if (window.__tcfapi) {
    window.__tcfapi('addEventListener', 2, function (tcData: TcData) {
      try {
        switch (tcData.eventStatus) {
          case 'useractioncomplete':
            triggerConsentTracking(tcData.eventStatus, true);
            window.__tcfapi('removeEventListener', 2, noop, tcData.listenerId);
            break;
          case 'cmpuishown':
            triggerConsentTracking(tcData.eventStatus, false);
            break;
          case 'tcloaded':
            triggerConsentTracking(tcData.eventStatus, false);
            window.__tcfapi('removeEventListener', 2, noop, tcData.listenerId);
            break;
          default:
            triggerConsentTracking(tcData.eventStatus, true);
        }
      } catch (error) {
        triggerConsentTracking('tcferror', true);
      }
      tcfok = true;
    });
  }

  setTimeout(function () {
    if (!tcfok) {
      triggerConsentTracking('tcfnotloaded', false);
    }
  }, 3000);
}

async function triggerConsentTracking(tcfStatus: string, sendGA: boolean) {
  let done = false;

  const trackingTimeout = setTimeout(() => {
    sendBecon('timeout', 'false', tcfStatus);
  }, 500);

  function sendBecon(method: string, consent: string, tcfStatus: string) {
    if (!done) {
      if (sendGA) {
        hit({
          eventCategory: 'tcfapi',
          eventAction: `${tcfStatus}: ${consent}`,
          eventLabel: method,
          dimension10: window.AC.route,
          dimension98: document.referrer
        });
      }

      sendPixel(method, consent, tcfStatus);

      if (trackingTimeout) {
        clearTimeout(trackingTimeout);
      }

      done = true;
    }
  }

  const consent: any = await getConsentForAnalitycs(false);
  const consentToString: any = consent == undefined ? 'undefined' : consent;

  sendBecon('didomiOnReady', consentToString, tcfStatus);
}

function sendPixel(
  method: string | void,
  consent: string | void,
  status: string | void
) {
  const pixelParam = `domain=${window.location.host}&referrer=${document.referrer}&site_route=${window.AC.route}&event=tcfapi&method=${method}&consent=${consent}&tcfStatus=${status}`;

  try {
    request(
      PIXEL_URL + pixelParam,
      {
        credentials: 'same-origin'
      },
      false
    );
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn(`Error sending pixel : ${e}`);
  }
}
