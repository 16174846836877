import { LocalStorageKeys } from 'common/constants/LocalStorageKeys';
import {
  Candidate,
  CandidateNames
} from 'common/services/unwantedOverlay/UnwantedOverlayManager';
import { isNotOnFirstScreen } from 'common/tools/network';
import { getCapping } from 'common/tools/ui/capping-helper';

import { openPromoteAffinityModal } from 'website/services/modal';

class PromoteAffinityOverlayCandidate implements Candidate {
  name = CandidateNames.PromoteAffinity;
  async isEligible() {
    const capping = getCapping(LocalStorageKeys.PROMOTE_AFFINITY_MODAL_CAPPING);

    return (
      isNotOnFirstScreen() &&
      (capping.now.getMonth() > capping.month ||
        (capping.counter < 3 && capping.now.getDate() > capping.day + 7))
    );
  }
  render() {
    openPromoteAffinityModal();
  }
}

export default PromoteAffinityOverlayCandidate;
