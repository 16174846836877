const debounce = <T extends (...args: any[]) => any>(func: T, wait = 300) => {
  let timeout = 0;

  return (...args: Parameters<T>) => {
    // This will reset the waiting every function execution.
    // This is the step that prevents the function from
    // being executed because it will never reach the
    // inside of the previous setTimeout
    window.clearTimeout(timeout);

    // Restart the debounce waiting period.
    // setTimeout returns a truthy value (it differs in web vs Node)
    timeout = window.setTimeout(() => {
      // Execute the callback
      func(...args);
    }, wait);
  };
};

export default debounce;
