import React from 'react';
import { createRoot } from 'react-dom/client';

import { SS_KEY_HIDE_REMINDER } from 'common/constants/LocalStorageKeys';
import {
  getUserProvenance,
  USER_PROVENANCE_SIGN_UP
} from 'common/tools/userspace';

import { waitForLoginStatus } from 'website/containers/user/connection';

export default function init() {
  const anchor = document.getElementsByClassName('js-message-board')[0];
  if (!anchor) return;
  if (sessionStorage.getItem(SS_KEY_HIDE_REMINDER)) return;

  // We don't want to display this reminder if we display also the modal. See also: https://gph.is/2DifJDh
  const userProvance = getUserProvenance();
  if (userProvance === USER_PROVENANCE_SIGN_UP) return;

  waitForLoginStatus().then(user => {
    if (!user.loggedIn || (user.account && user.account.isEmailValidated))
      return;
    import(
      /* webpackChunkName: "account-confirmation-message" */ 'website/components/user/account/AccountConfirmationMessage'
    ).then(module => {
      const AccountConfirmationMessage = module.default;
      const root = createRoot(anchor);
      root.render(<AccountConfirmationMessage />);
    });
  });
}
