// compute area from space to display the tooltip
// use to define the best area around the anchor to display tooltip

type AreaData = { width: number; height: number; area: number };

export type Position = 'top' | 'right' | 'bottom' | 'left';

type Area = Partial<Record<Position, AreaData>>;

const getArea = (width: number, height: number) => ({
  width,
  height,
  area: width * height
});

const computeAreasAroundAnchor = (
  position: string,
  anchorRect: DOMRect,
  viewportHeight: number,
  constraintRectangle: DOMRect,
  topGap: number
) => {
  // store comparison result
  const auto = position === 'auto';

  // compute the best area to display the tooltip
  const area: Area = {};

  // get area between the top of the anchor and the top of viewport (minus top gap)
  if (auto || position === 'top') {
    area.top = getArea(
      constraintRectangle.width, // the constraint rectangle width (not the viewport width)
      anchorRect.top - topGap // the anchor top in the viewport (without scroll Y position) minus a top gap
    );
  }
  // get area between the right of the anchor and the right of the constraint rectangle (not the viewport)
  if (auto || position === 'right') {
    area.right = getArea(
      // the constraint rectangle width
      // (not the viewport width) less the anchor right X value
      constraintRectangle.right - anchorRect.right,
      viewportHeight - topGap // the viewport height (minus top gap)
    );
  }
  // get area between the bottom of the anchor and the bottom of the viewport
  if (auto || position === 'bottom') {
    area.bottom = getArea(
      constraintRectangle.width, // the constraint rectangle width (not the viewport)
      viewportHeight - anchorRect.bottom // the viewport height less the anchor bottom Y position
    );
  }
  // get area between the left of the anchor and the left of the constraint rectangle (not the viewport)
  if (auto || position === 'left') {
    area.left = getArea(
      // the anchor left X position in the constraint rectangle
      // (not in the viewport)
      anchorRect.left - constraintRectangle.left,
      viewportHeight - topGap // the viewport height (minus top gap)
    );
  }

  return area;
};

const computeFinalTooltipPosition = (
  requestedPosition: Position | 'auto',
  areas: Area
) => {
  let finalPosition: Position;
  // if auto, get the biggest area to display tooltip
  if (requestedPosition === 'auto') {
    // start with the first in the list
    let positionCandidate: Position = 'top';
    let key: Position;

    // loop for each and compare the biggest
    for (key in areas) {
      if (areas[positionCandidate]?.area ?? 0 < (areas[key]?.area ?? 0)) {
        // override if bigger
        positionCandidate = key;
      }
    }
    finalPosition = positionCandidate;
  } else {
    finalPosition = requestedPosition;
  }
  return finalPosition;
};

/**
 * This will position the given tooltip
 */
export default (
  position: Position | 'auto',
  anchor: HTMLDivElement,
  elTooltip: HTMLDivElement,
  elTooltipArrow: HTMLDivElement,
  elTooltipContainer: HTMLDivElement,
  parentConstraint: HTMLElement,
  topGap = 0
) => {
  // get viewport data
  const { scrollX, scrollY, innerHeight: windowHeight } = window;

  // get tooltip data
  let tooltipX: number;
  let tooltipY: number;
  let { offsetWidth: tooltipWidth, offsetHeight: tooltipHeight } = elTooltip;
  // get tooltip arrow data
  let arrowX = 0;
  let arrowY = 0;
  let { offsetWidth: arrowWidth, offsetHeight: arrowHeight } = elTooltipArrow;
  // get tooltip content data
  const {
    offsetTop: tooltipContentTop,
    offsetLeft: tooltipContentLeft,
    offsetWidth: tooltipContentWidth,
    offsetHeight: tooltipContentHeight
  } = elTooltipContainer;

  // get anchor size and position
  const anchorRect = anchor.getBoundingClientRect();

  // get the middle position of the anchor from the top left of the html body (without a scroll)
  const anchorMiddleX = scrollX + anchorRect.left + anchorRect.width / 2;
  const anchorMiddleY = scrollY + anchorRect.top + anchorRect.height / 2;

  const constraintRectangle = parentConstraint.getBoundingClientRect();

  const areas = computeAreasAroundAnchor(
    position,
    anchorRect,
    windowHeight,
    constraintRectangle,
    topGap
  );

  const computedPosition = computeFinalTooltipPosition(position, areas);

  const area = areas[computedPosition];
  // resize the tooltip if it overflows
  if ((area?.width ?? 0) < tooltipWidth) {
    // tooltip width overflow area to display
    // set the area width to the tooltip
    tooltipWidth = area?.width ?? 0;
    elTooltip.style.width = `${tooltipWidth / 16}rem`;
    // get the new tooltip height
    tooltipHeight = elTooltip.offsetHeight;
  }
  if ((area?.height ?? 0) < tooltipHeight) {
    // tooltip height overflow area to display
    // set the area height to the tooltip
    tooltipHeight = area?.height ?? 0;
    elTooltip.style.height = `${tooltipHeight / 16}rem`;
    // update the new tooltip width, if it's fix by the first condition, no changes
    tooltipWidth = elTooltip.offsetWidth;
  }

  // After select the best area and set the size of the tooltip
  // compute the tooltip position in the area
  switch (computedPosition) {
    case 'right':
      // tooltip start at the anchor right X position
      tooltipX = scrollX + anchorRect.left + anchorRect.width;
      // tooltip middle is aligned with the middle of the anchor
      // unless the top of the tooltip exceeds the header,
      // or the bottom of the tooltip is over the page
      tooltipY = Math.max(
        // the biggest between the tooltip's top and the header's bottom
        Math.min(
          // the smallest between the tooltip's bottom and the viewport bottom less the tooltip height
          anchorMiddleY - tooltipHeight / 2,
          scrollY + windowHeight - tooltipHeight
        ),
        scrollY + topGap
      );
      // place the arrow on the left
      elTooltip.classList.add('tooltip-right');
      // update the height of the arrow
      arrowHeight = elTooltipArrow.offsetHeight;
      // prevents the arrow from coming out of alignment with the content (not with the parent container)
      arrowY = Math.max(
        Math.min(
          anchorMiddleY - tooltipY - arrowHeight / 2,
          tooltipContentTop + tooltipContentHeight - arrowHeight
        ),
        tooltipContentTop
      );
      break;
    case 'bottom':
      // tooltip middle is aligned with the middle of the anchor
      // unless the tooltip exceeds the constraint rectangle
      // in this case, the tooltip will be aligned at the edge of the constraint rectangle
      tooltipX = Math.max(
        // the biggest between the tooltip's left and the constraint rectangle left
        Math.min(
          // the smallest between the tooltip's right and the constraint rectangle right less the tooltip width
          anchorMiddleX - tooltipWidth / 2,
          scrollX +
            constraintRectangle.left +
            constraintRectangle.width -
            tooltipWidth
        ),
        scrollX + constraintRectangle.left
      );
      // tooltip start at the anchor bottom Y position
      tooltipY = scrollY + anchorRect.top + anchorRect.height;
      // place the arrow on the top
      elTooltip.classList.add('tooltip-bottom');
      // update the width of the arrow
      arrowWidth = elTooltipArrow.offsetWidth;
      // prevents the arrow from coming out of alignment with the content (not with the parent container)
      arrowX = Math.max(
        Math.min(
          anchorMiddleX - tooltipX - arrowWidth / 2,
          tooltipContentLeft + tooltipContentWidth - arrowWidth
        ),
        tooltipContentLeft
      );
      break;
    case 'left':
      // tooltip start at the anchor left X position
      tooltipX = scrollX + anchorRect.left - tooltipWidth;
      // tooltip middle is aligned with the middle of the anchor
      // unless the top of the tooltip exceeds the header,
      // or the bottom of the tooltip is over the page
      tooltipY = Math.max(
        // the biggest between the tooltip's top and the header's bottom
        Math.min(
          // the smallest between the tooltip's bottom and the viewport bottom less the tooltip height
          anchorMiddleY - tooltipHeight / 2,
          scrollY + windowHeight - tooltipHeight
        ),
        scrollY + topGap
      );
      // place the arrow on the right
      elTooltip.classList.add('tooltip-left');
      // update the height of the arrow
      arrowHeight = elTooltipArrow.offsetHeight;
      // prevents the arrow from coming out of alignment with the content (not with the parent container)
      arrowY = Math.max(
        Math.min(
          anchorMiddleY - tooltipY - arrowHeight / 2,
          tooltipContentTop + tooltipContentHeight - arrowHeight
        ),
        tooltipContentTop
      );
      break;
    default:
      // tooltip middle is aligned with the middle of the anchor
      // unless the tooltip exceeds the constraint rectangle
      // in this case, the tooltip will be aligned at the edge of the constraint rectangle
      tooltipX = Math.max(
        // the biggest between the tooltip's left and the constraint rectangle left
        Math.min(
          // the smallest between the tooltip's right and the constraint rectangle right less the tooltip width
          anchorMiddleX - tooltipWidth / 2,
          scrollX +
            constraintRectangle.left +
            constraintRectangle.width -
            tooltipWidth
        ),
        scrollX + constraintRectangle.left
      );
      // tooltip start at the anchor top Y position
      tooltipY = scrollY + anchorRect.top - tooltipHeight;

      // prevents the arrow from coming out of alignment with the content (not with the parent container)
      arrowX = Math.max(
        Math.min(
          anchorMiddleX - tooltipX - arrowWidth / 2,
          tooltipContentLeft + tooltipContentWidth - arrowWidth
        ),
        tooltipContentLeft
      );
  }

  // set position to the tooltip
  // removes top position from tooltip parent container (scrollY - constraintRectangle.top)
  elTooltip.style.transform = `translate3d(${
    Math.round(tooltipX - scrollX - constraintRectangle.left) / 16
  }rem,${Math.round(tooltipY - scrollY - constraintRectangle.top) / 16}rem,0)`;

  // correct the position of the arrow
  elTooltipArrow.style.transform = `translate3d(${Math.round(arrowX) / 16}rem,${
    Math.round(arrowY) / 16
  }rem,0)`;
};
