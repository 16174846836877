import slugify from 'common/tools/string/slugify';
import { hit } from 'common/tracking/googleanalytics';
import { standardizeTrackingType } from 'common/tracking/helpers';
import socialActionTracking from 'common/tracking/socialActionTracking';

export const trackWantToSee = (
  eventAction: string | null,
  {
    context: { entity, socialActionPlacement }
  }: {
    context: {
      entity: { legacyId: number; title: string; typename: string };
      socialActionPlacement: string;
    };
  }
) => {
  if (
    entity.typename !== 'Movie' &&
    entity.typename !== 'Series' &&
    entity.typename !== 'Episode'
  ) {
    return;
  }

  const { legacyId, title, typename } = entity;

  hit(
    {
      eventCategory: 'social',
      eventLabel: 'want_to_see',
      eventAction,
      dimension82: `${legacyId}-${slugify(title)}`, // entity
      dimension83: null, // entity status
      dimension84: standardizeTrackingType(typename), // entity theme
      dimension85: socialActionPlacement, // social action placement
      dimension87: legacyId // entity ID
    },
    true
  );

  socialActionTracking('want_to_see', eventAction, {
    ...entity,
    internalId: entity.legacyId
  });
};
