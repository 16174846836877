import { AnyAction, createReducer } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';

import { updateObject } from 'common/tools/objects/update';

import { SuccessPayload } from 'website/actions/AffinitySuggestionsActions';
import * as actionTypes from 'website/constants/ActionTypes';
import { SuggestableEntity } from 'website/types';

import { userEntityLeafMapper } from './data/helper';
import { UserEntityLeaf } from './data/types';

type Suggestion = { open: boolean; suggestions: string[]; items: string[] };

type SuggestionsBlocks = Record<string, Suggestion>;

export type AffinitySuggestionsState = {
  suggestionsBlocks: SuggestionsBlocks;
};

const filterSuggestions = (
  state: SuggestionsBlocks,
  entity: SuggestableEntity,
  useEntityLeaves: UserEntityLeaf[]
) => {
  const suggestions = state[entity.id].suggestions ?? [];

  const items = suggestions.reduce((acc: string[], suggestionId) => {
    const matchingUserEntityLeaf = useEntityLeaves.find(
      ({ entity: e }) => e.id === suggestionId
    );

    if (
      !matchingUserEntityLeaf ||
      (!matchingUserEntityLeaf.wantToSee && !matchingUserEntityLeaf.opinion)
    ) {
      // Add item if not noted "want to see" and there is no rating or review on it
      // by including the necessary properties to operate their own button "want to see"
      return [...acc, suggestionId];
    }

    return acc;
  }, []);

  return updateObject(state, {
    [entity.id]: {
      items
    }
  });
};

const closeAffinitySuggestion = (
  state: SuggestionsBlocks,
  action: AnyAction
) => {
  // To prevent to display suggestions at the load page.
  // suggestionsBlocks have to be created by user click only.
  // it's append when GET_AFFINITY_SUGGESTIONS_SUCCESS is call
  if (!state || !state[action.payload.entity.id]) {
    return { ...state };
  }

  return updateObject(state, {
    [action.payload.entity.id]: {
      open: false
    }
  });
};

const getAffinitySuggestion = (state: SuggestionsBlocks, action: AnyAction) => {
  const payload = action.payload as SuccessPayload;
  const tmpState = updateObject(state, {
    [action.entity.id]: {
      ...(state[action.entity.id] || {
        items: []
      }),
      open: true,
      suggestions: payload.entities.map((entity: { id: string }) => entity.id)
    }
  });

  const useEntityLeaves =
    payload.entitiesWithOpinions.map(userEntityLeafMapper);

  return filterSuggestions(tmpState, action.entity, useEntityLeaves);
};

const affinitySuggestionsReducer = createReducer({}, builder => {
  builder
    .addCase(actionTypes.CLOSE_AFFINITY_SUGGESTIONS, closeAffinitySuggestion)
    .addCase(
      actionTypes.GET_AFFINITY_SUGGESTIONS_SUCCESS,
      getAffinitySuggestion
    );
});

const appReducer = combineReducers({
  suggestionsBlocks: affinitySuggestionsReducer
});

export default appReducer;
