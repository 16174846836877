import {
  DISPLAY_PROVIDER,
  PREROLL_PROVIDER,
  POSTROLL_PROVIDER,
  POSTROLL_TRAILER_PROVIDER
} from 'common/constants/Ads';
import {
  AdProviders,
  AdProvidersModule
} from 'common/module/advertisement/types';
import { triggerAdsPositionsReady } from 'common/module/advertisement/utils';
import eventEmitter, { EventsTypes } from 'common/services/events/eventEmitter';
import { hasAdblock } from 'common/tools/adblock';
import { noAdserver } from 'common/tools/ads';
import { addClass } from 'common/tools/dom/classes';
import once from 'common/tools/functions/once';

import { PlayerCustomData } from 'website/module/player';

const triggerAdsReady = once(() => {
  window.adsReady = true;
  return eventEmitter.emit(EventsTypes.ADS_READY);
});

const adProvidersModules: Partial<Record<string, AdProvidersModule | void>> =
  {};

const loadAdProviderModule = async (
  adProvider: AdProviders
): Promise<AdProvidersModule | void> => {
  switch (adProvider) {
    case AdProviders.WEBEDIADS_SYSTEM_CODE:
      if (window.wads) {
        try {
          return await import(
            /* webpackChunkName: "script-01" */ './Webediads.lazy'
          );
        } catch (e) {
          triggerAdsPositionsReady();
          console.error(e); // eslint-disable-line no-console
        }
      }
      break;
    case AdProviders.DFP_SYSTEM_CODE:
      if (window.DfpConfigPlayer || window.GptConfig) {
        try {
          return await import(/* webpackChunkName: "script-02" */ './Dfp.lazy');
        } catch (e1) {
          triggerAdsPositionsReady();
          console.error(e1); // eslint-disable-line no-console
        }
      }
      break;
    case AdProviders.JAD_SYSTEM_CODE:
      if (window.JadConfig) {
        try {
          return await import(/* webpackChunkName: "script-03" */ './Jad.lazy');
        } catch (e2) {
          triggerAdsPositionsReady();
          console.error(e2); // eslint-disable-line no-console
        }
      }
      break;
    case AdProviders.FACEBOOK_SYSTEM_CODE:
      return import('./Facebook');
  }
  // if there is no ad system matching the config (typically a disabled provider)
  // return a self resolving promise
  return Promise.resolve();
};

export default () => {
  // This is not good. We take all the providers and launch the init part.
  // It means that if you have dfp for preroll and wads for display you will load both adserver on every page
  // We should separate display / preroll / postroll for the init

  if (noAdserver() || hasAdblock()) {
    addClass(document.body, 'no-ads');
  }

  const adProviderSet = new Set<AdProviders>([
    DISPLAY_PROVIDER,
    PREROLL_PROVIDER,
    POSTROLL_PROVIDER,
    POSTROLL_TRAILER_PROVIDER
  ]);
  const initPromises = noAdserver()
    ? []
    : // Removing any falsy value
      [...adProviderSet].filter(Boolean).map(async adProvider => {
        // Asynchronously loading ad provider JS module
        const adProvidersModule = await loadAdProviderModule(adProvider);
        // Saving the module for later use
        adProvidersModules[adProvider] = adProvidersModule;
        const adProviderModule = adProvidersModule;
        // Calling the module's init function if it exists
        if (adProviderModule && typeof adProviderModule.init === 'function') {
          return new Promise(adProviderModule.init);
        }
        return await Promise.resolve();
      });

  Promise.all(initPromises)
    .then(() => {
      triggerAdsReady();
    })
    .catch(e => {
      triggerAdsReady();
      console.error(e); // eslint-disable-line no-console
    });
};

export const getPrerollConfiguration = async (data: PlayerCustomData) => {
  if (
    !noAdserver() &&
    !hasAdblock() &&
    PREROLL_PROVIDER &&
    adProvidersModules[PREROLL_PROVIDER] &&
    typeof adProvidersModules[PREROLL_PROVIDER]?.getPrerollConfiguration ===
      'function'
  ) {
    return adProvidersModules[PREROLL_PROVIDER]?.getPrerollConfiguration?.(
      data
    );
  }
  return null;
};

export const getPostrollConfiguration = async (data: PlayerCustomData) => {
  if (
    !noAdserver() &&
    !hasAdblock() &&
    POSTROLL_PROVIDER &&
    adProvidersModules[POSTROLL_PROVIDER] &&
    typeof adProvidersModules[POSTROLL_PROVIDER]?.getPostrollConfiguration ===
      'function'
  ) {
    return adProvidersModules[POSTROLL_PROVIDER]?.getPostrollConfiguration?.(
      data
    );
  }
  return null;
};

export const getPostrollAsVideoConfiguration = (data: PlayerCustomData) => {
  if (
    !noAdserver() &&
    !hasAdblock() &&
    POSTROLL_TRAILER_PROVIDER &&
    adProvidersModules[POSTROLL_TRAILER_PROVIDER] &&
    typeof adProvidersModules[POSTROLL_TRAILER_PROVIDER]
      ?.getPostrollAsVideoConfiguration === 'function'
  ) {
    return adProvidersModules[
      POSTROLL_TRAILER_PROVIDER
    ]?.getPostrollAsVideoConfiguration?.(data);
  }
  return null;
};

export const getClientConfiguration = () => {
  if (
    !noAdserver() &&
    PREROLL_PROVIDER &&
    adProvidersModules[PREROLL_PROVIDER] &&
    typeof adProvidersModules[PREROLL_PROVIDER]?.getClientConfiguration ===
      'function'
  ) {
    return adProvidersModules[PREROLL_PROVIDER]?.getClientConfiguration?.();
  }
  return {};
};

export const createPosition = (
  positionRef: string,
  element: string | HTMLDivElement
) => {
  if (!positionRef || !element) {
    return false;
  }

  if (
    DISPLAY_PROVIDER &&
    adProvidersModules[DISPLAY_PROVIDER] &&
    typeof adProvidersModules[DISPLAY_PROVIDER]?.createPosition === 'function'
  ) {
    return adProvidersModules[DISPLAY_PROVIDER]?.createPosition?.(
      positionRef,
      element
    );
  }
};

export const refreshAds = (refreshObj: { advertisementTargets: string[] }) => {
  if (
    DISPLAY_PROVIDER &&
    adProvidersModules[DISPLAY_PROVIDER] &&
    typeof adProvidersModules[DISPLAY_PROVIDER]?.refreshAds === 'function'
  ) {
    return adProvidersModules[DISPLAY_PROVIDER]?.refreshAds?.(refreshObj);
  }
};

export const removePosition = (
  positionRef: string | undefined,
  element: HTMLDivElement | null | undefined
) => {
  if (!positionRef || !element) {
    return false;
  }

  if (
    DISPLAY_PROVIDER &&
    adProvidersModules[DISPLAY_PROVIDER] &&
    typeof adProvidersModules[DISPLAY_PROVIDER]?.removePosition === 'function'
  ) {
    return adProvidersModules[DISPLAY_PROVIDER]?.removePosition?.(
      positionRef,
      element
    );
  }
};
