import React, { useCallback, useEffect, useState } from 'react';

import Button from 'common/components/Button';
import { path } from 'common/tools/network/routing';
import { getImageUrl } from 'common/tools/network/url';
import pad from 'common/tools/string/pad';
import slugify from 'common/tools/string/slugify';
import trans from 'common/tools/translations/trans';
import { hit, print } from 'common/tracking/googleanalytics';
import { standardizeTrackingType } from 'common/tracking/helpers';
import { print as print_jan, hit as hit_jan } from 'common/tracking/jan';

import styles from './styles.module.scss';

export type ReleasedOn = 'SVOD' | 'THEATER';

export type EmergenceTracking = Record<string, any>;

type Props = {
  ctaTextAfterRelease?: string;
  ctaTextBeforeRelease?: string;
  entityId?: string;
  entityParentId?: string;
  entityProviderLogo?: string;
  entityProviderName?: string;
  entityRedirect?: string;
  entityRelease?: string;
  entityTitle?: string;
  entityType?: string;
  entityVideoId?: string;
  releasedOn: ReleasedOn;
  textAfterRelease?: string;
  textBeforeRelease?: string;
  tracking?: EmergenceTracking;
  widgetLarge: boolean;
};

const releasedOnList: ReleasedOn[] = ['SVOD', 'THEATER'];
export const isReleasedOn = (candidate: any): candidate is ReleasedOn =>
  releasedOnList.includes(candidate);

const countdownDaysLabel = trans('countdown_days');
const countdownHoursLabel = trans('countdown_hours');
const countdownMinutesLabel = trans('countdown_minutes');

const ReleaseCountdown = ({
  ctaTextAfterRelease,
  ctaTextBeforeRelease,
  entityId,
  entityParentId,
  entityProviderLogo,
  entityProviderName,
  entityRedirect,
  entityRelease,
  entityTitle,
  entityType,
  entityVideoId,
  releasedOn,
  textAfterRelease,
  textBeforeRelease,
  tracking,
  widgetLarge
}: Props) => {
  const [now, setNow] = useState(Date.now());
  const [releaseDate, setReleaseDate] = useState(-1);
  const [isPrinted, setIsPrinted] = useState(false);

  const brandCampaignType =
    releasedOn === 'SVOD' ? 'emergence_svod' : 'emergence';

  const baseTracking = {
    eventCategory: brandCampaignType,
    eventLabel: '',
    dimension82: `${entityId}-${slugify(entityTitle)}`,
    dimension84: standardizeTrackingType(entityType)
  };

  const remainingTime = releaseDate - now;

  const handleTracking = useCallback(
    (actionClick: boolean) => {
      if (releaseDate === -1) {
        return;
      }

      let label;

      if (releaseDate > 0) {
        label = widgetLarge
          ? 'countdown_homepage_trailer'
          : 'countdown_right_column_trailer';
      } else {
        label = widgetLarge
          ? 'countdown_homepage_affiliation'
          : 'countdown_right_column_affiliation';
      }

      const trackingObj = {
        ...baseTracking,
        eventLabel: label
      };

      if (!actionClick && !isPrinted) {
        print(trackingObj);
        print_jan('emergence', {
          ...(tracking ?? {})
        });
        setIsPrinted(true);
        return;
      }

      if (actionClick) {
        hit(trackingObj);
        hit_jan('emergence_click', {
          ...(tracking ?? {})
        });
        return;
      }
    },
    [baseTracking, isPrinted, releaseDate, widgetLarge]
  );

  useEffect(() => {
    const newReleaseDate = new Date(entityRelease ?? '');
    const newRelease = newReleaseDate.getTime();

    if (Date.now() >= newRelease) {
      setReleaseDate(0);
    } else {
      setReleaseDate(newRelease);
    }
  }, [entityRelease]);

  useEffect(() => {
    let i_id = 0;
    if (releaseDate > 0) {
      // update count every minute => 60000 milliseconds
      i_id = window.setInterval(() => {
        setNow(Date.now());
      }, 60000);
    }

    return () => {
      window.clearInterval(i_id);
    };
  }, [releaseDate]);

  useEffect(() => {
    handleTracking(false);
  }, [handleTracking, releaseDate]);

  let timer = null;

  const providerLogo = () => {
    const img = `/commons/v9/common/svod_providers/emergence/logo_${entityProviderLogo}.svg`;

    return getImageUrl({ src: img });
  };

  let trailerRoutePath = '#';

  switch (entityType) {
    case 'movie':
      trailerRoutePath = path('moviepage_videos_trailer', {
        movie: entityId,
        idMedia: entityVideoId
      });
      break;

    case 'series':
      trailerRoutePath = path('seriespage_videos_trailer', {
        series: entityId,
        idMedia: entityVideoId
      });
      break;

    case 'season':
      if (entityParentId) {
        trailerRoutePath = path('seriespage_videos_trailer', {
          series: entityParentId,
          idMedia: entityVideoId
        });
      } else {
        trailerRoutePath = '#';
      }

      break;

    default:
      trailerRoutePath = '#';
  }

  const handleBtnHref = () => {
    if (remainingTime > 0) {
      return trailerRoutePath;
    }

    return entityRedirect;
  };
  const svgClass = `provider-logo logo-${entityProviderName}`;
  const provider = (
    <div className={styles.provider}>
      <span className={styles.providerText}>
        {remainingTime > 0 ? textBeforeRelease : textAfterRelease}
      </span>
      {releasedOn === 'SVOD' ? (
        <img
          alt={entityProviderName}
          className={svgClass}
          src={providerLogo()}
          width="150"
        />
      ) : null}
    </div>
  );

  if (remainingTime > 0) {
    const remainingDate: any = new Date(remainingTime);

    // https://www.w3schools.com/howto/howto_js_countdown.asp (we can't use getDate / getHours / getMinutes because when it's less than a day, new Date(remainingTime), it will trigger a 01/01/1970 date)
    const days = Math.floor(remainingDate / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (remainingDate % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor(
      (remainingDate % (1000 * 60 * 60)) / (1000 * 60)
    );

    timer = (
      <div className={styles.timer}>
        <div className={styles.time}>
          <span className={styles.timeValue}>{pad(days)}</span>
          <span className={styles.timeLabel}>{countdownDaysLabel}</span>
        </div>
        <span className={styles.timeSeparator}>:</span>
        <div className={styles.time}>
          <span className={styles.timeValue}>{pad(hours)}</span>
          <span className={styles.timeLabel}>{countdownHoursLabel}</span>
        </div>
        <span className={styles.timeSeparator}>:</span>
        <div className={styles.time}>
          <span className={styles.timeValue}>{pad(minutes)}</span>
          <span className={styles.timeLabel}>{countdownMinutesLabel}</span>
        </div>
      </div>
    );
  }

  return (
    <div className="emergence-countdown">
      <div className={styles.emergenceCountdownContent}>
        <div className={styles.emergenceCountdownBody}>
          {provider}
          {timer}
        </div>
        <div className={styles.emergenceCountdownCta}>
          <Button
            theme="countdown"
            size="sm"
            href={handleBtnHref()}
            onClick={handleTracking.bind(null, true)}
            targetHref={remainingTime > 0 ? false : true}
          >
            {remainingTime > 0 ? ctaTextBeforeRelease : ctaTextAfterRelease}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ReleaseCountdown;
