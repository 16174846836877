import React, {
  DetailedHTMLProps,
  InputHTMLAttributes,
  useEffect,
  useRef
} from 'react';
import { createRoot } from 'react-dom/client';

type Input = Omit<
  DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
  'name' | 'type'
> & { name: string };

type FormActionProps = {
  action: string;
  inputs: Input[];
};
const FormAction = ({ action, inputs }: FormActionProps) => {
  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    if (formRef.current) {
      formRef.current.requestSubmit();
    }
  }, [formRef]);

  return (
    <form action={action} ref={formRef} method="POST">
      {inputs.map(({ name, ...props }) => (
        <input key={name} name={name} type="hidden" {...props} />
      ))}
    </form>
  );
};

const containerId = 'form-action-post-data';

/*
 * postData lets you send an HTML form using the POST method and
 * let the php server handle the redirection (no Ajax management
 * of the response).
 */
const postData = (action: string, inputs: Input[]) => {
  let anchor = document.getElementById(containerId);

  if (!anchor) {
    anchor = document.createElement('div');
    anchor.id = containerId;
    document.body.appendChild(anchor);
  }

  const root = createRoot(anchor);
  root.render(<FormAction action={action} inputs={inputs} />);
};

export default postData;
