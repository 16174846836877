import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import Tooltip from 'common/components/Tooltip';
import hasTouch from 'common/tools/dom/getTouchBrowser';
import noop from 'common/tools/noop';

/**
 * This HOC will mutate the "visible" property of the WrappedComponent
 * using onMouseEnter and onMouseLeave event handlers
 *
 * @param {Component} WrappedComponent A component to enhance
 * @returns {Component} a enhanced component
 */
function visibleOnHover(WrappedComponent) {
  class HoverTooltip extends PureComponent {
    constructor(props) {
      super(props);
      this.state = {
        visible: false
      };
    }

    showTooltip = callback => {
      this.setState({ visible: true }, () => callback(this.state.visible));
    };

    hideTooltip = () => {
      this.setState({ visible: false });
    };

    toogleTooltip = callback => {
      this.setState(
        state => ({
          visible: !state.visible
        }),
        () => callback(this.state.visible)
      );
    };

    handleTargetClick = () => {
      this.toogleTooltip(this.props.onTargetClick);
    };

    handleMouseEnter = () => {
      this.showTooltip(this.props.onMouseEnter);
    };

    render() {
      let actionProps = {
        onTargetClick: this.handleTargetClick
      };

      // Desktop (no touch) devices UX
      if (!hasTouch()) {
        actionProps = {
          onMouseEnter: this.handleMouseEnter,
          onMouseLeave: this.hideTooltip
        };
      }

      return (
        <WrappedComponent
          {...this.props}
          {...actionProps}
          visible={this.state.visible}
          onContentClick={this.hideTooltip}
        />
      );
    }
  }

  HoverTooltip.propTypes = {
    onMouseEnter: PropTypes.func,
    onTargetClick: PropTypes.func
  };

  HoverTooltip.defaultProps = {
    onMouseEnter: noop,
    onTargetClick: noop
  };

  return HoverTooltip;
}

export default visibleOnHover(Tooltip);
