import { isString } from 'common/types';

import { isDev } from '../env';
import { stringify } from './query-string';

let routingData: typeof window.routingData;

if (window.routingData) {
  // it is possible that the event emitter was already setup by a previously loaded javascript
  routingData = window.routingData;
} else {
  routingData = undefined;
  window.routingData = routingData;
}

export function initialize(data: ACRoute.Routing) {
  routingData = data;
  window.routingData = routingData;
}

export function getRoute(name: string) {
  // some routes are not branded so try them first
  if (routingData?.routes[name] !== undefined) {
    return routingData.routes[name];
  }
  const brandedName = routingData?.prefix + '__' + name;
  if (routingData?.routes[brandedName] !== undefined) {
    return routingData.routes[brandedName];
  }
  return null;
}

function interpretToken(
  token: ACRoute.RouteToken,
  route: ACRoute.Route,
  routeParameters: ACRoute.RouteParameters,
  unusedParameters?: ACRoute.RouteParameters
) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [tokenType, tokenText, tokenParameterType, tokenParameterName] = token;
  if (
    tokenType !== 'text' &&
    tokenType !== 'variable' &&
    tokenType !== 'part'
  ) {
    throw new Error('The token type "' + tokenType + '" is not supported.');
  }

  if (tokenType === 'text' || tokenType === 'part') {
    return tokenText;
  }

  let value: string | number | undefined;
  // tokenParameterName as to be defined since token is a Variable token but TS can't know that
  // check given parameters
  if (routeParameters[tokenParameterName as string] !== undefined) {
    value = routeParameters[tokenParameterName as string];
    delete unusedParameters?.[tokenParameterName as string];
  } else {
    // verify in default values
    value =
      route.defaults[tokenParameterName as string] !== undefined
        ? route.defaults[tokenParameterName as string]
        : undefined;
  }

  if (value === undefined) {
    throw new Error(`The token type "${tokenParameterName}" is required.`);
  }

  let cleanValue = encodeURIComponent(value);
  // avoid urlencoding of filters parameters with a slash inside
  if (typeof value == 'string' && value.startsWith('/')) {
    cleanValue = value;
  }

  return tokenText + cleanValue;
}

export function path(
  name: string,
  routeParameters: ACRoute.RouteParameters = {},
  absolute = false
) {
  if (!routingData) {
    throw new Error('no routing data provided');
  }
  if (routeParameters.page && routeParameters.page === 1) {
    delete routeParameters.page;
  }

  const route = getRouteAndFilter(name, routeParameters);

  if (!route) {
    return '';
  }

  const unusedParameters = {
    ...routeParameters
  };
  let url = '';
  let host = '';

  //
  const fragments: { [key: string]: string } = {};
  const optionalVariables = [];

  for (const token of route.tokens) {
    if (token[0] === 'part') {
      let fragment = '';
      let omit = true;

      for (const subToken of token[2]) {
        if (subToken[0] === 'text') {
          fragment = subToken[1] + fragment;
        } else {
          if (
            routeParameters[subToken[3]] || // optional parameter is defined in the route parameters
            routeParameters[subToken[3]] === 0 // special case for "falsy" but valid value 0
          ) {
            // Check requirements
            fragment = subToken[1] + routeParameters[subToken[3]] + fragment;
            omit = false;
            delete routeParameters[subToken[3]];
          }

          optionalVariables.push(subToken[3]);
        }
      }

      fragments[token[1]] = omit ? '' : fragment;
    }
  }

  for (const variable of optionalVariables) {
    delete unusedParameters[variable];
  }

  route.tokens.forEach(token => {
    url = interpretToken(token, route, routeParameters, unusedParameters) + url;
  });

  for (const placeholder of Object.keys(fragments)) {
    url = url.replace(placeholder, fragments[placeholder]);
  }

  const queryString = stringify(unusedParameters);
  if (queryString) {
    url = url + '?' + queryString;
  }

  if (route.hosttokens.length === 0) {
    host = routingData.host;
  } else {
    route.hosttokens.forEach(token => {
      host = interpretToken(token, route, routeParameters) + host;
    });
  }

  // If the route host is the same as current location host AND
  // user did not asked for an absolute route, we stop here.
  // It means we return a relative URL
  if (host === window.location.host && !absolute) {
    return url;
  }
  // Otherwise, let's return an absolute URL...

  let scheme = window.location.protocol;
  if (route.schemes && route.schemes.length === 1) {
    // SF does not had the ":" after the protocol, location.protocol does
    scheme = route.schemes[0] + ':';
  }

  return scheme + '//' + host + url;
}

function extractFilterToken(tokens: ACRoute.RouteToken[]) {
  if (tokens.length <= 0) {
    return [];
  }
  const filterTokenNames = [];
  for (const token of tokens) {
    if (token[0] === 'variable' && token[3].indexOf('filters') === 0) {
      filterTokenNames.push(token[3]);
    }
  }
  filterTokenNames.sort();

  return filterTokenNames;
}

// Because optionals route parameters are grouped in one parameters with constraints
// We have to reconstruct the right parameters with the help of parameters in defaults (filters_0, filters_0_order, filters_defaults)
// ex:
//  bin/console debug:router internal_api_showtimes_place_filtered
// +--------------+----------------------------------------------------------------------------------------------------------------------------------------+
// | Property     | Value                                                                                                                                  |
// +--------------+----------------------------------------------------------------------------------------------------------------------------------------+
// | Route Name   | internal_api_showtimes_place_filtered                                                                                                  |
// | Path         | /_/showtimes/movie-{movie}/near-{localization}{filters_0}/                                                                             |
// | Path Regex   | #^/_/showtimes/movie\-(?P<movie>[^/]++)/near\-(?P<localization>\d+)(?P<filters_0>(/d-(?P<day>[0-9]{1,2}))?(/p-(?P<page>[0-9]+))?)/$#sD |
// | Host         | ANY                                                                                                                                    |
// | Host Regex   |                                                                                                                                        |
// | Scheme       | ANY                                                                                                                                    |
// | Method       | GET|POST                                                                                                                               |
// | Requirements | filters_0: (/d-(?P<day>[0-9]{1,2}))?(/p-(?P<page>[0-9]+))?                                                                             |
// |              | localization: \d+                                                                                                                      |
// | Class        | Symfony\Component\Routing\Route                                                                                                        |
// | Defaults     | _cache_control: array ('enabled' => true,'ttl' => 3600,)                                                                               |
// |              | _controller: AppBundle:MoviePage:showtimesPlaceFiltered                                                                                |
// |              | _universe: tech                                                                                                                        |
// |              | all: false                                                                                                                             |
// |              | day: 0                                                                                                                                 |
// |              | filters_0:                                                                                                                             |
// |              | filters_0_order: array ('day' => 'd','page' => 'p',)                                                                                   |
// |              | filters_defaults: array ('day' => 0,'page' => 1,)                                                                                      |
// |              | page: 1                                                                                                                                |
// | Options      | compiler_class: Symfony\Component\Routing\RouteCompiler                                                                                |
// |              | expose: true                                                                                                                           |
// |              | universe: tech                                                                                                                         |
// | Callable     | AppBundle\Controller\MoviePageController::showtimesPlaceFilteredAction                                                                 |
// +--------------+----------------------------------------------------------------------------------------------------------------------------------------+
//
// For ex we must construct the day & page if provided in `parameters`
// to have finally a parameters like `/d-<day>/p-<page>` in `filters_0`.
//
function buildFiltersParameters(
  filterTokenNames: string[],
  defaults: { [key: string]: any },
  parameters: ACRoute.RouteParameters = {}
) {
  for (const tokenName of filterTokenNames) {
    let filtersValue = '';
    const isUrlPrefix = defaults?.[`${tokenName}_is_prefix`] ?? false;
    parameters[tokenName] = '';
    for (const [filterPartName, filterPartPrefix] of Object.entries(
      defaults[`${tokenName}_order`]
    )) {
      if (cleanEmptyFilterPart(filterPartName, parameters)) {
        continue;
      }
      const filterPartValue =
        defaults?._mapping?.[filterPartName]?.[
          parameters[filterPartName] as string
        ] ?? parameters[filterPartName];
      if (filterPartValue) {
        const urlPrefix = isUrlPrefix ? '' : '/';
        const filterPartPrefixValue =
          isString(filterPartPrefix) && filterPartPrefix.length > 0
            ? `${filterPartPrefix}-`
            : '';
        filtersValue = `${filtersValue}${urlPrefix}${filterPartPrefixValue}${filterPartValue}`;
      }
      delete parameters[filterPartName];
    }
    if (filtersValue.length <= 0) {
      if (isUrlPrefix) {
        return false;
      }
      continue;
    }
    parameters[tokenName] = filtersValue;
  }
  return true;
}

function cleanEmptyFilterPart(
  filterPartName: string,
  parameters: ACRoute.RouteParameters
) {
  if ((parameters[filterPartName] as string)?.length <= 0) {
    delete parameters[filterPartName];

    return true;
  }

  return false;
}

function getRouteAndFilter(
  name: string,
  routeParameters: ACRoute.RouteParameters,
  retry = false
): ACRoute.Route | null {
  const route = getRoute(name);
  if (!route) {
    if (isDev()) {
      /* eslint-disable no-console */
      console.warn(`Unknown route : "${name}"`);
      /* eslint-enable no-console */
    }

    return null;
  }
  const filterTokenNames = extractFilterToken(route.tokens);
  if (filterTokenNames.length <= 0) {
    return route;
  }
  if (
    buildFiltersParameters(filterTokenNames, route.defaults, routeParameters)
  ) {
    return route;
  }
  // avoid recursive loop
  if (retry) {
    return null;
  }

  return getRouteAndFilter(`${name}_no_prefix`, routeParameters, true);
}

export function urlMatchesRoute(url: string, route: string) {
  const TRIM_SCHEME_AND_QUERY_STRING_PATTERN = /^[^:]*([^?]*).*/;

  if (!url || !route) {
    return false;
  }

  const routeUrl = path(route, {}, true);

  // Remove query string and scheme from url
  const trimmedUrl = url.match(TRIM_SCHEME_AND_QUERY_STRING_PATTERN)?.[1];
  const trimmedRouteUrl = routeUrl.match(
    TRIM_SCHEME_AND_QUERY_STRING_PATTERN
  )?.[1];

  return trimmedUrl === trimmedRouteUrl;
}

export function getReadMorePath(typename: string) {
  switch (typename.toLowerCase()) {
    case 'movie':
      return 'userprofile_movie_review';
    case 'program':
      return 'userprofile_program_review';
    case 'programseason':
      return 'userprofile_program_season_review';
    case 'season':
      return 'userprofile_season_review';
    case 'series':
      return 'userprofile_series_review';
    case 'seriesepisode':
      return null;
    case 'seriesseason':
      return 'userprofile_season_review';
    default:
      return null;
  }
}
