import { hit } from 'common/tracking/googleanalytics';
import { standardizeTrackingType } from 'common/tracking/helpers';

import { EntityTypename } from 'website/types';

export const trackAuthenticatedLink = ({
  eventAction,
  entityTypename
}: {
  eventAction: 'click_tooltip';
  entityTypename: EntityTypename;
}): void => {
  hit(
    {
      eventCategory: 'my_brand',
      eventLabel: 'connection',
      eventAction,
      dimension84: standardizeTrackingType(entityTypename) // entity theme
    },
    true
  );
};
