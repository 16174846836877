import { ReviewRating } from 'types/graphql-api.generated';

export type ReduxUserAccount = {
  email?: string;
  id?: string | number;
  isCguValidated?: boolean;
  isEmailValidated?: boolean;
  label?: string;
  picture?: string | null;
};

export type ReduxUser = {
  account?: Partial<ReduxUserAccount> | null;
  loggedIn?: boolean;
  passiveLoginDone?: boolean;
};

export type Unpack<A> = A extends Array<infer E> ? E : A;

export type Stringify<T extends string> = `${T}`;

export const isRating = (candidate: any): candidate is ReviewRating =>
  window.Object.values(ReviewRating).includes(candidate);

export const getKeys = Object.keys as <T extends object>(
  obj: T
) => Array<keyof T>;

export const getValues = Object.values as <T>(obj: {
  [o: string]: T | undefined;
}) => Array<T>;

export const isHTMLElement = (candidate: any): candidate is HTMLElement =>
  typeof HTMLElement === 'object'
    ? candidate instanceof HTMLElement
    : candidate &&
      typeof candidate === 'object' &&
      candidate.nodeType === 1 &&
      typeof candidate.nodeName === 'string';

export const isString = (candidate: unknown): candidate is string =>
  typeof candidate === 'string';

export const toLowerCase = <T extends string>(candidate: T) =>
  candidate.toLowerCase() as Stringify<Lowercase<T>>;

export type DeepPartial<T> = {
  [P in keyof T]?: T[P] extends object ? DeepPartial<T[P]> : T[P];
};
