export const NEWSLETTER_SUBSCRIPTION_PUSH = 'subscribed_from_push';
export const NEWSLETTER_CAPPING_MODAL_INFO = 'ac_newsletter_modal_capping';
export const SS_KEY_HIDE_REMINDER = 'SS_KEY_HIDE_REMINDER';
export const GEO_IP_ADSERVER_ID = 'GEO_IP_ADSERVER_ID';
export const AC_ASSET_VERSION = 'AC_ASSET_VERSION';
export const CSS_FONT_CODE_KEY = 'font_css_code';
export const CSS_FONT_URL_KEY = 'font_css_url';
export const WADS_API = 'AC_W_API';
export const TVTY_KEY = 'TVTY_KEY';

export enum LocalStorageKeys {
  PROMOTE_AFFINITY_MODAL_CAPPING = 'ac_promote_affinity_modal_capping',
  PROMOTE_SIGNUP_MODAL_CAPPING = 'ac_promote_signup_modal_capping'
}
